import { LanguageMap } from '../interfaces/language';

export const TreasureCreationTypeValue: LanguageMap<string> = {
  EN: 'Sticker',
  KO: '이모티콘',
};

export const TreasureCreationTypeValueMul: LanguageMap<string> = {
  EN: 'Stickers',
  KO: '이모티콘',
};

export const TreasureCreationPoint: LanguageMap<string> = {
  EN: 'Adskit',
  KO: 'Adskit',
};

export const TreasureCreationCandy: LanguageMap<string> = {
  EN: 'Candy',
  KO: '캔디',
};

export const TreasureCreationLDiscount: LanguageMap<string> = {
  EN: 'AD Discount',
  KO: 'AD Discount',
};

export const TreasureCreationLDiscounts: LanguageMap<string> = {
  EN: 'AD Discounts',
  KO: 'AD Discounts',
};

export const TreasureCreationTypeValue2: LanguageMap<string> = {
  EN: 'Unit',
  KO: '유닛',
};

export const TreasureCreationTypeValue2Mul: LanguageMap<string> = {
  EN: 'Units',
  KO: '유닛',
};

export const TreasureCreationUnit: LanguageMap<string> = {
  EN: 'unit',
  KO: '유닛',
};

export const TreasureCreationUnits: LanguageMap<string> = {
  EN: 'units',
  KO: '유닛',
};

export const TreasureReviewDurationHours: LanguageMap<string> = {
  EN: 'hours',
  KO: '시간',
};

export const TreasureReviewDurationDays: LanguageMap<string> = {
  EN: 'days',
  KO: '일',
};

export const TreasureReviewDurationWeeks: LanguageMap<string> = {
  EN: 'weeks',
  KO: '주',
};

export const TreasureCreationDurationOption1: LanguageMap<string> = {
  EN: 'week',
  KO: '주',
};

export const TreasureCreationDurationOption2: LanguageMap<string> = {
  EN: 'day',
  KO: '일',
};

export const TreasureCreationDurationOption3: LanguageMap<string> = {
  EN: 'hour',
  KO: '시간',
};

export const locations: LanguageMap<string> = {
  EN: 'locations',
  KO: '위치',
};

export const starts: LanguageMap<string> = {
  EN: 'Starts',
  KO: '시작',
};

export const review: LanguageMap<string> = {
  EN: 'In Review',
  KO: '리뷰',
};

export const admin: LanguageMap<string> = {
  EN: 'ADMIN',
  KO: 'Admin',
};

export const superadmin: LanguageMap<string> = {
  EN: 'SUPERADMIN',
  KO: 'Super Admin',
};

export const master: LanguageMap<string> = {
  EN: 'MASTER',
  KO: 'Master',
};

export const masterPartner: LanguageMap<string> = {
  EN: 'MASTER-PARTNER',
  KO: 'Master-Partner',
};

export const created: LanguageMap<string> = {
  EN: 'Created',
  KO: 'Created',
};

export const loadingModalText: LanguageMap<string> = {
  EN: 'Uploading file, please wait... ',
  KO: 'Uploading file, please wait... ',
};
export const buyButton: LanguageMap<string> = {
  EN: 'Buy',
  KO: 'Buy',
};
export const buttonDelete: LanguageMap<string> = {
  EN: 'Delete',
  KO: 'Delete',
};
export const buttonCancel: LanguageMap<string> = {
  EN: 'Cancel',
  KO: 'Cancel',
};
export const deleteConfirmationText: LanguageMap<string> = {
  EN: 'Do you want to delete',
  KO: 'Do you want to delete',
};
