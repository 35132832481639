import React, { useState } from 'react';
import { Row, Button, Menu } from 'antd';
import BalanceList from './BalanceList';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { getBoughtLCashList } from '../../../../services/balanceService';
import * as balanceStrings from '../../../../lib/strings/balance';
import { languages } from '../../../../lib/interfaces/language';

interface Props {
  handleOpenRecharge: (menu: string) => void;
  language?: languages;
}

const mapState = (state: { authentication: any }) => {
  return {
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleOpenRecharge: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
  };
};

const Balance = (props: Props) => {
  /*     constructor(props: Readonly<Props>) {
            super(props)
    
            this.state = {
                transactionList: null,
                loading: false,
                itemType: null
            }
        } */

  const [loading, setloading] = useState(false);
  const [transactionList, setTransactionList] = useState(null);
  const [itemType, setItemType] = useState(null);

  const handleSelectedButton = async (e: { key: string }) => {
    if (e.key === '2') {
      setloading(true);
      const fetchSelectedResponse = await getBoughtLCashList(e.key);
      if (fetchSelectedResponse) {
        setTransactionList(fetchSelectedResponse);
        setItemType(2);
      }
      setloading(false);
    } else if (e.key === '4') {
      setloading(true);
      const fetchBought = await getBoughtLCashList(e.key);
      if (fetchBought) {
        setTransactionList(fetchBought);
        setItemType(2);
      }
      setloading(false);
    }
  };

  return (
    <Row>
      <Row type="flex" justify="center">
        <Button
          className="buttons purple-gradient-right"
          onClick={() => props.handleOpenRecharge('Recharge')}
        >
          {balanceStrings.recharge[props.language]}
        </Button>
      </Row>
      <Row>
        <Row>
          <Row type="flex" justify="space-around" className="admin-review-btn">
            <Menu
              onClick={(e: any) => handleSelectedButton(e)}
              mode="horizontal"
              style={{ width: '100%' }}
            >
              <Menu.Item className="" key="2">
                {balanceStrings.spent[props.language]}
              </Menu.Item>
              <Menu.Item className="" key="4">
                {balanceStrings.bought[props.language]}
              </Menu.Item>
            </Menu>
          </Row>
        </Row>
        {transactionList ? (
          <BalanceList itemType={itemType} transactionList={transactionList} />
        ) : null}
      </Row>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Balance);
