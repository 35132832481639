import React from 'react';
import { Row, Col, Typography } from 'antd';
import lcash from '../../../../assets/lcash.svg';
import moment from 'moment';
import { lCash } from '../../../../lib/strings/balance';
import { languages } from '../../../../lib/interfaces/language';
import { connect } from 'react-redux';

interface Props {
  transactionDate: number;
  value: number;
  action: string;
  image: string;
  language?: languages;
}

const { Text } = Typography;

const mapState = (state: any) => {
  return {
    language: state.authentication.language,
  };
};

const BalanceItemBought = (props: Props) => {
  return (
    <Row style={{ width: '100%' }}>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ width: '100%', padding: '1em' }}
      >
        <Col style={{ margin: '1em' }}>
          <img style={{ width: '30px', height: '30px' }} src={lcash} />
        </Col>
        <Col style={{ width: '80%' }}>
          <Row type="flex" justify="space-between" style={{ width: '100%' }}>
            <Col>
              <Row>
                <Text>{moment(props.transactionDate, 'x').format('DD/MM/YY')}</Text>
              </Row>
              <Row>
                <Text className="bold">
                  {props.value} {lCash[props.language]}
                </Text>
              </Row>
            </Col>
            <Col className="open-kit-details">${props.value}</Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default connect(mapState)(BalanceItemBought);
