import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { Row, Spin } from 'antd';
import Character from './Character';
import * as treasureService from '../../../../services/treasureService';
import { treasureConstants } from '../../../../constants/treasureConstants';

interface Props {
  characterList: any;
  storeCharacters: (characters: any) => Action;
}

const mapDispatchToProps = (dispatch: any) => ({
  storeCharacters: (characters: any) =>
    dispatch({ type: treasureConstants.SAVE_CHARACTERS, characters }),
});

const CharacterList = (props: Props) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const characters = async () => {
      props.storeCharacters(await treasureService.getStickerCharacters());
      setInitialized(true);
    };
    characters();
  }, []);

  const [characterStoryID, setCharacterStoryID] = useState(null);

  const handleSelectedCharacter = (id: number) => {
    setCharacterStoryID(id);
  };

  if (initialized) {
    return (
      <Row type="flex" justify="center" style={{ width: '100%' }}>
        {props.characterList
          .sort((a: any, b: any) => (a.id < b.id ? 1 : -1))
          .map((character: any) => {
            return (
              <Character
                all={character}
                title={character.title}
                selected={character.id === characterStoryID}
                key={character.id}
                ID={character.id}
                handleSelectedCharacter={handleSelectedCharacter}
                thumbnail={character.video_thumbnail?.image}
              />
            );
          })}
      </Row>
    );
  } else {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }
};

export default connect(null, mapDispatchToProps)(CharacterList);
