import { alertActions } from './alertActions';
import * as userService from '../services/signinService';
import { userConstants, userActionTypes } from '../constants/signinConstants';
import { history, store } from '../helpers/store';
import { notification } from 'antd';
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_USERDATA,
  LOCAL_STORAGE_COUNTRY,
  LOCAL_STORAGE_USER_LOCATION,
} from '../lib/config';
import { signInError } from '../lib/strings/error';
import { languages } from '../lib/interfaces/language';

const language: languages = store.getState().authentication.language;

const openNotificationWithIcon = (errorMessage: string): any => {
  notification.error({
    message: signInError[language],
    description: errorMessage,
  });
};

export function goBackToPhoneNumberForm() {
  return (dispatch: (arg0: any) => void) => {
    dispatch(cancel());
  };
  function cancel() {
    return { type: userConstants.CANCEL };
  }
}

export function sendPhoneNumber(phoneNumber: string, checked: boolean) {
  return (
    dispatch: (arg0: { type: string; phoneNumber?: string; error?: string }) => userActionTypes
  ) => {
    (async () => {
      dispatch(request(phoneNumber));
      const response = await userService.sendPhoneNumber(phoneNumber);
      if (response) {
        dispatch(success(response));
        dispatch(alertActions.success('Sending successful'));
      }
    })();
  };
  function request(phoneNumber: string) {
    return { type: userConstants.SEND_PHONE_NUMBER_REQUEST, phoneNumber };
  }
  function success(response: any) {
    return { type: userConstants.SEND_PHONE_NUMBER_SUCCESS, phoneNumber };
  }
}

export function signin(verificationCode: string, phoneNumber: string) {
  return (
    dispatch: (arg0: {
      type: string;
      verificationCode?: string;
      response?: any;
      message?: string;
      error?: string;
    }) => void
  ) => {
    (async () => {
      try {
        dispatch(request(verificationCode));
        const responseWithToken = await userService.signin(verificationCode, phoneNumber);
        dispatch(success(responseWithToken));
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(responseWithToken));
        dispatch(alertActions.success('Sign in successful'));

        const userData = await userService.getUserData(responseWithToken.user._id);
        localStorage.setItem(LOCAL_STORAGE_USERDATA, JSON.stringify(userData));
        dispatch(storeUserData(userData));
        history.replace('/dashboard');
      } catch (error) {
        dispatch(failure(error.description));
        dispatch(alertActions.error(error));
        openNotificationWithIcon(error.description);
      }
    })();
  };
  function request(verificationCode: string) {
    return { type: userConstants.SIGNIN_REQUEST, verificationCode };
  }
  function success(response: any) {
    return { type: userConstants.SIGNIN_SUCCESS, response };
  }
  function failure(error: string) {
    return { type: userConstants.SIGNIN_FAILURE, error };
  }
  function storeUserData(user: any) {
    return { type: userConstants.STORE_USER_DATA, user };
  }
}

export function signout() {
  return (dispatch: (arg0: { type: string }) => userActionTypes) => {
    userService.signout();
    localStorage.removeItem(LOCAL_STORAGE_USER);
    localStorage.removeItem(LOCAL_STORAGE_USERDATA);
    localStorage.removeItem(LOCAL_STORAGE_USER_LOCATION);
    dispatch(reset());
    history.replace('/');
  };
  function reset() {
    return { type: userConstants.RESET };
  }
}

export function saveUserCountryCode(countryCode: string) {
  return (dispatch: (arg0: { type: string; country: string }) => void) => {
    /* if (countryCode === 'en-US') {
            dispatch(saveCountryCode('EN'))
        } */
    localStorage.setItem(LOCAL_STORAGE_COUNTRY, countryCode);
    dispatch(saveCountryCode('KO'));
  };
  // function saveCountryCode(countryCode: string) { return { type: userConstants.LANGUAGE, countryCode } }
  function saveCountryCode(country: string) {
    return { type: userConstants.COUNTRY_CODE, country };
  }
}

export function retakeControl(responseWithToken, userData) {
  return (dispatch) => {
    dispatch({ type: userConstants.RETAKE_CONTROL, payload: responseWithToken });
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(responseWithToken));

    localStorage.setItem(LOCAL_STORAGE_USERDATA, JSON.stringify(userData));
    dispatch({ type: userConstants.STORE_USER_DATA, user: userData });
  };
}
