import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import * as treasureCreation from '../../lib/strings/treasureCreation';
import { roleMaster, roleAdmin, roleSuperadmin, sticker } from '../../lib/const';
import lcash from '../../assets/lcash.svg';
import PropTypes from 'prop-types';

function ReviewButtons(props) {
  const { language, treasure, role, onCancel, onReview } = props;
  const { treasureType, totalNumberOfUnits, pointsPerUnit, totalPrice } = treasure;

  return (
    <Row className="review-buttons">
      <Col>
        {Number(treasureType) > sticker && role < roleAdmin ? (
          <Row>
            <Col>
              <Typography.Text className="center pricing-text white-txt fredoka">
                {!totalNumberOfUnits ? 0 : pointsPerUnit} {treasureCreation.PointResult[language]}{' '}
                {totalNumberOfUnits ? totalNumberOfUnits : 0}{' '}
                {treasureCreation.UnitsResult[language]} = <img src={lcash} />
                {totalPrice !== NaN ? totalPrice.toFixed(2) : 0}
              </Typography.Text>
            </Col>
          </Row>
        ) : null}

        {/* Buttons at the bottom Cancel Save as draft Next */}
        <Row type="flex" justify="space-around" className="buttons-row">
          <Col>
            <Button
              className="buttons transparent-btn-white treasure-button-width"
              onClick={onCancel}
            >
              <span style={{ textDecoration: 'underline' }}>
                {treasureCreation.TreasureCreationButton1[language]}
              </span>
            </Button>
          </Col>
          <Col>
            <Button
              className="buttons transparent-btn-white treasure-button-width"
              onClick={() => console.log('missing logic')}
            >
              <span style={{ textDecoration: 'underline' }}>
                {treasureCreation.TreasureCreationSaveAsDraftButton[language]}
              </span>
            </Button>
          </Col>
          <Col>
            <Button className="buttons yellow-gradient treasure-button-width" onClick={onReview}>
              {treasureCreation.TreasureCreationButton3[language]}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ReviewButtons.propTypes = {
  language: PropTypes.string,
  treasure: PropTypes.object.isRequired,
  role: PropTypes.oneOf([roleMaster, roleAdmin, roleSuperadmin]),
  onCancel: PropTypes.func,
  onReview: PropTypes.func,
  locations: PropTypes.array,
};

ReviewButtons.defaultProps = {
  language: 'EN',
  onCancel: () => {},
  onReview: () => {},
};

export default ReviewButtons;
