import React, { useEffect } from 'react';
import { Row, Button, Typography, Col, InputNumber } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import equalIcon from '../../../../assets/equal.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { connect } from 'react-redux';
import { rechargeConstants } from '../../../../constants/rechargeConstants';
import StripePayment from '../../../../components/StripePayment';
import { persistor } from '../../../../helpers/store';
import lcashIcon from '../../../../assets/lcash.svg';
import { recharge, lCash, currentBalance } from '../../../../lib/strings/balance';
import { languages } from '../../../../lib/interfaces/language';

import { userConstants } from '../../../../constants/signinConstants';
import { LOCAL_STORAGE_USERDATA } from '../../../../lib/config';
import { userData } from '../../../../reducers/userReducer';
interface Props {
  handleClose: () => void;
  updateLCash: (amount: number) => void;
  amount: number;
  currentLCash: number;
  usd: number;
  isPaymentInitiated: boolean;
  language?: languages;
  userData: object;
  storeUserData: (user: object) => void;
}

const mapState = (state: {
  authentication: any;
  userData: { lcash: any };
  recharge: { usd: any; amount: any };
  isPaymentInitiated: boolean;
}) => {
  return {
    currentLCash: state.userData.lcash,
    usd: state.recharge.usd,
    amount: state.recharge.amount,
    isPaymentInitiated: state.isPaymentInitiated,
    language: state.authentication.language,
    userData: state.userData,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    updateLCash: (amount: number) => dispatch({ type: rechargeConstants.STORE_AMOUNT, amount }),
    storeUserData: (user: any) => dispatch({ type: userConstants.STORE_USER_DATA, user }),
  };
};

const Recharge = (props: Props) => {
  useEffect(() => {
    persistor.persist();
    persistor.flush();
  }, []);

  const updateLCash = (e: any) => {
    props.updateLCash(e);
  };

  const onStripePayment = () => {
    props.storeUserData({ refetch: true });
    localStorage.setItem(LOCAL_STORAGE_USERDATA, JSON.stringify({ ...userData, refetch: true }));
  };

  return (
    <Row className="kit-list-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="start" align="middle">
          <Button onClick={props.handleClose} className="details-arrow">
            <img src={arrow} alt="close" />
          </Button>
          <Typography.Text className="contest-title bold">
            {recharge[props.language]}
          </Typography.Text>
        </Row>
        <Row className="recharge-container">
          <Row type="flex" justify="space-between">
            <Typography.Text className="contest-heading">
              {currentBalance[props.language]}
            </Typography.Text>
            <Typography.Text>
              {props.currentLCash ? props.currentLCash : 0}{' '}
              <img src={lcashIcon} style={{ marginLeft: '.2em' }} />
            </Typography.Text>
          </Row>
          <Row className="recharge-form">
            <Row type="flex" justify="center">
              <Col>
                <Row>
                  <Typography.Text>{lCash[props.language]}</Typography.Text>
                </Row>
                <Row>
                  <InputNumber
                    className="input-treasure"
                    min={0}
                    name="lcash"
                    onChange={(e) => updateLCash(e)}
                  />
                </Row>
              </Col>
              <Col style={{ margin: '1em' }}>
                <img src={equalIcon} style={{ width: '55px' }} />
              </Col>
              <Col>
                <Row>
                  <Typography.Text>USD</Typography.Text>
                </Row>
                <Row>
                  <Typography.Text style={{ fontSize: '1.5em' }}>${props.usd}</Typography.Text>
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="center" className="button-payment">
              <Button className="buttons yellow-gradient" onClick={onStripePayment}>
                <StripePayment amount={props.amount * 100}> </StripePayment>
              </Button>
            </Row>
          </Row>
          <Row type="flex" justify="center">
            1.00 Lcash = 1.00 US$
          </Row>
        </Row>
      </div>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Recharge);
