import { menuConstants } from '../constants/menuConstants';
import { locationConstants } from '../constants/locationConstants';

export const initialStateMenu = {
  open: 0,
  openZoomControls: true,
};
export function menuUIChange(state = initialStateMenu, action: any) {
  switch (action.type) {
    case menuConstants.OPEN_TREASURE_CREATION:
      return {
        ...state,
        open: 1,
        openZoomControls: true,
      };
    case menuConstants.CANCEL_TREASURE_CREATION:
      return {
        ...state,
        open: 0,
      };
    case menuConstants.OPEN_REVIEW_SCREEN:
      return {
        ...state,
        openZoomControls: false,
        open: 2,
      };
    case menuConstants.BACK_TO_TREASURE_CREATION:
      return {
        ...state,
        open: 1,
        openZoomControls: true,
      };
    case menuConstants.SEND_TO_REVIEW: {
      return {
        ...state,
        open: 0,
      };
    }
    case menuConstants.OPEN_MAIN: {
      return {
        ...state,
        open: 0,
      };
    }
    case menuConstants.EDIT_DRAFT: {
      return {
        ...state,
        open: 1,
      };
    }
    case menuConstants.OPEN_CREATE_CANDY_EVENT: {
      return { ...state, open: 3, openZoomControls: false };
    }
    case menuConstants.CLOSE_MENU:
      return {
        ...state,
        open: 0,
        openZoomControls: false,
      };
    default:
      return state;
  }
}

export function openLocationUIs(state: any = [], action: any) {
  let cloneState = [...state];
  switch (action.type) {
    case menuConstants.OPEN_LOCATION_UI:
      cloneState[action.locationID_UI] = true;
      return cloneState;
    case menuConstants.CLOSE_LOCATION_UI:
      cloneState[action.locationID_UI] = false;
      return cloneState;
    case locationConstants.DELETE_LOCATION:
      cloneState.splice(action.locationID_UI, 1);
      return cloneState;
    case locationConstants.CREATE_LOCATION:
      cloneState.forEach((element: boolean, index: number) => {
        if (element === true) {
          cloneState[index] = false;
        }
      });
      cloneState.push(true);
      return cloneState;
    case locationConstants.RESET_REDUCERS:
      return [];
    default:
      return state;
  }
}

export const isSubMenu1Open = (state: boolean = false, action: any) => {
  switch (action.type) {
    case menuConstants.OPEN_SUBMENU_1:
      return true;
    case menuConstants.CLOSE_SUBMENU_1:
      return false;
    case menuConstants.OPEN_TREASURE_CREATION:
      return false;
    default:
      return state;
  }
};

export const isSubMenu2Open = (state: boolean = false, action: any) => {
  switch (action.type) {
    case menuConstants.OPEN_SUBMENU_2:
      return true;
    case menuConstants.CLOSE_SUBMENU_2:
      return false;
    case menuConstants.CLOSE_SUBMENU_1:
      return false;
    case menuConstants.OPEN_TREASURE_CREATION:
      return false;
    default:
      return state;
  }
};

export const isSubMenuFullScreenOpen = (state: boolean = false, action: any) => {
  switch (action.type) {
    case menuConstants.OPEN_SUBMENU_FULL_SCREEN:
      return true;
    case menuConstants.CLOSE_SUBMENU_FULL_SCREEN:
      return false;
    default:
      return state;
  }
};

export const subMenu1 = (state: string = '', action: any) => {
  switch (action.type) {
    case menuConstants.OPEN_SUBMENU_1:
      return action.menu;
    default:
      return state;
  }
};

export const subMenu2 = (state: string = '', action: any) => {
  switch (action.type) {
    case menuConstants.OPEN_SUBMENU_2:
      return action.menu;
    default:
      return state;
  }
};

export const subMenuFullScreen = (state: string = '', action: any) => {
  switch (action.type) {
    case menuConstants.OPEN_SUBMENU_FULL_SCREEN:
      return action.menu;
    case menuConstants.CLOSE_SUBMENU_FULL_SCREEN:
      return '';
    default:
      return state;
  }
};

export const menu = (state = '', action: any) => {
  switch (action.type) {
    case menuConstants.CHANGE_MENU:
      return action.menu;
    default:
      return state;
  }
};
