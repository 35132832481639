import React from 'react';
import { Row, Col, Typography } from 'antd';
import pointImg from '../../../../assets/ar_star_large.svg';
import lcash from '../../../../assets/lcash.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import { languages } from '../../../../lib/interfaces/language';
import { adKit } from '../../../../lib/strings/balance';

interface Props {
  action: string;
  transactionDate: number;
  value: number;
  image: string;
  language?: languages;
}
const mapState = (state: any) => {
  return {
    language: state.authentication.language,
  };
};
const { Text } = Typography;

const BalanceItemSpent = (props: Props) => {
  return (
    <Row style={{ width: '100%' }}>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ width: '100%', padding: '1em' }}
      >
        <Col style={{ margin: '1em' }}>
          <img style={{ width: '30px', height: '30px' }} src={pointImg} />
        </Col>
        <Col style={{ width: '80%' }}>
          <Row type="flex" justify="space-between" style={{ width: '100%' }} align="middle">
            <Col>
              <Row>
                <Text>{moment(props.transactionDate, 'x').format('DD/MM/YY')}</Text>
              </Row>
              <Row>
                <Text className="bold">{adKit[props.language]}</Text>
              </Row>
            </Col>
            <Col className="open-kit-details">
              <img src={lcash} style={{ marginRight: '.2em' }} />
              {props.value}
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default connect(mapState)(BalanceItemSpent);
