import { signupAPI, baseURL, verifyAPI, userDetailsAPI } from '../lib/config';
import { store } from '../helpers/store';
import { notification } from 'antd';
import * as signInString from '../lib/strings/signin';
import * as error from '../lib/strings/error';
import { responseCodes } from '../lib/const';

export async function sendPhoneNumber(phoneNumber: string) {
  console.log('sendPhoneNumber, phoneNumber:', phoneNumber);
  try {
    const fetchResponse = await fetch(baseURL + signupAPI, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
        isMaster: true,
        isWeb: true,
      }),
    });
    const response = await fetchResponse.json();
    switch (response.code) {
      case 1:
        return response;
      case 21211:
        notification.error({
          message:
            signInString.phoneNumberNotRegistered[store.getState().authentication.language || 'EN'],
        });
        return Promise.reject({
          description: signInString.phoneNumberNotRegistered.EN,
        });
      case responseCodes.userNotFound:
        notification.error({
          message:
            signInString.phoneNumberNotRegistered[store.getState().authentication.language || 'EN'],
        });
        return Promise.reject({
          description: signInString.phoneNumberNotRegistered.EN,
        });
      case responseCodes.userNotMaster:
        notification.error({
          message:
            signInString.switchNumberToMaster[store.getState().authentication.language || 'EN'],
        });
        return Promise.reject({
          description: signInString.switchNumberToMaster.EN,
        });
      default:
        notification.error({
          message: error.serverError[store.getState().authentication.language || 'EN'],
          description:
            signInString.failedToSendPhoneNumber[store.getState().authentication.language || 'EN'],
        });
    }
  } catch (error) {
    return error;
  }
}

export async function signin(verificationCode: string, phoneNumber: string) {
  try {
    const fetchResponse = await fetch(baseURL + verifyAPI, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isWeb: true,
        activationCode: verificationCode,
        phoneNumber: phoneNumber,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === 1) {
      return response.data;
    } else if (response.data.user.isMaster !== true) {
      return Promise.reject(response);
    } else {
      return Promise.reject(response);
    }
  } catch (error) {
    return Promise.reject('Failed to login');
  }
}

export function signout() {
  localStorage.removeItem('accesstoken');
  localStorage.removeItem('userdata');
}

export async function getUserData(userID: string) {
  try {
    const fetchResponse = await fetch(`${baseURL}${userDetailsAPI}/${userID}`, {
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const response = await fetchResponse.json();
    return response.data.user;
  } catch (error) {
    return error;
  }
}
