import { LOCAL_STORAGE_USERDATA } from '../lib/config';
import { userConstants } from '../constants/signinConstants';
import { roleMaster } from '../lib/const';

let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USERDATA));
const initialState = user
  ? {
      ...user,
    }
  : {
      role: roleMaster,
    };

export const userData = (state = initialState, action: any) => {
  switch (action.type) {
    case userConstants.STORE_USER_DATA:
      return {
        ...state,
        ...action.user,
      };
    case userConstants.COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.countryCode,
      };
    default:
      return state;
  }
};
