import { responseCodes } from '../lib/const';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import { executeSignOut } from './sharedServices';
import { isEmpty } from 'lodash';
import { serverError } from '../lib/strings/error';

export const getPartnerRequestsList = async ({ queryKey }) => {
  // for react-query
  console.log('getPartnerRequestsList, queryKey[1]:', queryKey[1]);

  const fetchResponse = await fetch(config.baseURL + config.partnerRequest, {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return isEmpty(response.data?.requests) ? [] : response.data.requests;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `Response code: ${response.code}`;
  }
};

export const updatePartnerStatus = async (ID, requestStatus: 'approved' | 'declined') => {
  // for react-query
  console.log(`updatePartnerStatus, ID: ${ID}, status: ${requestStatus}`);

  const fetchResponse = await fetch(`${config.baseURL}${config.partnerRequest}/${ID}`, {
    method: 'PUT',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ requestStatus: requestStatus }),
  });

  const response = await fetchResponse.json();
  console.log('response:', response);
  if (response.code === responseCodes.successful) {
    return response;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.error} ${response.code}`;
  }
};

export const checkUsername = async (username) => {
  try {
    console.log('checkUsername, username:', username);

    const fetchResponse = await fetch(
      `${config.baseURL}${config.partnerRequest}/checkUsername?` +
        new URLSearchParams({ username: username }),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    console.log('response:', response);
    if (response.code === responseCodes.successful) {
      return response.data.role;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      throw `Response code: ${response.code}`;
    }
  } catch (error) {
    console.log('error:', error);
    return false;
  }
};
