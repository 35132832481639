import React from 'react';
import { Row } from 'antd';
import BalanceItemSpent from './BalanceItemSpent';
import BalanceItemBought from './BalanceItemBought';

interface Props {
  transactionList: Array<any>;
  itemType: number;
}

const BalanceList = (props: Props) => {
  return (
    <Row type="flex" justify="center" style={{ width: '100%' }}>
      {props.transactionList.map((transaction, index) => {
        if (props.itemType === 2) {
          return (
            <BalanceItemSpent
              key={index}
              value={transaction.value}
              action={transaction.action}
              image={transaction.image}
              transactionDate={transaction.transactionDate}
            />
          );
        } else {
          return (
            <BalanceItemBought
              key={index}
              value={transaction.value}
              action={transaction.action}
              image={transaction.image}
              transactionDate={transaction.transactionDate}
            />
          );
        }
      })}
    </Row>
  );
};

export default BalanceList;
