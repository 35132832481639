import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

import * as constants from '../../lib/const';

function isNumber(char: string) {
  return /^\d+$/.test(char);
}

export default (props: { onUpdate?: (arg0: any) => void }) => {
  let input: Input[] = [null, null, null, null, null, null];

  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    input[0].focus();
  }, []);

  const checkInput = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const char = e.key;

    if (e.key === constants.keyBackspace) {
      const newNumbers = [...numbers];
      newNumbers[index] = '';
      setNumbers(newNumbers);
      if (input[index - 1]) input[index - 1].focus();

      if (props.onUpdate) props.onUpdate(newNumbers.reduce((pin, number) => pin + number + '', ''));

      return;
    }

    if (e.key === constants.keyDelete) {
      const newNumbers = [...numbers];
      newNumbers[index] = '';
      setNumbers(newNumbers);
      if (input[index + 1]) input[index].focus();

      if (props.onUpdate) props.onUpdate(newNumbers.reduce((pin, number) => pin + number + '', ''));

      return;
    }

    if (isNumber(char)) {
      const newNumbers = [...numbers];
      newNumbers[index] = char;
      setNumbers(newNumbers);
      if (input[index + 1]) input[index + 1].focus();

      if (props.onUpdate) props.onUpdate(newNumbers.reduce((pin, number) => pin + number + '', ''));
    } else {
      return;

      /* if (isNumber(char)) {
    
                const newNumbers = [...numbers];
                newNumbers[index] = char;
                setNumbers(newNumbers);
                if (input[index + 1]) input[index + 1].focus();
    
                if (props.onUpdate) props.onUpdate(newNumbers.reduce((pin, number) => pin + number + "", ""))
    
    
            }*/

      /* const newNumbers = [...numbers];
           newNumbers[index] = "";
           setNumbers(newNumbers);

           if (props.onUpdate) props.onUpdate(newNumbers.reduce((pin, number) => pin + number + "", "")) */
    }
  };

  return (
    <div className="input-verification-code">
      <Input
        ref={(dom) => (input[0] = dom)}
        value={numbers[0]}
        className="pininput"
        maxLength={1}
        onKeyDown={(e) => checkInput(e, 0)}
      />
      <Input
        ref={(dom) => (input[1] = dom)}
        value={numbers[1]}
        className="pininput"
        maxLength={1}
        onKeyDown={(e) => checkInput(e, 1)}
      />
      <Input
        ref={(dom) => (input[2] = dom)}
        value={numbers[2]}
        className="pininput"
        maxLength={1}
        onKeyDown={(e) => checkInput(e, 2)}
      />
      <Input
        ref={(dom) => (input[3] = dom)}
        value={numbers[3]}
        className="pininput"
        maxLength={1}
        onKeyDown={(e) => checkInput(e, 3)}
      />
      <Input
        ref={(dom) => (input[4] = dom)}
        value={numbers[4]}
        className="pininput"
        maxLength={1}
        onKeyDown={(e) => checkInput(e, 4)}
      />
      <Input
        ref={(dom) => (input[5] = dom)}
        value={numbers[5]}
        className="pininput"
        maxLength={1}
        onKeyDown={(e) => checkInput(e, 5)}
      />
    </div>
  );
};
