import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, InputNumber } from 'antd';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import PropTypes from 'prop-types';
import * as menuActions from '../../../../actions/menuActions';
import * as usersActions from '../../../../actions/usersActions';
import * as signinActions from '../../../../actions/signinActions';
import BackButtonCenteredText from '../../../../components/BackButtonCenteredText';
import * as usersString from '../../../../lib/strings/users';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';
import * as usersServices from '../../../../services/usersServices';

function UserDetails({
  language,
  user,
  closeSubmenu1,
  closeSubmenu2,
  resetUser,
  retakeControl,
  closeMenu,
}) {
  const queryClient = useQueryClient();
  const [addLcash, setAddLcash] = useState(0);

  useEffect(() => {
    setAddLcash(0);
  }, [user._id]);

  const userQuery = useQuery(['User', { id: user._id }], usersServices.getUserById);

  const addLcashMutation = useMutation(() => usersServices.addLcash(user._id, addLcash), {
    onSuccess: () => {
      queryClient.invalidateQueries(['User', { id: user._id }]);
      setAddLcash(0);
    },
    onError: () => {
      usersServices.showErrorMessage('Server error, please try again later.');
    },
  });

  const userTokenMutation = useMutation(() => usersServices.getRetakeData(user._id), {
    onSuccess: (response) => {
      takeAccountControlHandler(response.token, response.user);
    },
    onError: () => {
      usersServices.showErrorMessage('Server error, please try again later.');
    },
  });

  const onCloseHandler = () => {
    closeSubmenu1();
    closeSubmenu2();
    resetUser();
  };

  const addLcashHandler = () => {
    addLcashMutation.mutate();
  };

  const takeAccountControlHandler = (responseWithToken, userData) => {
    retakeControl(responseWithToken, userData);
    closeSubmenu1();
    closeSubmenu2();
    closeMenu();
  };

  const onLcashChangeHandler = (value) => {
    setAddLcash(value);
  };

  let lcash = 0;
  if (userQuery.data?.lcash) {
    lcash = userQuery.data.lcash;
  } else if (user.lcash) {
    lcash = user.lcash;
  }

  return (
    <Row className="candyEventDetails-wrapper candyEvent-text">
      <Col className="scrollable">
        <BackButtonCenteredText label={user.username} onClick={onCloseHandler} edit={false} />
        <div className="candyEventDetails-container">
          <Form>
            <Form.Item
              label={usersString.addLcash[language]}
              help={`${usersString.usersLcash[language]} ${lcash}`}
            >
              <InputNumber
                className="input-treasure full-width"
                value={addLcash ? addLcash : undefined}
                onChange={onLcashChangeHandler}
              />
            </Form.Item>
          </Form>
          <Row type="flex" justify="center" style={{ marginTop: 20 }}>
            <Col>
              <ButtonWithSpinner
                label={usersString.addLcash[language]}
                isLoading={addLcashMutation.isLoading}
                onClick={addLcashHandler}
              />
            </Col>
          </Row>

          <Row type="flex" justify="center" style={{ marginTop: 20 }}>
            <Col>
              <ButtonWithSpinner
                label={usersString.retakeControl[language]}
                type="transparentRed"
                underline
                onClick={userTokenMutation.mutate}
                width={210}
                style={{ margin: '100px 0px' }}
                isLoading={userTokenMutation.isLoading}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

UserDetails.propTypes = {
  users: PropTypes.object,
  language: PropTypes.string,
};

UserDetails.defaultProps = {
  user: {},
  language: 'EN',
};

const mapStateToProps = (state) => ({
  user: state.users,
  language: state.authentication.language,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  closeMenu: () => dispatch(menuActions.changeMenu('')),
  resetUser: () => dispatch(usersActions.resetUsers()),
  retakeControl: (responseWithToken, userData) =>
    dispatch(signinActions.retakeControl(responseWithToken, userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
