import React, { useState, useEffect } from 'react';
import { Form, Input, Typography, Row, InputNumber, Col } from 'antd';
import { Autocomplete } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { roleAdmin, sticker } from '../../../lib/const';
import trash from '../../../assets/location_delete_icon.svg';
import Geocode from 'react-geocode';

function LocationFormItem(props) {
  const {
    language,
    role,
    treasure,
    index,
    enableDelete,
    form,
    onDeleteLocation,
    ID,
    changeLocation,
    location,
    selectLocation,
    address,
    setAddress,
    treasureDetails,
  } = props;

  const [autocomplete, setAutocomplete] = useState(null);

  const setShowValues = (showValues) => {
    if (!location.disableShow) {
      form.setFieldsValue(showValues);
    }
  };

  useEffect(() => {
    if (location.numberOfUnits && location.numberOfUnits > 0) {
      setShowValues({
        ['latitude' + ID]: location.latitude,
        ['longitude' + ID]: location.longitude,
        ['numberOfUnits' + ID]: location.numberOfUnits,
        ['address' + ID]: location.address,
      });
    }
  }, []);

  useEffect(() => {
    if (treasure.isEditing && treasureDetails.locations.length > 0) {
      setShowValues({
        ['latitude' + ID]: location.latitude,
        ['longitude' + ID]: location.longitude,
        ['numberOfUnits' + ID]: location.numberOfUnits,
        ['address' + ID]: location.address,
      });
    }
  }, [ID]);

  useEffect(() => {
    const getFromLatLng = async (lat, lng) => {
      const geo = await Geocode.fromLatLng(lat, lng);
      const address = geo.results[0].formatted_address;

      setShowValues({ ['address' + ID]: address });
      changeLocation({ address }, index);
    };

    if (location.geocodeEnabled && location.latitude && location.longitude) {
      getFromLatLng(location.latitude, location.longitude);
    }
  }, [location.latitude, location.longitude]);

  useEffect(() => {
    if (location.selected && location.latitude && location.longitude) {
      setAddress({
        lat: location.latitude,
        lng: location.longitude,
        zoom: location.zoom,
        radius: location.radius,
      });
      address.mapInstance.setZoom(location.zoom);
    }
  }, [location.latitude, location.longitude, location.selected]);

  useEffect(() => {
    if (location.selected) {
      if (
        location.latitude !== address.lat ||
        location.longitude !== address.lng ||
        location.radius !== address.radius ||
        location.zoom !== address.zoom
      ) {
        setShowValues({
          ['latitude' + ID]: address.lat,
          ['longitude' + ID]: address.lng,
        });

        changeLocation(
          {
            latitude: address.lat,
            longitude: address.lng,
            zoom: address.zoom,
            radius: address.radius,
            geocodeEnabled: true,
          },
          index
        );
      }
    }
  }, [address]);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      const res = await autocomplete.getPlace();
      if (res.formatted_address) {
        const lat = await res.geometry.location.lat();
        const lng = await res.geometry.location.lng();

        form.setFieldsValue({
          ['address' + ID]: res.formatted_address,
          ['latitude' + ID]: lat,
          ['longitude' + ID]: lng,
        });
        changeLocation(
          {
            address: res.formatted_address,
            latitude: lat,
            longitude: lng,
            geocodeEnabled: false,
            disableShow: false,
          },
          index
        );
      }
    }
  };

  const onDeleteHandler = (event) => {
    event.stopPropagation();
    onDeleteLocation(index);
  };

  const onLatChange = (event) => {
    const lat = +event.target.value;
    changeLocation({ latitude: lat, geocodeEnabled: true, disableShow: false }, index);
  };

  const onLngChange = (event) => {
    const lng = +event.target.value;
    changeLocation({ longitude: lng, geocodeEnabled: true, disableShow: false }, index);
  };

  const onNumberOfUnitsChangeHandler = (event) => {
    changeLocation({ numberOfUnits: +event.target.value, disableShow: false }, index);
  };

  const onAddressChangeHandler = () => {
    changeLocation({ disableShow: false }, index);
  };

  return (
    <div
      className="location-wrapper"
      onClick={() => {
        selectLocation(index);
      }}
    >
      {location.selected ? (
        <Row type="flex" justify="end">
          <Typography.Text strong>Selected</Typography.Text>
        </Row>
      ) : (
        <div style={{ height: 21 }}></div>
      )}

      <Row type="flex" justify="space-between" align="middle">
        <Typography.Text className="location-title">
          #{index + 1} {treasureCreation.LocationFormTitle[language]}
        </Typography.Text>
        {enableDelete && (
          <img
            className="icon-filter"
            onClick={onDeleteHandler}
            src={trash}
            alt="delete location"
          />
        )}
      </Row>

      <Form.Item
        className="roboto-regular"
        label={treasureCreation.LocationFormLabelAddress[language]}
      >
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          {form.getFieldDecorator(['address' + ID])(
            <Input
              className="input-treasure"
              placeholder={treasureCreation.LocationFormPlaceHolder1[language]}
              onChange={onAddressChangeHandler}
            />
          )}
        </Autocomplete>
      </Form.Item>

      {role >= roleAdmin && (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              className="roboto-regular"
              label={treasureCreation.LocationFormLabelLatitude[language]}
            >
              {form.getFieldDecorator(['latitude' + ID])(
                <Input
                  className="input-treasure"
                  placeholder={treasureCreation.LocationFormPlaceHolder2[language]}
                  onBlur={onLatChange}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="roboto-regular"
              label={treasureCreation.LocationFormLabelLongitude[language]}
            >
              {form.getFieldDecorator(['longitude' + ID])(
                <Input
                  className="input-treasure"
                  placeholder={treasureCreation.LocationFormPlaceHolder2[language]}
                  onBlur={onLngChange}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row>
        <Row type="flex" justify="space-between" align="middle">
          {treasure.treasureType === sticker ? (
            <Typography.Text className="required-field">
              {treasureCreation.LocationFormLabelNumberOfStickers[language]}
            </Typography.Text>
          ) : (
            <Typography.Text className="required-field">
              {treasureCreation.LocationFormLabelNumberOfUnits[language]}
            </Typography.Text>
          )}
          {/* <Row type="flex">
          <Button className="location-button" onClick={this.calculateNew}>
            <img src={locationRefresh} />
          </Button>
          <Button className="location-button">
            <img src={locationUndo} />
          </Button>
        </Row> */}
        </Row>
      </Row>
      <Form.Item
        className="ant-row ant-form-item roboto-regular"
        style={{
          justifyContent: 'start',
        }}
      >
        {form.getFieldDecorator(['numberOfUnits' + ID], {
          rules: [
            {
              required: true,
              message: treasureCreation.LocationFormNumberOfUnitsError[language],
            },
          ],
        })(
          <InputNumber
            required
            className="input-number"
            min={1}
            onBlur={onNumberOfUnitsChangeHandler}
          />
        )}
        {/* {treasure.treasureType > sticker && this.props.role < roleAdmin && treasure.price && (
          <Typography.Text style={{ marginLeft: '5px' }}>
            {this.props.locations[this.props.locationID_UI].locationPrice
              ? (
                  this.props.locations[this.props.locationID_UI].numberOfUnits * treasure.price
                ).toFixed(2)
              : 0}{' '}
            {treasureReview.lCash[language]}
          </Typography.Text>
        )} */}
      </Form.Item>
    </div>
  );
}

LocationFormItem.propTypes = {
  language: PropTypes.string,
  role: PropTypes.number,
  treasure: PropTypes.object,
  location: PropTypes.object,
  ID: PropTypes.number,
  index: PropTypes.number,
  enableDelete: PropTypes.bool,
  form: PropTypes.object,
  onDeleteLocation: PropTypes.func,
  changeLocation: PropTypes.func,
  selectLocation: PropTypes.func,
  address: PropTypes.object,
  setAddress: PropTypes.func,
  treasureDetails: PropTypes.object,
};

LocationFormItem.defaultProps = {
  language: 'EN',
  role: 800,
  treasure: {},
  onDeleteLocation: () => {},
  changeLocation: () => {},
  changeAddress: () => {},
  selectLocation: () => {},
};

export default LocationFormItem;
