import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import AdminReviewButtons from './AdminReviewButtons';
import TreasureList from './TreasureList';
import * as treasureService from '../../../../services/treasureService';
import { connect } from 'react-redux';
import { detailsConstants } from '../../../../constants/treasureDetailsConstants';

interface Props {
  storeTreasureList?: (treasureList: Array<any>) => void;
  treasureList?: Array<any>;
  role?: number;
  reviewSelected: boolean;
  purgeList: () => void;
}

const mapState = (state: { treasureList: Array<any>; userData: { role: number } }) => {
  const { treasureList, userData } = state;
  const { role } = userData;
  return { treasureList, role };
};

const mapDispatch = (dispatch: any) => {
  return {
    storeTreasureList: (treasureList: Array<any>) =>
      dispatch({ type: detailsConstants.STORE_LIST, treasureList }),
    purgeList: () => dispatch({ type: detailsConstants.DELETE_LIST }),
  };
};

const Review = (props: Props) => {
  useEffect(() => {
    if (props.reviewSelected) {
      props.purgeList();
    }
  }, []);

  const [loading, setloading] = useState(false);
  const [selectedButton, setselectedButton] = useState(null);

  const handleSelectedButton = async (e: { key: string }) => {
    if (e.key === '2') {
      setloading(true);
      setselectedButton(e.key);
      const fetchSelectedResponse = await treasureService.getTreasureByStatus(e.key);
      if (fetchSelectedResponse) {
        props.storeTreasureList(fetchSelectedResponse);
      }
      setloading(false);
    } else if (e.key === '3,4') {
      setloading(true);
      setselectedButton(e.key);
      const fetchApproved = await treasureService.getTreasureByStatus('4');
      const fetchDeclined = await treasureService.getTreasureByStatus('3');
      if (fetchApproved && fetchDeclined) {
        let all = fetchApproved.concat(fetchDeclined);
        all.sort((a: { ID: number }, b: { ID: number }) => {
          return b.ID - a.ID;
        });
        props.storeTreasureList(all);
      }
      setloading(false);
    }
  };

  return (
    <Row className="treasure-list">
      <Row>
        <AdminReviewButtons handleSelectedButton={handleSelectedButton} />
      </Row>
      {selectedButton ? (
        <Col>
          {!loading ? (
            <TreasureList treasureList={props.treasureList} />
          ) : (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Spin size="large" />
            </div>
          )}
        </Col>
      ) : null}
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Review);
