import React from 'react';
import { Row, Typography } from 'antd';
import RankItem from './RankItem';
import * as candyEvent from '../../lib/strings/candyEvent';
import PropTypes from 'prop-types';

function Ranking(props) {
  const { language, rankingData, onContestantClick, contestantIndex } = props;
  return (
    <Row>
      <Row type="flex" style={{ marginBottom: '26px', paddingLeft: '1em' }}>
        <Typography.Text className="candyEvent-title weight-400">
          {candyEvent.ranking[language]}
        </Typography.Text>
      </Row>
      {rankingData &&
        rankingData.map((contestant, index) => {
          let name = contestant.username;
          if (contestant.firstName && contestant.lastName) {
            name = contestant.firstName + ' ' + contestant.lastName;
          }
          return (
            <RankItem
              key={contestant.position}
              rank={contestant.position}
              index={index}
              name={name}
              profileComplete={contestant.profileComplete}
              gender={contestant.gender}
              profilePicUrl={contestant.avatar?.thumbnail?.nameOnServer}
              onClick={onContestantClick}
              selected={contestantIndex === index}
              candyCount={contestant.collectedCandyCount}
            />
          );
        })}
    </Row>
  );
}

Ranking.propTypes = {
  language: PropTypes.string,
  rankingData: PropTypes.array,
  onContestantClick: PropTypes.func,
  contestantIndex: PropTypes.number,
};

Ranking.defaultProps = {
  language: 'EN',
  rankingData: [],
  onContestantClick: () => {},
};

export default Ranking;
