import React from 'react';
import Profile from './Profile';
import { Row } from 'antd';
import MenuButtons from './MenuButtons';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring/renderprops';
import TreasureCreation from '../treasureCreation/TreasureCreation';
import TreasureReview from '../treasureCreation/TreasureReview';
import CreateCandyEvent from './candyEvent/CreateCandyEvent';

const mapState = (state: { menuUIChange: { open: number } }) => {
  return {
    open: state.menuUIChange.open,
  };
};

const pages = [
  (style: any) => (
    <animated.div style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}>
      <MenuButtons />
    </animated.div>
  ),
  (style: any) => (
    <animated.div style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}>
      <TreasureCreation />
    </animated.div>
  ),
  (style: any) => (
    <animated.div style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}>
      <TreasureReview />
    </animated.div>
  ),
  (style) => (
    <animated.div style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}>
      <CreateCandyEvent />
    </animated.div>
  ),
];

interface UserMenuProps {
  open?: number;
}

const UserMenu = (props: UserMenuProps) => {
  return (
    <Row className="menu-wrapper treasure-menu-wrapper">
      <Profile />
      <Transition
        reset
        unique
        items={props.open}
        from={{ height: 0, opacity: 0 }}
        enter={{ height: 'auto', opacity: 1 }}
        leave={{ height: 0, opacity: 0 }}
      >
        {(open: any) => pages[open]}
      </Transition>
    </Row>
  );
};

export default connect(mapState)(UserMenu);
