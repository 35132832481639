import { baseURL, userTransactionHistory, get } from '../lib/config';
import { store } from '../helpers/store';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

export const getBoughtLCashList = async (param: string) => {
  try {
    const fetchResponse = await fetch(
      baseURL +
        userTransactionHistory +
        '?' +
        new URLSearchParams({
          valueType: param,
        }),
      {
        method: get,
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.historyData;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject('failed to fetch payment history');
    }
  } catch (error) {
    return error;
  }
};
