import React, { useState, memo, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { icon_male, icon_female, icon_other } from '../../../assets';
import { baseURL } from '../../../lib/config';
import PropTypes from 'prop-types';

function UserListItem(props) {
  const {
    profilePicUrl,
    username,
    onClick,
    gender,
    index,
    selected,
    transactionText,
    date,
  } = props;

  const [profilePicture, setProfilePicture] = useState(
    profilePicUrl ? baseURL + '/' + profilePicUrl : ''
  );

  useEffect(() => {
    if (profilePicUrl) {
      setProfilePicture(baseURL + '/' + profilePicUrl);
    }
  }, [profilePicUrl]);

  let altProfilePicture = icon_other;
  if (gender === 'male') {
    altProfilePicture = icon_male;
  } else if (gender === 'female') {
    altProfilePicture = icon_female;
  }

  const onProfilePictureError = () => {
    setProfilePicture(altProfilePicture);
  };

  const onClickHandler = () => {
    onClick(index);
  };

  return (
    <div onClick={onClickHandler} style={{ cursor: 'pointer' }}>
      <Row
        type="flex"
        align="middle"
        justify="start"
        className={
          selected ? 'cashExchange-user-item cashExchange-user-selected' : 'cashExchange-user-item'
        }
      >
        <Col span={24}>
          <Row type="flex" align="middle">
            <Col span={2} className="user-item-container">
              <Typography.Text className="user-item-number">{index + 1}.</Typography.Text>
            </Col>
            <Col>
              <img className="profile-img" src={profilePicture} onError={onProfilePictureError} />
            </Col>
            <Col span={16}>
              <Row>
                <Typography.Text className="user-item-name">{username}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="user-item-bellow-name">
                  {date}: {transactionText}
                </Typography.Text>
              </Row>
              {/* Third row
              <Row>
                <Typography.Text className="user-item-bellow-name">{date}</Typography.Text>
              </Row> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

UserListItem.propTypes = {
  index: PropTypes.number,
  profilePicUrl: PropTypes.string,
  username: PropTypes.string,
  transactionText: PropTypes.string,
  profileComplete: PropTypes.bool,
  gender: PropTypes.oneOf(['male', 'female', 'other']),
  selected: PropTypes.bool,
  date: PropTypes.string,
  onClick: PropTypes.func,
};

UserListItem.defaultProps = {
  onClick: (value) => {
    console.log('missing logic:', value);
  },
  profilePicUrl: '',
  gender: 'other',
};

export default memo(UserListItem);
