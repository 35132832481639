import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import TreasureList from './TreasureList';
import * as treasureService from '../../../../services/treasureService';
import { detailsConstants } from '../../../../constants/treasureDetailsConstants';
import { connect } from 'react-redux';

interface Props {
  treasureList: any;
  storeTreasureList: (treasureList: Array<any>) => void;
}

const mapState = (state: { treasureList: any }) => {
  return {
    treasureList: state.treasureList,
  };
};

const mapDispatch = (dispatch: (arg0: { type: string; treasureList: any[] }) => any) => {
  return {
    storeTreasureList: (treasureList: Array<any>) =>
      dispatch({ type: detailsConstants.STORE_LIST, treasureList }),
  };
};

const Drafts = (props: Props) => {
  const [loading, setloading] = useState(false);

  const fetchTreasureList = async () => {
    setloading(true);
    const fetchTreasures = await treasureService.getCreatedTreasure('1');
    if (fetchTreasures) {
      props.storeTreasureList(fetchTreasures);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchTreasureList();
  }, []);

  return (
    <Row className="treasure-list">
      <Col>
        {!loading ? (
          <TreasureList treasureList={props.treasureList} />
        ) : (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Drafts);
