import { treasureConstants } from '../constants/treasureConstants';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import * as treasureService from '../services/treasureService';
import { alertActions } from './alertActions';
import { locationConstants } from '../constants/locationConstants';
import { store } from '../helpers/store';
import * as constant from '../lib/const';
import { calculateRandomLocations } from '../lib/math';
import { detailsConstants } from '../constants/treasureDetailsConstants';
import { notification } from 'antd';
import { menuConstants } from '../constants/menuConstants';
import * as SubMenuConstants from '../lib/SubMenuConstants';
import { positionErr, fileErr, errMsg } from '../lib/strings/error';
import { languages } from '../lib/interfaces/language';
import Geocode from 'react-geocode';

const language: languages = store.getState().authentication.language;

export function calculateTotalPrice(unitPrice: number, numberOfUnits: number) {
  return unitPrice * numberOfUnits;
}

export function selectTreasureType(treasureType: number) {
  return (dispatch: (arg0: { type: string; treasureType?: number }) => void) => {
    (async () => {
      dispatch(treasure(treasureType));
      const currentTreasure = store.getState().treasure;
      const messageLocations = store.getState().messageLocations;

      treasureService.treasureUpdate(currentTreasure.ID, 'treasureType', treasureType);

      // if (treasureType === constant.point && messageLocations.length === 0) {
      //   const locations = store.getState().locations;
      //   locations.forEach(async (location: { radius: Number }) => {
      //     const response = await treasureService.messageLocation(location, location.radius);
      //     dispatch(add(response));
      //   });
      // } else {
      //   messageLocations.forEach((messageLocation: { ID: number }) => {
      //     treasureService.deleteMessageLocation(messageLocation.ID);
      //   });
      //   dispatch(removeAllMsgRadius());
      //   if (treasureType === constant.candy) {
      //     //treasureService.treasureUpdate(currentTreasure.ID, 'treasureType', treasureType)
      //   }
      // }
    })();
  };

  function treasure(treasureType: number) {
    return { type: treasureConstants.TREASURE_TYPE, treasureType };
  }
  function add(response: any) {
    return { type: locationConstants.CREATE_MSG_LOCATION, response };
  }
  function removeAllMsgRadius() {
    return { type: locationConstants.CLEAR_ALL_MSG_RADIUS };
  }
}

export function enterTreasureName(treasureName: string) {
  return (dispatch: (arg0: { type: string; treasureName: string }) => void) => {
    const currentTreasure = store.getState().treasure;

    if (treasureName !== '') {
      dispatch(storeTreasureName(treasureName));
      treasureService.treasureUpdate(currentTreasure.ID, 'treasureName', treasureName);
    }
  };

  function storeTreasureName(treasureName: string) {
    return { type: treasureConstants.TREASURE_NAME, treasureName };
  }
}

export function addNewHashtag(hashtags: string[]) {
  return (dispatch: (arg0: { type: string; hashtags: string[] }) => void) => {
    const currentTreasure = store.getState().treasure;

    let keysOfHastags = {};
    currentTreasure.hashtags.map((hashtag) => {
      keysOfHastags[hashtag] = true;
    });

    hashtags.forEach((hashtag) => {
      if (!keysOfHastags[hashtag]) {
        (async () => {
          const res = await treasureService.updateHashtag(currentTreasure.ID, hashtag); //missing error handling
          dispatch(
            addHashtag({
              hashtag: {
                ...res.hashtag,
                treasureID: currentTreasure.ID,
                hashtagID: res.hashtag.ID,
              },
            })
          );
        })();
      }
    });
  };
  function addHashtag(hashtags: any) {
    return { type: treasureConstants.HASHTAG, hashtags };
  }
}

export function removeHashtags(hashtags: string[]) {
  return (dispatch: (arg0: { type: string; hashtag: any }) => void) => {
    hashtags.forEach((hashtag) => {
      (async () => {
        const res = await treasureService.deleteHashtag(hashtag);
        dispatch(removeHashtag(hashtag));
      })();
    });
  };
  function removeHashtag(hashtag: any) {
    return { type: treasureConstants.TREASURE_REMOVE_HASHTAG, hashtag };
  }
}

export function storeStickerImage(image: File, imgUrl: any, characterID: number) {
  return (dispatch: (arg0: { type: string; image?: File; imgUrl?: any }) => void) => {
    (async () => {
      const currentTreasure = store.getState().treasure;
      dispatch(addStickerImage(image, imgUrl));
    })();
  };
  function addStickerImage(image: File, imgUrl: any) {
    return { type: treasureConstants.STICKER_IMAGE, image, imgUrl };
  }
  // function errorOnPost() { return { type: treasureConstants.FAILED_POST } }
}

export function updateSection(sectionID: number) {
  return (dispatch: (arg0: { type: string; sectionID?: number }) => void) => {
    (async () => {
      const currentTreasure = store.getState().treasure;
      const takenPositions = await treasureService.getSection(currentTreasure.characterID);
      const index = takenPositions.findIndex((section: { ID: number }) => section.ID === sectionID);
      dispatch(storeSection(sectionID, takenPositions[index].usedSectionPositions));
      if (currentTreasure.image && !currentTreasure.hasImagePropsUpdateFailed) {
        treasureService.treasureUpdate(currentTreasure.ID, 'sectionID', sectionID);
      } else if (currentTreasure.hasImagePropsUpdateFailed) {
        const res = await treasureService.updateStickerProps(
          currentTreasure.ID,
          currentTreasure.characterID,
          currentTreasure.sectionID,
          currentTreasure.position
        );
        treasureService.treasureUpdateImage(currentTreasure.ID, currentTreasure.image);
        if (res.code === 1) {
          dispatch(successOnPost());
        }
      }
    })();
  };
  function storeSection(sectionID: number, takenPositions: number) {
    return { type: treasureConstants.UPDATE_SECTION, sectionID, takenPositions };
  }
  function successOnPost() {
    return { type: treasureConstants.FAILED_POST };
  }
}

export function updatePosition(position: number) {
  return (dispatch: (arg0: { type: string; position?: number }) => void) => {
    (async () => {
      const currentTreasure = store.getState().treasure;
      const x = currentTreasure.takenPositions.find((n: number) => n === position);
      console.log(x);
      if (!x) {
        dispatch(storePostion(position));
        if (currentTreasure.image) {
          treasureService.treasureUpdate(currentTreasure.ID, 'sectionPosition', position);
        } else if (!x && currentTreasure.hasImagePropsUpdateFailed) {
          const res = await treasureService.updateStickerProps(
            currentTreasure.ID,
            currentTreasure.characterID,
            currentTreasure.sectionID,
            currentTreasure.position
          );
          treasureService.treasureUpdateImage(currentTreasure.ID, currentTreasure.image);
          if (res.code === 1) {
            dispatch(successOnPost());
          }
        }
      } else {
        notification.error({
          message: positionErr[language],
        });
      }
    })();
  };
  function storePostion(position: number) {
    return { type: treasureConstants.UPDATE_POSITION, position };
  }
  function successOnPost() {
    return { type: treasureConstants.FAILED_POST };
  }
}

export function enterDuration(duration: number, unit: string, durationInHours: any) {
  return (dispatch: (arg0: { type: string; unit?: string; duration?: number }) => void) => {
    const currentTreasure = store.getState().treasure;

    dispatch(addDuration(duration));
    dispatch(addDurationUnit(unit));
    treasureService.treasureUpdate(currentTreasure.ID, 'exposureDuration', durationInHours);
  };
  function addDuration(duration: number) {
    return { type: treasureConstants.DURATION, duration };
  }
  function addDurationUnit(durationUnit: string) {
    return { type: treasureConstants.DURATION_UNIT, durationUnit };
  }
}

export function enterMessagingRule(messagingRule: CheckboxValueType[]) {
  return (dispatch: (arg0: { type: string; messagingRule: CheckboxValueType[] }) => void) => {
    dispatch(addMessagingRule(messagingRule));
  };
  function addMessagingRule(messagingRule: CheckboxValueType[]) {
    return { type: treasureConstants.MSG_RULE, messagingRule };
  }
}

export function addNewLocation(locationID_UI: number) {
  return (dispatch: (arg0: { type: string }) => void) => {
    (async () => {
      try {
        const currentTreasure = store.getState().treasure;

        const address = store.getState().address;

        const response = await treasureService.createLocation(
          currentTreasure.ID,
          address.lat,
          address.lng,
          address.radius
        );

        console.log(
          'treasure action addNewLocation response!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!:',
          response
        );

        if (!response || !response.data || !response.data.location)
          throw 'Failed to create location';

        dispatch(locationSuccess(response.data.location));

        // if (currentTreasure.treasureType === constant.point) {
        //   const messageLocationResponse = await treasureService.messageLocation(
        //     response.data.location,
        //     constant.DefaultRadius
        //   );
        //   dispatch(messageLocationSuccess(messageLocationResponse.data.location));
        // }

        const last = store.getState().locations.length;
        dispatch(selectNew(last));
        dispatch(treasureUnitsNotSent(locationID_UI));
      } catch (error) {
        console.error(error);
        dispatch(alertActions.error(error));
      }
    })();
  };
  function locationSuccess(response: any) {
    return { type: locationConstants.CREATE_LOCATION, response };
  }
  function messageLocationSuccess(response: any) {
    return { type: locationConstants.CREATE_MSG_LOCATION, response };
  }
  function selectNew(last: number) {
    return { type: locationConstants.UPDATE_SELECTED, last };
  }
  function treasureUnitsNotSent(locationID_UI: number) {
    return { type: locationConstants.NOT_SENT, locationID_UI };
  }
}

export function deleteLocation(locationID_UI: number) {
  return (
    dispatch: (arg0: { type: string; locationID_UI?: number; locationID?: number }) => void
  ) => {
    const locationID = store.getState().locations[locationID_UI].ID;
    const currentTreasure = store.getState().treasure;
    treasureService.deleteLocation(locationID);
    dispatch(remove(locationID_UI));
    if (currentTreasure.treasureType === constant.point) {
      dispatch(removeMessagingRadius(locationID, locationID_UI));
    }
    dispatch(removeTreasureUnits(locationID_UI));
    const numberOfLocations = store.getState().locations.length;
    dispatch(changeSelectedLocation(numberOfLocations - 1));
  };
  function remove(locationID_UI: number) {
    return { type: locationConstants.DELETE_LOCATION, locationID_UI };
  }
  function removeMessagingRadius(locationID: number, locationID_UI: number) {
    return { type: locationConstants.DELETE_ALL_MSG_RADIUS, locationID, locationID_UI };
  }
  function removeTreasureUnits(locationID_UI: number) {
    return { type: locationConstants.DELETE_UNITS, locationID_UI };
  }
  function changeSelectedLocation(newSelected: number) {
    return { type: locationConstants.CHANGE_AFTER_DELETE, newSelected };
  }
}

export function enterAddress(response: any, locationID_UI: number) {
  return (
    dispatch: (arg0: {
      type: string;
      address?: any;
      locationID_UI?: number;
      lat?: number;
      lng?: number;
    }) => void
  ) => {
    const idle = true;
    (async () => {
      try {
        const currentTreasure = store.getState().treasure;
        const resLatLng = response.geometry.location;
        const latLng = {
          lat: await resLatLng.lat(),
          lng: await resLatLng.lng(),
        };
        dispatch(updateAddressOnMap(latLng));

        // const location = store.getState().locations[locationID_UI];
        // treasureService.updateLocationLatLng(latLng as any, location);

        // dispatch(updateLocation(latLng.lat, latLng.lng, locationID_UI));

        // const locationID = store.getState().locations[locationID_UI].ID;

        // if (currentTreasure.treasureType === constant.point) {
        //   const messageLocations = store.getState().messageLocations;

        //   dispatch(updateMessageLocation(latLng.lat as any, latLng.lng as any, locationID));
        //   messageLocations.forEach((messageLocation: any) => {
        //     if (messageLocation.locationID === locationID)
        //       treasureService.updateMessageLocationLatLng(
        //         messageLocation.ID,
        //         latLng.lat,
        //         latLng.lng
        //       );
        //   });
        // }

        // saveTreasureUnit(
        //   store.getState().treasureUnitsRandomNumbers,
        //   store.getState().selectedLocation,
        //   storeLocations,
        //   saveUnitResponse,
        //   alreadySent,
        //   idle
        // );
      } catch (error) {
        throw 'error';
      }
    })();
  };
  function updateAddressOnMap(address: any) {
    return { type: locationConstants.UPDATE_MAP_ADDRESS, address };
  }
  function updateLocation(lat: number, lng: number, locationID_UI: number) {
    return { type: locationConstants.UPDATE_LOCATION_ADDRESS, lat, lng, locationID_UI };
  }
  function updateMessageLocation(lat: number, lng: number, locationID: number) {
    return { type: locationConstants.UPDATE_MSG_LOCATION, lat, lng, locationID };
  }
  function storeLocations(treasureUnits: Array<any>, locationID_UI: number) {
    return { type: locationConstants.STORE_UNITS, treasureUnits, locationID_UI };
  }
  function saveUnitResponse(response: any, locationID_UI: number) {
    return { type: locationConstants.STORE_RESPONSE, response, locationID_UI };
  }
  function alreadySent(locationID_UI: number) {
    return { type: locationConstants.ALREADY_SENT };
  }
}

export function enterLatLng(latLng: any, locationID_UI: number) {
  console.log('latLng:', latLng);
  return (
    dispatch: (arg0: {
      type: string;
      address?: any;
      locationID_UI?: number;
      lat?: number;
      Lng?: number;
    }) => void
  ) => {
    const idle = true;
    (async () => {
      try {
        const currentTreasure = store.getState().treasure;
        dispatch(updateAddressOnMap(latLng));

        // const location = store.getState().locations[locationID_UI];
        // treasureService.updateLocationLatLng(latLng as any, location);

        // dispatch(updateLocation(latLng.lat, latLng.lng, locationID_UI));

        // const locationID = store.getState().locations[locationID_UI].ID;

        // if (currentTreasure.treasureType === constant.point) {
        //   const messageLocations = store.getState().messageLocations;

        //   dispatch(updateMessageLocation(latLng.lat as any, latLng.Lng as any, locationID));
        //   messageLocations.forEach((messageLocation: any) => {
        //     if (messageLocation.locationID === locationID)
        //       treasureService.updateMessageLocationLatLng(
        //         messageLocation.ID,
        //         latLng.lat,
        //         latLng.Lng
        //       );
        //   });
        // }

        // saveTreasureUnit(
        //   store.getState().treasureUnitsRandomNumbers,
        //   store.getState().selectedLocation,
        //   storeLocations,
        //   saveUnitResponse,
        //   alreadySent,
        //   idle
        // );
      } catch (error) {
        throw 'error';
      }
    })();
  };
  function updateAddressOnMap(address: any) {
    return { type: locationConstants.UPDATE_MAP_ADDRESS, address };
  }
  function updateLocation(lat: number, lng: number, locationID_UI: number) {
    return { type: locationConstants.UPDATE_LOCATION_ADDRESS, lat, lng, locationID_UI };
  }
  function updateMessageLocation(lat: number, lng: number, locationID: number) {
    return { type: locationConstants.UPDATE_MSG_LOCATION, lat, lng, locationID };
  }
  function storeLocations(treasureUnits: Array<any>, locationID_UI: number) {
    return { type: locationConstants.STORE_UNITS, treasureUnits, locationID_UI };
  }
  function saveUnitResponse(response: any, locationID_UI: number) {
    return { type: locationConstants.STORE_RESPONSE, response, locationID_UI };
  }
  function alreadySent(locationID_UI: number) {
    return { type: locationConstants.ALREADY_SENT };
  }
}

export function updateMapAddressOnIdle() {
  return (dispatch: (arg0: { type: string; lat?: number; lng?: number }) => void) => {
    const idle = true;
    (async () => {
      const currentTreasure = store.getState().treasure;
      const mapCenter = store.getState().address.mapInstance.getCenter();
      const address = { lat: mapCenter.lat(), lng: mapCenter.lng() };
      dispatch(update(address));
      // const { lat, lng } = address;

      // if (store.getState().locations.length === 0) return;

      // const location = store.getState().locations[store.getState().selectedLocation];

      // treasureService.updateLocationLatLng(address, location);

      // dispatch(updateLocation(lat, lng, store.getState().selectedLocation));
      // const selected = store.getState().selectedLocation;
      // const id = store.getState().locations[selected].ID;
      // if (currentTreasure.treasureType === constant.point) {
      //   const messageLocations = store.getState().messageLocations;

      //   dispatch(updateMessagingLocation(lat, lng, id));

      //   messageLocations.forEach((messageLocation: { locationID: number; ID: number }) => {
      //     if (messageLocation.locationID === id) {
      //       treasureService.updateMessageLocationLatLng(messageLocation.ID, lat, lng);
      //     }
      //   });
      // }
    })();
    // saveTreasureUnit(
    //   store.getState().treasureUnitsRandomNumbers,
    //   store.getState().selectedLocation,
    //   storeLocations,
    //   saveUnitResponse,
    //   alreadySent,
    //   idle
    // );
  };

  function update(address: { lat: number; lng: number }) {
    return { type: locationConstants.UPDATE_MAP_ADDRESS, address };
  }
  function updateLocation(lat: number, lng: number, locationID_UI: number) {
    return { type: locationConstants.UPDATE_LOCATION_ON_IDLE, lat, lng, locationID_UI };
  }
  function updateMessagingLocation(lat: number, lng: number, locationID: number) {
    return { type: locationConstants.UPDATE_MSG_LOCATION, lat, lng, locationID };
  }
  function storeLocations(treasureUnits: Array<any>, locationID_UI: number) {
    return { type: locationConstants.STORE_UNITS, treasureUnits, locationID_UI };
  }
  function saveUnitResponse(response: any, locationID_UI: number) {
    return { type: locationConstants.STORE_RESPONSE, response, locationID_UI };
  }
  function alreadySent(locationID_UI: number) {
    return { type: locationConstants.ALREADY_SENT };
  }
}

export function enterNumberOfUnits(numberOfUnits: string, ID: number) {
  return (dispatch: (arg0: { type: string; numberOfUnits: number; ID: number }) => void) => {
    const currentTreasure = store.getState().treasure;
    const locationPrice = calculateTotalPrice(currentTreasure.price, Number(numberOfUnits));
    dispatch(addNumberOfUnits(Number(numberOfUnits), ID, Number(locationPrice)));

    const location = store.getState().locations[ID];
    treasureService.updateNumberOfUnits(location, numberOfUnits, ID);
  };
  function addNumberOfUnits(numberOfUnits: number, ID: number, locationPrice: number) {
    return { type: locationConstants.NO_OF_UNITS, numberOfUnits, ID, locationPrice };
  }
}

export function updateTotalNumberOfUnits() {
  return (dispatch: any) => {
    const currentTreasure = store.getState().treasure;

    const locations = store.getState().locations;
    let count: number = 0;
    let totalPrice: number;
    locations.forEach((location: { numberOfUnits: number }) => {
      count = count + Number(location.numberOfUnits);
    });
    totalPrice = calculateTotalPrice(currentTreasure.price, count);

    dispatch(countNumberOfUnits(count, totalPrice));
    treasureService.treasureUpdate(currentTreasure.ID, 'totalNumberOfUnits', count);
  };
  function countNumberOfUnits(totalNumberOfUnits: number, totalPrice: number) {
    return { type: treasureConstants.TOTAL_NUMBER_OF_UNITS, totalNumberOfUnits, totalPrice };
  }
}

export function storeLocationRadius(locationRadius: number, selectedLocation: number) {
  return (
    dispatch: (arg0: {
      type: string;
      locationRadius?: number;
      locationID_UI?: number;
      messagingRadius?: number;
      selectedLocation?: number;
    }) => void
  ) => {
    const idle = true;
    const zoom = store.getState().address.zoom;
    if (zoom >= 14) locationRadius = locationRadius / 1000;

    // dispatch(addRadius(locationRadius, selectedLocation));

    // const selectedLocationID = store.getState().locations[selectedLocation].ID;

    // const currentTreasure = store.getState().treasure;
    // // const currentLocation = store.getState().locations[selectedLocation]
    // treasureService.updateLocationRadius(selectedLocationID, locationRadius);

    // if (currentTreasure.treasureType === constant.point) {
    //   const msgLocs = store.getState().messageLocations;
    //   dispatch(changeMsgLocationRadius(locationRadius, selectedLocationID));
    //   msgLocs.forEach((messageLocation: { locationID: any; ID: number }) => {
    //     if (messageLocation.locationID === selectedLocationID) {
    //       treasureService.updateMessageLocationRadius(locationRadius, messageLocation.ID);
    //     }
    //   });
    // }

    // saveTreasureUnit(
    //   store.getState().treasureUnitsRandomNumbers,
    //   store.getState().selectedLocation,
    //   storeLocations,
    //   saveUnitResponse,
    //   alreadySent,
    //   idle
    // );
  };
  function addRadius(locationRadius: number, locationID_UI: number) {
    return { type: 'LOCATION_RADIUS_INPUT', locationRadius, locationID_UI };
  }
  function changeMsgLocationRadius(messagingRadius: number, selectedLocation: number) {
    return { type: locationConstants.UPDATE_ALL_MSG_RADIUS, messagingRadius, selectedLocation };
  }
  function storeLocations(treasureUnits: Array<any>, locationID_UI: number) {
    return { type: locationConstants.STORE_UNITS, treasureUnits, locationID_UI };
  }
  function saveUnitResponse(response: any, locationID_UI: number) {
    return { type: locationConstants.STORE_RESPONSE, response, locationID_UI };
  }
  function alreadySent(locationID_UI: number) {
    return { type: locationConstants.ALREADY_SENT };
  }
}

export function enterMessagingRadius(messagingRadius: number, messageLocationID_UI: number) {
  return (dispatch: (arg0: { type: string; messagingRadius: number }) => void) => {
    const id = store.getState().messageLocations[messageLocationID_UI].ID;
    dispatch(updateMessageLocationRadius(messagingRadius, messageLocationID_UI));
    treasureService.updateMessageLocationRadius(messagingRadius, id);
  };
  function updateMessageLocationRadius(messagingRadius: number, messageLocationID_UI: number) {
    return { type: locationConstants.UPDATE_MSG_RADIUS, messagingRadius, messageLocationID_UI };
  }
}

export function addNewMessagingRadius(locationID_UI: number) {
  return (dispatch: (arg0: { type: string }) => void) => {
    (async () => {
      try {
        const location = store.getState().locations[locationID_UI];
        // const response = await treasureService.messageLocation(location, constant.DefaultRadius);

        // dispatch(add(response.data.location));
      } catch (error) {
        throw 'error';
      }
    })();
  };
  function add(response: any) {
    return { type: locationConstants.CREATE_MSG_LOCATION, response };
  }
}

export function deleteMessagingRadius(messageLocationID_UI: number) {
  return (dispatch: (arg0: { type: string }) => void) => {
    const messageLocation = store.getState().messageLocations[messageLocationID_UI];

    treasureService.deleteMessageLocation(messageLocation.ID);

    dispatch(remove(messageLocationID_UI));
  };
  function remove(messageLocationID_UI: number) {
    return { type: locationConstants.DELETE_MSG_RADIUS, messageLocationID_UI };
  }
}

export function selectedLocation(selectedValue: string) {
  return (dispatch: (arg0: { type: string; selected: number }) => void) => {
    dispatch(select(Number(selectedValue)));
  };
  function select(selected: number) {
    return { type: locationConstants.SELECTED, selected };
  }
}

export function calculateRandomNumbers(locationID_UI: number, numberOfUnits: number) {
  return (dispatch: (arg0: { type: string }) => void) => {
    // const locationID = store.getState().locations[locationID_UI].ID;
    // const treasureType = store.getState().treasure.treasureType;
    // const arrayOfUnits: Array<any> = [];
    // const idle = false;
    // for (let i = 0; i < numberOfUnits; i++) {
    //   const u = Math.random();
    //   const v = Math.random();
    //   arrayOfUnits.push({ u: u, v: v, locationID: locationID, locationID_UI: locationID_UI });
    // }
    // if (treasureType === constant.candy) {
    //   const treasureUnitCandy = store.getState().treasureUnitCandy;
    //   arrayOfUnits.forEach((unit, index) => {
    //     unit.candyCount = treasureUnitCandy[index];
    //   });
    // }
    // dispatch(addTreasureUnits(arrayOfUnits, locationID_UI));
    // saveTreasureUnit(
    //   store.getState().treasureUnitsRandomNumbers,
    //   locationID_UI,
    //   storeLocations,
    //   saveUnitResponse,
    //   alreadySent,
    //   idle
    // );
  };
  function addTreasureUnits(latLng: Array<any>, locationID: number) {
    return { type: locationConstants.CALCULATED_UNITS, latLng, locationID };
  }
  function storeLocations(treasureUnits: Array<any>, locationID_UI: number) {
    return { type: locationConstants.STORE_UNITS, treasureUnits, locationID_UI };
  }
  function saveUnitResponse(response: any) {
    return { type: locationConstants.STORE_RESPONSE, response, locationID_UI };
  }
  function alreadySent(locationID_UI: number) {
    return { type: locationConstants.ALREADY_SENT, locationID_UI };
  }
}

export function calculateCandiesInUnits(numberOfUnits: number) {
  return (dispatch: (arg0: { type: string; randomCandyNumbers: string[] }) => void) => {
    let oneCandyUnits: number = numberOfUnits * 0.6;
    let twoCandyUnits = numberOfUnits * 0.3;
    let threeCandyUnits = numberOfUnits * 0.1;
    let arrayOfOne = new Array(Number(oneCandyUnits.toFixed())).fill('1');
    let arrayOfTwo = new Array(Number(twoCandyUnits.toFixed())).fill('2');
    let arrayOfThree = new Array(Number(threeCandyUnits.toFixed())).fill('3');

    let arrayOfCandyValues: Array<string> = arrayOfOne.concat(arrayOfTwo, arrayOfThree);

    dispatch(storeArrayOfRandomNumbers(arrayOfCandyValues));
  };
  function storeArrayOfRandomNumbers(randomCandyNumbers: Array<string>) {
    return { type: treasureConstants.RANDOM_CANDY, randomCandyNumbers };
  }
}

export function saveTreasureUnit(
  treasureUnits: Array<any>,
  locationID_UI: number,
  storeLocations: (units: Array<any>, locationID_UI: number) => void,
  saveUnitResponse: (response: any, locationID_UI: number) => void,
  alreadySent: (locationID_UI: number) => void,
  idle: boolean
) {
  const latLng: any[] = [];
  const sent = store.getState().treasureUnitsSent[locationID_UI];
  const treasureType = store.getState().treasure.treasureType;

  if (treasureType !== constant.candy) {
    for (let key in treasureUnits) {
      if (Number(key) === locationID_UI) {
        treasureUnits[key].map((unit: any) => {
          return latLng.push(calculateRandomLocations(unit.u, unit.v, locationID_UI));
        });
      }
    }
  } else {
    for (let key in treasureUnits) {
      if (Number(key) === locationID_UI) {
        treasureUnits[key].map((unit: any) => {
          return latLng.push(
            calculateRandomLocations(unit.u, unit.v, locationID_UI, unit.candyCount)
          );
        });
      }
    }
  }

  // store.dispatch(storeLocations(latLng, locationID_UI));

  (async () => {
    if (!sent) {
      const res = await treasureService.treasureUnit(latLng, locationID_UI);

      const responseToArray = Object.keys(res).map((key) => {
        return res[key];
      });
      store.dispatch(saveUnitResponse(responseToArray, locationID_UI));
      store.dispatch(alreadySent(locationID_UI));
    } else if (idle && sent) {
      //updateOnDrag
      const storedTreasureUnits = store.getState().treasureUnitsResponse[`${locationID_UI}`];
      const unitResponse = [];

      for (let key in storedTreasureUnits) {
        const res = await treasureService.updateTreasureUnit(
          storedTreasureUnits[key].ID,
          latLng[Number(key)]
        );
        unitResponse.push(res);
      }
      store.dispatch(saveUnitResponse(unitResponse, locationID_UI));
      store.dispatch(alreadySent(locationID_UI));
    } else {
      //deletes previously created units and get new ones, on input change and refresh button
      const storedTreasureUnits = store.getState().treasureUnitsResponse[`${locationID_UI}`];
      if (storedTreasureUnits.length === treasureUnits[locationID_UI].length) {
        const unitResponse = [];
        for (let key in storedTreasureUnits) {
          const res = await treasureService.updateTreasureUnit(
            storedTreasureUnits[key].ID,
            latLng[Number(key)]
          );
          unitResponse.push(res);
        }
        store.dispatch(saveUnitResponse(unitResponse, locationID_UI));
        store.dispatch(alreadySent(locationID_UI));
      } else {
        for (let key in storedTreasureUnits) {
          treasureService.deleteTreasureUnit(storedTreasureUnits[key]);
        }
        const res = await treasureService.treasureUnit(latLng);
        var responseToArray = Object.keys(res).map((key) => {
          return res[key];
        });
        store.dispatch(saveUnitResponse(responseToArray, locationID_UI));
        store.dispatch(alreadySent(locationID_UI));
      }
    }
  })();
}

export function updateZoom(zoom: number, radius: number) {
  return (dispatch: (arg0: { type: string }) => void) => {
    const idle = true;
    dispatch(update(zoom, radius));
    // (async () => {
    //   const currentTreasure = store.getState().treasure;
    //   const selectedLocation = store.getState().selectedLocation;
    //   const x = store.getState().menuUIChange.open === 1 ? true : false;
    //   dispatch(update(zoom, radius, selectedLocation));
    //   if (x) {
    //     const selectedLocationID = store.getState().locations[selectedLocation].ID;
    //     await treasureService.updateLocationRadius(selectedLocationID, radius);
    //     dispatch(updateMessageLocationRadius(radius, selectedLocationID));
    //     if (currentTreasure.treasureType === constant.point) {
    //       const msgLocs = store.getState().messageLocations;
    //       msgLocs.forEach((messageLocation: { locationID: any; ID: number }) => {
    //         if (messageLocation.locationID === selectedLocationID)
    //           treasureService.updateMessageLocationRadius(radius, messageLocation.ID);
    //       });
    //     }
    //     saveTreasureUnit(
    //       store.getState().treasureUnitsRandomNumbers,
    //       store.getState().selectedLocation,
    //       storeLocations,
    //       saveUnitResponse,
    //       alreadySent,
    //       idle
    //     );
    //   }
    // })();
  };
  function update(zoom: number, radius: number, locationID_UI?: number) {
    return { type: locationConstants.LOCATION_RADIUS, zoom, radius, locationID_UI };
  }
  function updateMessageLocationRadius(messagingRadius: number, selectedLocation: number) {
    return { type: locationConstants.UPDATE_ALL_MSG_RADIUS, messagingRadius, selectedLocation };
  }
  function storeLocations(treasureUnits: Array<any>, locationID_UI: number) {
    return { type: locationConstants.STORE_UNITS, treasureUnits, locationID_UI };
  }
  function saveUnitResponse(response: any, locationID_UI: number) {
    return { type: locationConstants.STORE_RESPONSE, response, locationID_UI };
  }
  function alreadySent(locationID_UI: number) {
    return { type: locationConstants.ALREADY_SENT };
  }
}

export function getTreasureByID(treasureID: number) {
  return (dispatch: (arg0: { type: string; details?: any; menu?: string }) => void) => {
    (async () => {
      const details = await treasureService.getTreasureByID(treasureID);

      const treasureLocations = details.treasure?.locations;

      const newLocations = await recalculateLocations(treasureLocations);
      console.log('newLocations:', newLocations);
      details.treasure.locations = newLocations;

      dispatch(storeTreasure(details));
      dispatch(openSubMenu1(SubMenuConstants.treasureDetails));
    })();
  };

  async function getAddressFromLatLng(lat, lng) {
    const geo = await Geocode.fromLatLng(lat, lng);
    const address = geo.results[0].formatted_address;
    return address;
  }

  function calculateZoom(latitude, radius, mapInstance) {
    let zoom =
      Math.log(
        200 *
          ((156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
            (mapInstance.getZoom() >= 14 ? radius : radius * 1000))
      ) / Math.log(2);

    return Math.round(zoom);
  }
  async function recalculateLocations(locations) {
    //calculates address, zoom, selected and add it to treasure locations
    const address = store.getState().address;

    if (locations && locations.length > 0) {
      const newLocations = await Promise.all(
        locations.map(async (location, index) => {
          const addressLocation = await getAddressFromLatLng(location.latitude, location.longitude);

          if (index === 0) {
            return {
              ...location,
              selected: true,
              zoom: calculateZoom(location.latitude, location.radius, address.mapInstance),
              address: addressLocation,
              numberOfUnits: location.unitCopies,
            };
          }
          return {
            ...location,
            selected: false,
            zoom: calculateZoom(location.latitude, location.radius, address.mapInstance),
            address: addressLocation,
            numberOfUnits: location.unitCopies,
          };
        })
      );

      return newLocations;
    }
    return [];
  }
  function storeTreasure(details: any) {
    return { type: detailsConstants.STORE_DETAILS, details };
  }
  function openSubMenu1(menu: string) {
    return { type: menuConstants.OPEN_SUBMENU_1, menu };
  }
}

export function selectCharacter(characterID: string) {
  return (dispatch: (arg0: { type: string; characterID: string }) => void) => {
    const currentTreasure = store.getState().treasure;
    dispatch(selectedCharacter(characterID));
    if (currentTreasure.image) {
      treasureService.treasureUpdate(currentTreasure.ID, 'characterID', Number(characterID));
    }
  };
  function selectedCharacter(characterID: string) {
    return { type: treasureConstants.SELECTED_CHARACTER, characterID };
  }
}

export function typeOfSticker(typeOfSticker: string) {
  return (dispatch: any) => {
    dispatch(type(typeOfSticker));
  };
  function type(typeOfSticker: string) {
    return { type: treasureConstants.TYPE_OF_STICKER, typeOfSticker };
  }
}

export function addTreasurePoints(treasurePoints: number) {
  return (dispatch: (arg0: { type: string; pointsPerUnit?: number; price?: number }) => void) => {
    const currentTreasure = store.getState().treasure;
    const totalPrice = calculateTotalPrice(
      currentTreasure.price,
      currentTreasure.totalNumberOfUnits
    );

    dispatch(storeTreasurePoints(Number(treasurePoints), totalPrice));
    if (currentTreasure.image) {
      treasureService.updatePointProps(
        currentTreasure.ID,
        treasurePoints,
        currentTreasure.kitTypeID
      );
      treasureService.treasureUpdateAdvertisement(currentTreasure.ID, currentTreasure.image);
    }
  };
  function storeTreasurePoints(pointsPerUnit: number, totalPrice: number) {
    return { type: treasureConstants.TREASURE_POINTS, pointsPerUnit, totalPrice };
  }
}

// not used in Advertisement kit anymore
export function storePointAdvertisement(
  image: File,
  imgUrl: any,
  pointsPerUnit: number,
  fileType: string,
  kitType: string
) {
  return (dispatch: (arg0: { type: string; image: File; imgUrl: any }) => void) => {
    const currentTreasure = store.getState().treasure;
    let isVideo = false;
    if (kitType === 'video') {
      if (fileType.includes('video')) {
        isVideo = true;
        dispatch(addPointAdvertisement(image, imgUrl, isVideo));
      } else {
        notification.error({ message: fileErr[language] });
        return;
      }
    } else if (kitType === 'image') {
      if (fileType.includes('image')) {
        dispatch(addPointAdvertisement(image, imgUrl, isVideo));
      } else {
        notification.error({ message: fileErr });
        return;
      }
    } else {
      notification.error({ message: errMsg });
      return;
    }
    treasureService.updatePointProps(currentTreasure.ID, pointsPerUnit, currentTreasure.kitTypeID);
    treasureService.treasureUpdateAdvertisement(currentTreasure.ID, image);
  };
  function addPointAdvertisement(image: File, imgUrl: any, isVideo: boolean) {
    return { type: treasureConstants.STICKER_IMAGE, image, imgUrl, isVideo };
  }
}

export function updateKitOptions(
  treasurePoints: number,
  price: number,
  kitTypeID: number,
  kitType: string
) {
  return (
    dispatch: (arg0: {
      type: string;
      pointsPerUnit?: number;
      totalPrice?: number;
      price?: number;
      points?: number;
    }) => void
  ) => {
    const currentTreasure = store.getState().treasure;
    const totalPrice = calculateTotalPrice(price, currentTreasure.totalNumberOfUnits);

    dispatch(storeTreasurePoints(Number(treasurePoints), totalPrice, price, kitType));
    treasureService.updatePointProps(currentTreasure.ID, treasurePoints, kitTypeID);
    // if (currentTreasure.image) {
    //   console.log('treasureService.updatePointProps:');
    //   console.log('currentTreasure', currentTreasure.ID);
    //   console.log('treasurePoints', treasurePoints);
    //   console.log('currentTreasure.kitTypeID', currentTreasure.kitTypeID);
    //   treasureService.treasureUpdateAdvertisement(currentTreasure.ID, currentTreasure.image);
    //   console.log('treasureService.treasureUpdateAdvertisement:');
    //   console.log('currentTreasure.ID', currentTreasure.ID);
    //   console.log('currentTreasure.image', currentTreasure.image);
    // }
  };
  function storeTreasurePoints(
    pointsPerUnit: number,
    totalPrice: number,
    price: number,
    kitType: string
  ) {
    return {
      type: treasureConstants.TREASURE_POINTS,
      pointsPerUnit,
      totalPrice,
      price,
      kitTypeID,
      kitType,
    };
  }
}

export function makeAd(useAdMaker: boolean) {
  return (dispatch: (arg0: { type: string; useAdMaker: boolean }) => void) => {
    dispatch(adMaker(useAdMaker));
  };
  function adMaker(useAdMaker: boolean) {
    return { type: treasureConstants.USE_AD_MAKER, useAdMaker };
  }
}

export const inputParameters = {
  treasureName: 'treasureName',
  hashtag: 'hashtag',
  startDate: 'startDate',
  endDate: 'endDate',
  description: 'description',
  discount_value: 'discount_value',
  ldiscountExpirationDate: 'ldiscountExpirationDate',

  kitTypeID: 'kitTypeID',

  couponAddress: 'couponAddress',
  couponLatitude: 'couponLatitude',
  couponLongitude: 'couponLongitude',

  characterID: 'characterID',
  sectionID: 'sectionID',
  sectionPosition: 'sectionPosition',

  useReferral: 'useReferral',
  partnerUsername: 'partnerUsername',
};

export function handleInput(name: string, value: any) {
  return (dispatch: (arg0: { type: string; name: string; value: any }) => void) => {
    dispatch(storeValues(name, value));
  };
  function storeValues(name: string, value: any) {
    return { type: treasureConstants.AD_MAKER_INPUT, name, value };
  }
}

export function openSubMenu1(menu: string) {
  return (dispatch: (arg0: { type: string; menu: string }) => void) => {
    dispatch(open(menu));
  };
  function open(menu: string) {
    return { type: menuConstants.OPEN_SUBMENU_1, menu };
  }
}

export function updateMediaPath(payload) {
  return { type: treasureConstants.UPDATE_MEDIA_PATH, payload };
}

export function addPointAdvertisement(image: File, imgUrl: string, isVideo: boolean) {
  return { type: treasureConstants.STICKER_IMAGE, image, imgUrl, isVideo };
}

export function treasureBatchEdit(payload: any) {
  return { type: treasureConstants.TREASURE_BATCH_EDIT, payload };
}

export function singleStoreToTreasure(name: string, value: any) {
  return { type: treasureConstants.AD_MAKER_INPUT, name, value };
}

export function editTreasureListParametar(ID: number, parametarName: string, value: any) {
  return { type: detailsConstants.EDIT_TREASURE_LIST_DATA_PARAMETAR, ID, parametarName, value };
}

export function editTreasureDetailParametar(parametarName: string, value: any) {
  return { type: detailsConstants.EDIT_TREASURE_DETAILS_PARAMETAR, parametarName, value };
}

export function addTreasureErrors(payload: object) {
  return { type: treasureConstants.TREASURE_ERROR_ADD, payload };
}

export function resetTreasureErrors() {
  return { type: treasureConstants.TREASURE_ERROR_RESET };
}
