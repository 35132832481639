import React, { Component } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import imgPlaceholder from '../../../assets/profile_picture.svg';
import Logout from '../../../assets/home_logout_icon.svg';
import * as userActions from '../../../actions/signinActions';
import { connect } from 'react-redux';
import * as userService from '../../../services/signinService';
import { baseURL } from '../../../lib/config';
import { roleMaster, rolePartner, roleAdmin, roleSuperadmin } from '../../../lib/const';
import lcashIcon from '../../../assets/lcash.svg';
import { admin, superadmin, master, masterPartner } from '../../../lib/strings/appConstants';
import { languages } from '../../../lib/interfaces/language';
import emojiFlags from 'emoji-flags';
import Flag from 'react-flagkit';

const { Text } = Typography;

const actionCreators = (dispatch: any) => {
  return {
    onClickLogout: () => dispatch(userActions.signout()),
  };
};

const mapState = (state: {
  authentication: { user: any; language: string };
  userData: any;
  menuUIChange: any;
}) => {
  const { authentication, userData, menuUIChange } = state;
  const { user, language } = authentication;
  const { role, lcash } = userData;
  const { country } = userData;
  return { user, userData, menuUIChange, role, lcash, language, country };
};

interface ProfileInfoProps {
  onClickLogout: () => void;
  user?: any;
  userData: any;
  menuUIChange: any;
  role: number;
  lcash?: number;
  language: languages;
  country: any;
}

interface ProfileInfoState {
  img: any;
  username: string;
  phoneNumber: string;
  lcash: number;
}

export class ProfileInfo extends Component<ProfileInfoProps, ProfileInfoState> {
  constructor(props: Readonly<ProfileInfoProps>) {
    super(props);

    this.state = {
      img: imgPlaceholder,
      username: '',
      phoneNumber: '',
      lcash: 0,
    };
  }

  fetchUserData = async () => {
    const res = await userService.getUserData(this.props.user.user._id);
    return res;
  };

  componentDidMount = async () => {
    if (this.props.userData.avatar && this.props.userData.avatar.picture) {
      this.setState({
        img: `${baseURL}/uploads/${this.props.userData.avatar.picture.nameOnServer}`,
      });
    }

    if (this.props.userData.lcash) {
      this.setState({
        lcash: this.props.userData.lcash,
      });
    }
  };

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData.avatar && this.props.userData.avatar.picture) {
        this.setState({
          img: `${baseURL}/uploads/${this.props.userData.avatar.picture.nameOnServer}`,
        });
      }
    }
    if (prevProps.lcash !== this.props.lcash) {
      if (this.props.lcash) {
        this.setState({
          lcash: this.props.lcash,
        });
      }
    }
  };

  switchRole = () => {
    const countryFlag = this.props.country ? (
      <Flag country={this.props.country.toUpperCase()} style={{ marginBottom: 2 }} />
    ) : null;
    switch (this.props.role) {
      case roleMaster:
        return (
          <>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">{master[this.props.language]} </Text>
              {countryFlag}
            </Row>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">
                <img style={{ marginRight: '.2em' }} src={lcashIcon} />
                {this.state.lcash}
              </Text>
            </Row>
          </>
        );

      case rolePartner:
        return (
          <>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">{masterPartner[this.props.language]} </Text>
              {countryFlag}
            </Row>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">
                <img style={{ marginRight: '.2em' }} src={lcashIcon} />
                {this.state.lcash}
              </Text>
            </Row>
          </>
        );

      case roleAdmin:
        return (
          <Row className="center large-text-no-padding">
            <Text className="roboto-regular">{admin[this.props.language]} </Text>
            {countryFlag}
          </Row>
        );

      case roleSuperadmin:
        return (
          <Row className="center large-text-no-padding">
            <Text className="roboto-regular">{superadmin[this.props.language]} </Text>
            {countryFlag}
          </Row>
        );
      default:
        break;
    }
  };

  render() {
    return (
      <div className="profile-info-safari-fix">
        <Row type="flex" justify="center" className="profile-info-wrapper">
          <Col>
            <Row type="flex" justify="center" align="middle">
              <img alt="profile photo" src={this.state.img} className="profile-photo" />
            </Row>
            <Row>
              <Col className="center large-text-no-padding">
                <Text className="fredoka">{this.props.user.user.username}</Text>
              </Col>
            </Row>
            {this.switchRole()}{' '}
          </Col>
          <Button className="button-logout" onClick={this.props.onClickLogout}>
            <img src={Logout} className="logout-icon" />
          </Button>
        </Row>
      </div>
    );
  }
}

export default connect(mapState, actionCreators)(ProfileInfo);
