import React, { useState, useMemo } from 'react';
import { Row, Typography, Col, Button } from 'antd';
import first from '../../../../assets/icon_1st.svg';
import second from '../../../../assets/icon_2nd.svg';
import third from '../../../../assets/icon_3rd.svg';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { contestConstants } from '../../../../constants/contestConstants';
import edit from '../../../../assets/icon_edit.svg';
import { deleteContest } from '../../../../services/contestService';
import moment, { Moment } from 'moment';
import { menuConstants } from '../../../../constants/menuConstants';
import * as subMenuConstants from '../../../../lib/SubMenuConstants';
import { baseURL } from '../../../../lib/config';
import { languages } from '../../../../lib/interfaces/language';
import {
  mottosLabel,
  jobLabel,
  applyingLabel,
  uploadingLabel,
  qualifying2Label,
  finalRound,
  specialitiesLabel,
  scheduleLabel,
  prizesText,
  firstPlaceText,
  secondPlaceText,
  thirdPlaceText,
  contestantsButton,
  reviewButton,
} from '../../../../lib/strings/contest';
import * as contestStrings from '../../../../lib/strings/contest';
import { Dispatch, Action } from 'redux';
import { contestantApproved } from '../../../../lib/const';
import { DeleteConfirmationModal } from '../../../../components';
import * as appConstants from '../../../../lib/strings/appConstants';

import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../services/sharedServices';
import { serverError } from '../../../../lib/strings/error';
import * as contestService from '../../../../services/contestService';
import Winners from '../../../../components/contest/winners/Winners';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';

const { Text, Title } = Typography;

interface Props {
  contestDetails: any;
  characters?: Array<any>;
  closeDetails: () => Action;
  editContest: (contest: any) => Action;
  deleteContest: (contest: any) => Action;
  getContestants: (list: any) => Action;
  openSubmenu1: (menu: string) => Action;
  selectContestantType: (contestantType: string) => Action;
  language: languages;
}

const mapState = (state: { contestDetails: any; authentication: any }) => {
  const { contestDetails, authentication } = state;
  const { language } = authentication;
  return { contestDetails, language };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    closeDetails: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    editContest: (contest: any) => dispatch({ type: contestConstants.EDIT_CONTEST, contest }),
    deleteContest: (contest: any) => dispatch({ type: contestConstants.DELETE_CONTEST, contest }),
    getContestants: (list: any) => dispatch({ type: contestConstants.SHOW_CONTESTANTS_LIST, list }),
    openSubmenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    selectContestantType: (contestantType: string) =>
      dispatch({ type: contestConstants.CONTESTANT_TYPE, contestantType }),
  };
};

const ContestInfo = (props: Props) => {
  const { contestDetails, language } = props;

  const contestantsReviewList: any = useQuery(
    ['ContestantsReviewList', { id: contestDetails.ID, pageNumber: 1 }],
    contestService.getContestantsQueryReview,
    {
      onError: (error: any) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const handleDelete = async () => {
    props.closeDetails();
    await deleteContest(props.contestDetails.ID);
    props.deleteContest(props.contestDetails);
  };

  const editContest = () => {
    const editObj = props.contestDetails;
    const {
      picturePath,
      guidelineVideos,
      lyrics,
      instrumental,
      instrumentalWithVocals,
      ...rest
    } = editObj;
    const imgUrl = picturePath;
    editObj.imgUrl = imgUrl;
    lyrics ? (editObj.lyricsPDF = `${baseURL}${lyrics}`) : null;
    instrumental ? (editObj.instrumental = `${baseURL}${instrumental}`) : null;
    instrumentalWithVocals
      ? (editObj.instrumentalWithVocals = `${baseURL}${instrumentalWithVocals}`)
      : null;
    guidelineVideos[0] ? (editObj.guideSong1 = `${baseURL}${guidelineVideos[0]}`) : null;
    guidelineVideos[1] ? (editObj.guideSong2 = `${baseURL}${guidelineVideos[1]}`) : null;
    props.editContest(editObj);
    props.openSubmenu1(subMenuConstants.contestForm);
  };

  const showContestants = async (): Promise<void> => {
    props.openSubmenu1(subMenuConstants.contestants);
  };

  const showContestantReview = () => {
    props.openSubmenu1(subMenuConstants.contestantsReview);
  };

  const getDate = (timestamp: Moment) => {
    const months = contestStrings.months[language];

    let dateUnix = new Date(Number(timestamp));
    let date = dateUnix.getDate();
    let month = months[dateUnix.getMonth()];
    return `${date} ${month}`;
  };

  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState(false);
  const toggleDeleteConfirmationModalHandler = () => {
    setDeleteConfirmationModalIsOpen((currentState: boolean) => !currentState);
  };

  const reviewsCount = useMemo(() => {
    let count = 0;
    contestantsReviewList.data?.pendingSignups?.map((pendingContest) => {
      if (pendingContest.freeChoiceVideo && pendingContest.freeChoiceVideoStatus === 'pending') {
        count += 1;
      } else if (
        pendingContest.freeChoiceVideoStatus === 'approved' &&
        pendingContest.songPath &&
        pendingContest.status === 'pending'
      ) {
        count += 1;
      }
    });
    return count;
  }, [contestantsReviewList.data]);

  const currentDate = parseInt(moment().format('x'));
  const getScheduleDatesStyle = (startDate: number, endDate: number) => {
    if (startDate < currentDate && currentDate < endDate) {
      return 'contest-date contest-date-active';
    }
    return 'contest-date';
  };

  return (
    <Row className="contest-details-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="space-between">
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={props.closeDetails} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Text
              className="bold"
              style={{
                fontFamily: 'SF UI',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#70329e',
              }}
            >
              {contestDetails?.name}
            </Text>
          </Col>
          <Col style={{ marginRight: '1em' }}>
            <Button className="button-image" onClick={editContest}>
              <img src={edit} alt="close" />
            </Button>
          </Col>
        </Row>

        <div style={{ padding: '1em 2em' }}>
          {currentDate > contestDetails.finalsEndDate && contestDetails.topTen?.length > 0 && (
            <Winners
              topTen={contestDetails.topTen}
              language={language}
              prizes={[
                contestDetails.firstPlacePrize,
                contestDetails.secondPlacePrize,
                contestDetails.thirdPlacePrize,
              ]}
            />
          )}

          <Row type="flex" justify="space-between" className="section-contest">
            <Col className="general-contest">
              <Row>
                <Title style={{ marginBottom: 0 }} className="fredoka" level={4}>
                  {contestDetails.characterDetails?.name}
                </Title>
              </Row>
              <Row>
                <Typography.Text>{contestDetails.characterDetails?.type}</Typography.Text>
              </Row>
              <Row>
                <Text className="bold">
                  {getDate(contestDetails.startDate)} - {getDate(contestDetails.endDate)}
                </Text>
              </Row>
              <Row>
                <Typography.Text className="bold">{contestDetails?.description}</Typography.Text>
              </Row>
            </Col>
            <Col className="idol-image">
              <img src={`${baseURL}${contestDetails?.picturePath}`} />
            </Col>
          </Row>

          {contestDetails.characterDetails?.ID && (
            <Row>
              <Row>
                <Typography.Text className="contest-character fredoka">
                  {mottosLabel[props.language]}
                </Typography.Text>
                <Typography.Text>{contestDetails.characterDetails?.mottos}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="contest-character fredoka">
                  {jobLabel[props.language]}{' '}
                </Typography.Text>
                <Typography.Text>{contestDetails.characterDetails?.job}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="contest-character fredoka">
                  {specialitiesLabel[props.language]}{' '}
                </Typography.Text>
                <Typography.Text>{contestDetails.characterDetails?.specialities}</Typography.Text>
              </Row>
            </Row>
          )}

          <Row className="section-contest">
            <Row className="contest-heading">{scheduleLabel[props.language]}</Row>
            <Row type="flex">
              <Col>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text
                    className={getScheduleDatesStyle(
                      contestDetails.registrationStartDate,
                      contestDetails.registrationEndDate
                    )}
                  >
                    {moment(contestDetails.registrationStartDate, 'x').format('DD/MM/YY')} -{' '}
                    {moment(contestDetails.registrationEndDate, 'x').format('DD/MM/YY')}
                  </Text>
                </Row>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text
                    className={getScheduleDatesStyle(
                      contestDetails.uploadContestantsVideoEarliestDate,
                      contestDetails.uploadContestantsVideoLatestDate
                    )}
                  >
                    {moment(contestDetails.uploadContestantsVideoEarliestDate, 'x').format(
                      'DD/MM/YY'
                    )}{' '}
                    -{' '}
                    {moment(contestDetails.uploadContestantsVideoLatestDate, 'x').format(
                      'DD/MM/YY'
                    )}
                  </Text>
                </Row>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text
                    className={getScheduleDatesStyle(
                      contestDetails.qualificationsStartDate,
                      contestDetails.qualificationsEndDate
                    )}
                  >
                    {moment(contestDetails.qualificationsStartDate, 'x').format('DD/MM/YY')} -{' '}
                    {moment(contestDetails.qualificationsEndDate, 'x').format('DD/MM/YY')}
                  </Text>
                </Row>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text
                    className={getScheduleDatesStyle(
                      contestDetails.finalsStartDate,
                      contestDetails.finalsEndDate
                    )}
                  >
                    {moment(contestDetails.finalsStartDate, 'x').format('DD/MM/YY')} -{' '}
                    {moment(contestDetails.finalsEndDate, 'x').format('DD/MM/YY')}
                  </Text>
                </Row>
              </Col>
              <Col style={{ marginLeft: '1em' }}>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text className="contest-date-text">{applyingLabel[props.language]}</Text>
                </Row>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text className="contest-date-text">{uploadingLabel[props.language]}</Text>
                </Row>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text className="contest-date-text">{qualifying2Label[props.language]}</Text>
                </Row>
                <Row type="flex" justify="space-between" className="contest-date-time-row">
                  <Text className="contest-date-text">{finalRound[props.language]}</Text>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row className="section-contest" type="flex" justify="space-between">
            <Col>
              <Row className="contest-heading">{prizesText[props.language]}</Row>
              <Row type="flex" justify="start" align="middle">
                <Col className="prize-icon">
                  <img src={first} />
                </Col>
                <Col>
                  <Row>
                    <Text>{firstPlaceText[props.language]}</Text>
                  </Row>
                  <Row>
                    <Text className="bold">{contestDetails.firstPlacePrize}</Text>
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col className="prize-icon">
                  <img src={second} />
                </Col>
                <Col>
                  <Row>
                    <Text>{secondPlaceText[props.language]}</Text>
                  </Row>
                  <Row>
                    <Text className="bold">{contestDetails.secondPlacePrize}</Text>
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="start" align="middle">
                <Col className="prize-icon">
                  <img src={third} />
                </Col>
                <Col>
                  <Row>
                    <Text>{thirdPlaceText[props.language]}</Text>
                  </Row>
                  <Row>
                    <Text className="bold">{contestDetails.thirdPlacePrize}</Text>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="end" className="button-contestants">
            <Button
              style={{ width: '72px', margin: '.5em' }}
              className="buttons transparent-btn-red"
              onClick={toggleDeleteConfirmationModalHandler}
            >
              <span style={{ textDecoration: 'underline' }}>
                {appConstants.buttonDelete[language]}
              </span>
            </Button>
            {!(currentDate > contestDetails.finalsEndDate) && (
              <ButtonWithSpinner
                style={{ margin: '.5em' }}
                label={`${reviewButton[props.language]} (${reviewsCount})`}
                type="yellowGradient"
                isLoading={contestantsReviewList.isLoading}
                onClick={showContestantReview}
                disabled={moment(contestDetails.uploadContestantsVideoEarliestDate).isAfter()}
              />
            )}
            <Button
              style={{ margin: '.5em' }}
              className="buttons purple-gradient-right"
              onClick={() => showContestants()}
            >
              {contestantsButton[props.language]}
            </Button>
          </Row>
        </div>
      </div>
      <DeleteConfirmationModal
        visible={deleteConfirmationModalIsOpen}
        onCancel={toggleDeleteConfirmationModalHandler}
        name={contestDetails?.name}
        onDelete={handleDelete}
        language={language}
      />
    </Row>
  );
};

export default connect(mapState, mapDispatch)(ContestInfo);
