import { LanguageMap } from '../interfaces/language';

export const TreasureCreationTitle: LanguageMap<string> = {
  EN: 'Create a new Treasure',
  KO: '새로운 보물 생성',
};

export const TreasureCreationLabel1: LanguageMap<string> = {
  EN: 'Treasure type:',
  KO: '보물 종류',
};

export const TreasureCreationTypeValue: LanguageMap<string> = {
  EN: 'Sticker',
  KO: '이모티콘',
};

export const changeBackground: LanguageMap<string> = {
  EN: 'Change Background',
  KO: 'Change Background',
};

export const TreasureCreationNameLabel: LanguageMap<string> = {
  EN: 'Treasure Name:',
  KO: '보물 이름',
};

export const TreasureCreationNamePlaceholder: LanguageMap<string> = {
  EN: 'Name...',
  KO: '이름…',
};

export const TreasureCreationNameError: LanguageMap<string> = {
  EN: 'Please input sticker name!',
  KO: '이모티콘 이름을 입력해주세요',
};

export const TreasureCreationHashtagError: LanguageMap<string> = {
  EN: 'Add at least one hashtag!',
  KO: '한 개 이상의 Hashtag를 입력해주세요',
};

export const TreasureCreationHashtagLabel: LanguageMap<string> = {
  EN: 'Hashtag:',
  KO: 'Hashtag',
};

export const TreasureCreationImageLabel: LanguageMap<string> = {
  EN: 'Sticker image:',
  KO: '이모티콘 이미지',
};

export const TreasureCreationDurationLabel: LanguageMap<string> = {
  EN: 'Duration:',
  KO: '지속 시간',
};

export const TreasureCreationDurationError: LanguageMap<string> = {
  EN: 'Please input sticker duration!',
  KO: '스티커 지속 시간을 입력해주세요',
};

export const TreasureCreationDurationOption1: LanguageMap<string> = {
  EN: 'weeks',
  KO: '주',
};

export const TreasureCreationDurationOption2: LanguageMap<string> = {
  EN: 'days',
  KO: '일',
};

export const TreasureCreationDurationOption3: LanguageMap<string> = {
  EN: 'h',
  KO: '시간',
};

export const TreasureCreationAddLocation: LanguageMap<string> = {
  EN: 'Add another location',
  KO: '다른 지역 추가',
};

export const TreasureCreationRuleLabel: LanguageMap<string> = {
  EN: 'Messaging rule:',
  KO: '메시지 전달 방식',
};

export const TreasureCreationRule1: LanguageMap<string> = {
  EN: 'Master to indidvidual users',
  KO: '마스터가 개인 유저에게',
};

export const TreasureCreationRule2: LanguageMap<string> = {
  EN: 'Master to all users in radius',
  KO: '마스터가 범위 내 모든 유저에게',
};

export const TreasureCreationRule3: LanguageMap<string> = {
  EN: 'Master to all clients in radius',
  KO: '마스터가 범위 내 모든 고객에게',
};

export const TreasureCreationRule4: LanguageMap<string> = {
  EN: 'Users to users',
  KO: '유저가 유저에게',
};

export const TreasureCreationRuleError: LanguageMap<string> = {
  EN: 'Select at least one rule!',
  KO: '최소 한가지 전달 방식을 선택해주세요',
};

export const TreasureCreationCreateLabel: LanguageMap<string> = {
  EN: 'Create:',
  KO: '생성하기',
};

export const TreasureCreationCreateButton1: LanguageMap<string> = {
  EN: 'Choose',
  KO: '선택',
};

export const TreasureCreationCreateButton2: LanguageMap<string> = {
  EN: 'Make new',
  KO: '새로 만들기',
};

export const Location: LanguageMap<string> = {
  EN: `Location`,
  KO: '지역',
};

export const Locations: LanguageMap<string> = {
  EN: `Locations`,
  KO: '지역',
};

export const Stickers: LanguageMap<string> = {
  EN: 'stickers',
  KO: '이모티콘',
};

export const TreasureCreationButton1: LanguageMap<string> = {
  EN: 'Cancel',
  KO: '취소',
};

export const TreasureCreationSaveAsDraftButton: LanguageMap<string> = {
  EN: 'Save as draft',
  KO: 'Save as draft',
};

export const TreasureCreationButton2: LanguageMap<string> = {
  EN: 'Drafts',
  KO: '드래프트',
};

export const TreasureCreationButton3: LanguageMap<string> = {
  EN: 'Next',
  KO: '다음',
};

export const TreasureCreationValidationMessage: LanguageMap<string> = {
  EN: 'Image is missing!',
  KO: '이미지가 존재하지 않습니다',
};

export const TreasureCreationValidationDescription: LanguageMap<string> = {
  EN: 'Please choose image that will be used as a treasure.',
  KO: '보물로 사용될 이미지를 선택해주세요',
};

export const TreasureCreationImageUploadMessage: LanguageMap<string> = {
  EN: 'Wrong file type',
  KO: '파일형식이 올바르지 않습니다',
};

export const TreasureCreationImageUploadDescription: LanguageMap<string> = {
  EN: 'Please upload image or video file! Size of image must be smaller than 300MB.',
  KO: '이미지 파일과 동영상 파일만 업로드해주세요. 용량 크기는 50MB를 넘으면 안됩니다',
};

export const TreasureCreationCharacterLabel: LanguageMap<string> = {
  EN: 'Character:',
  KO: '캐릭터',
};

export const TreasureCreationCharacterLabel2: LanguageMap<string> = {
  EN: 'Section & Order:',
  KO: '섹션 & 주문',
};

export const TreasureCreationCharacterLabel3: LanguageMap<string> = {
  EN: 'No.',
  KO: '아니오',
};

export const LocationFormTitle: LanguageMap<string> = {
  EN: 'Location',
  KO: '위치',
};

export const LocationFormName: LanguageMap<string> = {
  EN: 'Location:',
  KO: '위치',
};

export const LocationFormLabelAddress: LanguageMap<string> = {
  EN: 'Address:',
  KO: '주소',
};

export const LocationFormLabelLatitude: LanguageMap<string> = {
  EN: 'Latitude:',
  KO: 'Latitude:',
};

export const LocationFormLabelLongitude: LanguageMap<string> = {
  EN: 'Longitude:',
  KO: 'Longitude:',
};

export const LocationFormLabelNumberOfStickers: LanguageMap<string> = {
  EN: 'Number of stickers:',
  KO: '이모티콘 개수',
};

export const LocationFormLabelNumberOfUnits: LanguageMap<string> = {
  EN: 'Number of units:',
  KO: '유닛 개수',
};

export const LocationFormNumberOfUnitsError: LanguageMap<string> = {
  EN: 'Please add at least one treasure unit.',
  KO: '하나 이상의 보물 유닛을 추가해주세요',
};

export const LocationFormButton: LanguageMap<string> = {
  EN: 'New messaging radius',
  KO: '새로운 메시지 범위',
};

export const LocationFormPlaceHolder1: LanguageMap<string> = {
  EN: 'Address..',
  KO: 'Address....',
};

export const LocationFormPlaceHolder2: LanguageMap<string> = {
  EN: 'Number....',
  KO: '개수….',
};

export const MessagingRadiusFormLabel: LanguageMap<string> = {
  EN: 'Messaging radius',
  KO: '메시지 범위',
};

export const TreasureCreationPointsErrorMessage: LanguageMap<string> = {
  EN: 'Add at least one point!',
  KO: '하나 이상의 포인트를 추가해주세요',
};

export const TreasureCreationPointsLabel1: LanguageMap<string> = {
  EN: 'Kit type:',
  KO: '키트 종류',
};

export const TreasureCreationPointsLabel2: LanguageMap<string> = {
  EN: 'Upload advertisement:',
  KO: '광고 뿌리기',
};

export const TreasureCreationPointsUploadLogo: LanguageMap<string> = {
  EN: 'Upload logo (Optional):',
  KO: 'Upload logo (Optional):',
};

export const TreasureCreationPointsText: LanguageMap<string> = {
  EN: 'points earned',
  KO: '획득한 포인트',
};

export const PointResult: LanguageMap<string> = {
  EN: 'points in',
  KO: '포인트',
};

export const UnitsResult: LanguageMap<string> = {
  EN: 'units',
  KO: '유닛',
};

export const sectionPositionErrorMessage: LanguageMap<string> = {
  EN: 'position already taken, please choose another.',
  KO: '누군가 이미 사용중입니다',
};

export const characterErrorMessage: LanguageMap<string> = {
  EN: 'Pick a character',
  KO: '캐릭터를 선택해주세요',
};

export const sectionErrorMessage: LanguageMap<string> = {
  EN: 'Pick section!',
  KO: '섹션을 선택해주세요',
};
export const sectionPositionErrorMessage2: LanguageMap<string> = {
  EN: 'Insert sticker position in section on an empty position!',
  KO: '비어 있는 자리에 이모티콘을  삽입해주세요',
};

export const lcashPerUnit: LanguageMap<string> = {
  EN: 'Lcash/unit',
  KO: 'Lcash/유닛',
};

export const kitTypeErrorMessage: LanguageMap<string> = {
  EN: 'Kit type must be selected!',
  KO: '카트 형식을 선택해주세요',
};

export const stickersTitle: LanguageMap<string> = {
  EN: 'Stickers',
  KO: '이모티콘',
};

export const chooseStickerButton: LanguageMap<string> = {
  EN: 'Next',
  KO: '다음',
};

export const deleteLocationBtn: LanguageMap<string> = {
  EN: 'Delete Location',
  KO: 'Delete Location',
};

export const labelTitle: LanguageMap<string> = {
  EN: 'Title:',
  KO: 'Title:',
};

export const labelDescription: LanguageMap<string> = {
  EN: 'Description:',
  KO: 'Description:',
};

export const labelContact: LanguageMap<string> = {
  EN: 'Contact Details:',
  KO: 'Contact Details:',
};

export const editButton: LanguageMap<string> = {
  EN: 'Edit Design:',
  KO: 'Edit Design:',
};

export const editingTitle: LanguageMap<string> = {
  EN: 'SETTING AD DESIGN',
  KO: 'SETTING AD DESIGN',
};

export const backgroundImagesLabel: LanguageMap<string> = {
  EN: 'Background Images:',
  KO: 'Background Images:',
};

export const saveButton: LanguageMap<string> = {
  EN: 'Save:',
  KO: 'Save:',
};

export const start: LanguageMap<string> = {
  EN: 'Start:',
  KO: 'Start:',
};

export const end: LanguageMap<string> = {
  EN: 'End:',
  KO: 'End:',
};

export const pointCreationUpload: LanguageMap<string> = {
  EN: 'Upload',
  KO: 'Upload',
};

export const pointCreationReuse: LanguageMap<string> = {
  EN: 'Reuse',
  KO: 'Reuse',
};

export const pointCreationMakeNew: LanguageMap<string> = {
  EN: 'Make new',
  KO: 'Make new',
};

export const pointCreationAdditionalFiles: LanguageMap<string> = {
  EN:
    'Upload additional file (eg. Coupon, Additional information) that you send to users via chat (Optional):',
  KO:
    'Upload additional file (eg. Coupon, Additional information) that you send to users via chat (Optional):',
};

export const pointCreationNoReplayUserViaChat: LanguageMap<string> = {
  EN: 'No reply from users via chat',
  KO: 'No reply from users via chat',
};

export const pointCreationReplayUserViaChat: LanguageMap<string> = {
  EN: 'Reply from users via chat',
  KO: 'Reply from users via chat',
};

export const running: LanguageMap<string> = {
  EN: 'Running',
  KO: 'Running',
};

export const pending: LanguageMap<string> = {
  EN: 'Pending',
  KO: 'Pending',
};

export const draft: LanguageMap<string> = {
  EN: 'Draft',
  KO: 'Draft',
};

export const inReview: LanguageMap<string> = {
  EN: 'In review',
  KO: 'In review',
};

export const finished: LanguageMap<string> = {
  EN: 'Finished',
  KO: 'Finished',
};

export const all: LanguageMap<string> = {
  EN: 'All',
  KO: 'All',
};

export const status: LanguageMap<string> = {
  EN: 'Status:',
  KO: 'Status:',
};

export const country: LanguageMap<string> = {
  EN: 'Country',
  KO: 'Country',
};

export const state: LanguageMap<string> = {
  EN: 'State',
  KO: 'State',
};

export const city: LanguageMap<string> = {
  EN: 'City',
  KO: 'City',
};

export const world: LanguageMap<string> = {
  EN: 'World',
  KO: 'World',
};

export const startDateErrorMassage: LanguageMap<string> = {
  EN: 'Please enter start date',
  KO: 'Please enter start date',
};

export const endDateErrorMassage: LanguageMap<string> = {
  EN: 'Please enter end date',
  KO: 'Please enter end date',
};

export const uploadFileMissing: LanguageMap<string> = {
  EN: 'Please upload advertisement file',
  KO: 'Please upload advertisement file',
};

export const adressMissing: LanguageMap<string> = {
  EN: 'Please enter at least one address',
  KO: 'Please enter at least one address',
};

export const treasureCreationErrorTitle: LanguageMap<string> = {
  EN: 'Please enter all required fields!',
  KO: 'Please enter all required fields!',
};

export const LDiscountValue: LanguageMap<string> = {
  EN: 'AD Discount value:',
  KO: 'AD Discount value:',
};

export const addMessage: LanguageMap<string> = {
  EN: 'Add message',
  KO: 'Add message',
};

export const amount: LanguageMap<string> = {
  EN: 'Amount',
  KO: 'Amount',
};

export const LDiscountNameError: LanguageMap<string> = {
  EN: 'Please input AD Discount name!',
  KO: 'Please input AD Discount name!',
};

export const LDiscountDescriptionError: LanguageMap<string> = {
  EN: 'Please input AD Discount description!',
  KO: 'Please input AD Discount description!',
};

export const academy: LanguageMap<string> = {
  EN: 'Academy',
  KO: 'Academy',
};

export const agency: LanguageMap<string> = {
  EN: 'Agency',
  KO: 'Agency',
};

export const bar: LanguageMap<string> = {
  EN: 'Bar',
  KO: 'Bar',
};

export const basic: LanguageMap<string> = {
  EN: 'Basic',
  KO: 'Basic',
};

export const beauty: LanguageMap<string> = {
  EN: 'Beauty',
  KO: 'Beauty',
};

export const cafe: LanguageMap<string> = {
  EN: 'Cafe',
  KO: 'Cafe',
};

export const campaign: LanguageMap<string> = {
  EN: 'Campaign',
  KO: 'Campaign',
};

export const clinic: LanguageMap<string> = {
  EN: 'Clinic',
  KO: 'Clinic',
};

export const fashion: LanguageMap<string> = {
  EN: 'Fashion',
  KO: 'Fashion',
};

export const food: LanguageMap<string> = {
  EN: 'Food',
  KO: 'Food',
};

export const job: LanguageMap<string> = {
  EN: 'Job',
  KO: 'Job',
};

export const product: LanguageMap<string> = {
  EN: 'New Product',
  KO: 'New Product',
};

export const promotion: LanguageMap<string> = {
  EN: 'Promotion',
  KO: 'Promotion',
};

export const sale: LanguageMap<string> = {
  EN: 'Sale',
  KO: 'Sale',
};

export const store: LanguageMap<string> = {
  EN: 'Store',
  KO: 'Store',
};

export const backgroundColors: LanguageMap<string> = {
  EN: 'Background colors:',
  KO: 'Background colors:',
};

export const pickYourColor: LanguageMap<string> = {
  EN: 'Pick your color',
  KO: 'Pick your color',
};

export const upload: LanguageMap<string> = {
  EN: 'Upload',
  KO: 'Upload',
};

export const adKitLogo: LanguageMap<string> = {
  EN: 'AD KIT logo (Optional)',
  KO: 'AD KIT logo (Optional)',
};

export const title: LanguageMap<string> = {
  EN: 'Title:',
  KO: 'Title:',
};

export const addTitle: LanguageMap<string> = {
  EN: 'Add title',
  KO: 'Add title',
};

export const description: LanguageMap<string> = {
  EN: 'Description:',
  KO: 'Description:',
};

export const contactDetails: LanguageMap<string> = {
  EN: 'Contact details:',
  KO: 'Contact details:',
};

export const addContactDetails: LanguageMap<string> = {
  EN: 'Add contact details',
  KO: 'Add contact details',
};

export const preview: LanguageMap<string> = {
  EN: 'Preview',
  KO: 'Preview',
};

export const save: LanguageMap<string> = {
  EN: 'Save',
  KO: 'Save',
};

export const loadMore: LanguageMap<string> = {
  EN: 'Load more',
  KO: 'Load more',
};

export const LDiscountExpirationDate: LanguageMap<string> = {
  EN: 'Expiration date:',
  KO: 'Expiration date:',
};

export const LDiscountExpirationError: LanguageMap<string> = {
  EN: 'Please input AD Discount expiration date!',
  KO: 'Please input AD Discount expiration date!',
};

export const CouponAddressLabel: LanguageMap<string> = {
  EN: 'Would you like to send a map with an address? (Optional):',
  KO: 'Would you like to send a map with an address? (Optional):',
};

export const partnerUsernameReferral: LanguageMap<string> = {
  EN: 'Partner username referral:',
  KO: 'Partner username referral:',
};

export const yes: LanguageMap<string> = {
  EN: 'Yes',
  KO: 'Yes',
};

export const no: LanguageMap<string> = {
  EN: 'No',
  KO: 'No',
};

export const partnerUsername: LanguageMap<string> = {
  EN: 'Partner username:',
  KO: 'Partner username:',
};

export const correctUsername: LanguageMap<string> = {
  EN: 'Correct username.',
  KO: 'Correct username.',
};

export const incorrectUsername: LanguageMap<string> = {
  EN: 'Incorrect username.',
  KO: 'Incorrect username.',
};

export const partnerUsernameEmpty: LanguageMap<string> = {
  EN: 'Please input partner referral username.',
  KO: 'Please input partner referral username.',
};
