export const treasureConstants = {
  INITIAL: 'INITIAL',
  TREASURE_ID: 'TREASURE_ID',
  TREASURE_TYPE: 'TREASURE_TYPE',
  TREASURE_NAME: 'TREASURE_NAME',
  HASHTAG: 'HASHTAG',
  STICKER_IMAGE: 'STICKER_IMAGE',
  DURATION: 'DURATION',
  DURATION_UNIT: 'DURATION_UNIT',
  MSG_RULE: 'MSG_RULE',
  CLEAR: 'CLEAR',
  TOTAL_NUMBER_OF_UNITS: 'TOTAL_NUMBER_OF_UNITS',
  SAVE_CHARACTERS: 'SAVE_CHARACTERS',
  SELECTED_CHARACTER: 'SELECTED_CHARACTER',
  CHOSEN_STICKER: 'CHOSEN_STICKER',
  TYPE_OF_STICKER: 'TYPE_OF_STICKER',
  TREASURE_POINTS: 'TREASURE_POINTS',
  PAYMENT_INITIATED: 'PAYMENT_INITIATED',
  RANDOM_CANDY: 'RANDOM_CANDY',
  UPDATE_KIT_PROPS: 'UPDATE_KIT_PROPS',
  PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
  UPDATE_SECTION: 'UPDATE_SECTION',
  UPDATE_POSITION: 'UPDATE_POSITION',
  FAILED_POST: 'FAILED_POST',
  SUCCESSFUL_POST: 'SUCCESSFUL_POST',
  USE_AD_MAKER: 'USE_AD_MAKER',
  AD_MAKER_INPUT: 'AD_MAKER_INPUT',
  PICK_COLOR: 'PICK_COLOR',
  CREATE_AD: 'CREATE_AD',
  TREASURE_HANDLE_INPUT: 'TREASURE_HANDLE_INPUT:',
  UPDATE_MEDIA_PATH: 'UPDATE_MEDIA_PATH',
  TREASURE_BATCH_EDIT: 'TREASURE_BATCH_EDIT',
  TREASURE_REMOVE_HASHTAG: 'TREASURE_REMOVE_HASHTAG',
  TREASURE_ERROR_ADD: 'TREASURE_ERROR_ADD',
  TREASURE_ERROR_RESET: 'TREASURE_ERROR_RESET',
};
