import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { Row, Col, Button, Typography, Select } from 'antd';
import { Table, Filter, Paginate } from '../../../../components';
import * as menuActions from '../../../../actions/menuActions';
import * as statisticsStrings from '../../../../lib/strings/statistics';
import PropTypes from 'prop-types';
import { lang } from 'moment';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Statistics(props) {
  const {
    language,
    closeSubmenuFullScreen,
    label,
    loading,
    statisticsType,
    dataSource,
    clickedUsername,
    kitList,
    onFiltersChange,
    onPageChange,
    totalItems,
    pageSize,
    currentPage,
    total,
    role,
    isPartner,
    ...otherProps
  } = props;

  const { width, height } = useWindowDimensions();

  // const [currentPage, setCurrentPage] = useState(0)
  // console.log('currentPage:', currentPage)

  const handleCloseStatistics = () => {
    closeSubmenuFullScreen();
  };

  return (
    <Row
      className="menu-wrapper full-screen-submenu"
      // type="flex"
      // justify="start"
    >
      {/* Back button and title */}
      <Row className="contest-top-row" type="flex" justify="start" align="middle">
        <Button onClick={handleCloseStatistics} className="details-arrow">
          <img src={arrow} alt="close" />
        </Button>
        <Typography.Text className="contest-title bold">{label}</Typography.Text>
      </Row>
      <div style={{ paddingLeft: '2em', paddingRight: '2em' }}>
        {/* Filter and page numbers */}
        <Row type="flex" justify="space-between">
          <Col>
            <Filter
              language={language}
              statisticsType={statisticsType}
              clickedUsername={clickedUsername}
              kitList={kitList}
              onChange={onFiltersChange}
              role={role}
            />
          </Col>
          <Col>
            <Paginate
              onPageChange={(page) => onPageChange(page.selected)}
              totalItems={totalItems}
              pageSize={pageSize}
              forcePage={currentPage}
            />
          </Col>
        </Row>
        <Table
          width={width - 70}
          height={height - 220}
          language={language}
          loading={loading}
          dataSource={dataSource}
          {...otherProps}
        />
        {dataSource.length !== 0 && !loading && (
          <div className="statistics-below-table">
            {statisticsType === 'StatisticsForTreasure' && (
              <BellowTable
                label={statisticsStrings.totalLcashBalance[language]}
                value={parseInt(total.totalBalance, 10)}
              />
            )}
            {statisticsType === 'StatisticsForPoints' && (
              <>
                <BellowTable
                  label={statisticsStrings.totalCollectedPoints[language]}
                  value={parseInt(total.totalCollected, 10)}
                />
                <BellowTable
                  label={statisticsStrings.totalPointsConverted[language]}
                  value={parseInt(total.totalConverted, 10)}
                />
                <BellowTable
                  label={statisticsStrings.totalPointsBalance[language]}
                  value={parseInt(total.totalBalance, 10)}
                />
              </>
            )}
            {statisticsType === 'StatisticsForCoins' && (
              <>
                <BellowTable
                  label={statisticsStrings.totalPurchaseCoins[language]}
                  value={parseInt(total.purchasedCoins, 10)}
                />
                <BellowTable
                  label={statisticsStrings.totalCoinsConvertedFrom[language]}
                  value={parseInt(total.convertedCoins, 10)}
                />
                <BellowTable
                  label={statisticsStrings.totalUsedCoins[language]}
                  value={parseInt(total.usedCoins, 10)}
                />
                <BellowTable
                  label={statisticsStrings.totalCoinsBalance[language]}
                  value={parseInt(total.totalBalance, 10)}
                />
              </>
            )}
            {isPartner && (
              <Typography.Text>{statisticsStrings.thisAmountIsCurrent[language]}</Typography.Text>
            )}
          </div>
        )}
      </div>
    </Row>
  );
}

Statistics.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  statisticsType: PropTypes.string,
  dataSource: PropTypes.array,
  onPageChange: PropTypes.func,
  totalItems: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  total: PropTypes.object,
};

Statistics.defaultProps = {
  language: 'EN',
  onPageChange: () => {},
  pageSize: 10,
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  kitList: state.kitList,
  role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenuFullScreen: () => dispatch(menuActions.closeSubmenuFullScreen()),
  openStats: () => dispatch(menuActions.openSubmenuFullScreen),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);

export function BellowTable(props) {
  const { label, value } = props;
  return (
    <div className="column">
      <div className="row">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
}

BellowTable.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
