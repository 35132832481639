declare var SERVICE_URL: string;

export const isDev = process.env['NODE_ENV'] === 'development';
export const baseURL = SERVICE_URL;
export const signupAPI = '/api/v2/user/signup';
export const verifyAPI = '/api/v2/user/signup/verify';
export const finishAPI = '/api/v2/user/signup/finish';
export const treasureAPI = '/api/armax/treasure';
export const locationAPI = '/api/armax/location';
export const messageLocationAPI = '/api/armax/messageLocation';
export const treasureUnitAPI = '/api/armax/treasureUnit';
export const hashtagAPI = '/api/armax/hashtag';
export const userDetailsAPI = '/api/v2/user/detail';
export const getUserTreasure = '/api/armax/user/treasures';
export const getTreasureListByStatusAPI = '/api/armax/treasure/all';
export const stickerCharactersAPI = '/api/armax/stickerCharacters';
export const contestAPI = '/api/armax/contest';
export const characterAPI = '/shop/stickers';
export const characterStoryAPI = '/api/armax/story';
export const contestVideoAPI = '/api/armax/contest/guidelineVideo';
export const kitAPI = '/kitType';
export const userTransactionHistory = '/api/armax/user/transactionHistory';
export const paymentGetSessionAPI = '/api/armax/payment/session';
export const paymentVerifyAPI = '/api/armax/payment/verify';
export const exchangeAPI = '/api/armax/user/exchange';
export const exchangeRateAPI = '/api/armax/exchangeRate';
export const getPushNotificationsAPI = '/api/armax/notifications/all';
export const notificationAPI = '/api/armax/notifications';
export const userListAPI = '/api/armax/user/all';
export const localization = '/api/armax/localization/byModel';
export const localizationUploadFiles = '/api/armax/file';
export const adKitReuseList = '/api/armax/user/adkit';
export const statistics = '/api/armax/statistics';
export const statisticsCoins = '/api/armax/statistics/coins';
export const statisticsPoints = '/api/armax/statistics/points';
export const statisticsPartners = '/api/armax/statistics/partners';
export const candyEvent = '/api/armax/candyEvent';
export const candyEventSendNotifications = '/api/armax/notifications/candyEvent';
export const cashExchange = '/api/armax/exchangeRate/exchangeRequest';
export const partnerRequest = '/api/armax/partnerRequest';
export const users = '/api/armax/admin/find-users';
export const admin = '/api/armax/admin';

export const hashSalt = '8zgqvU6LaziThJI1uz3PevYd';
export const useSha256 = false;

export const gm = 'AIzaSyAC7FC5wCXyfRRFiA5b3zQ1hF85iz_8EM4';

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const formDataHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const get = 'GET';
export const post = 'POST';
export const put = 'PUT';
export const del = 'DELETE';

export const LOCAL_STORAGE_USER = 'user';
export const LOCAL_STORAGE_USERDATA = 'userData';
export const LOCAL_STORAGE_USER_LOCATION = 'userLocation';

export const LOCAL_STORAGE_LANGUAGE = 'language';
export const LOCAL_STORAGE_COUNTRY = 'country';

export const stripeDev = 'pk_test_eodY9hMOuCwaQb5Y1jT5FZe800NQYaOjk3';

export const stripeLive = 'pk_live_qefVewQtkhqw4JmuBXbFtVy900GR3aXdJd';

console.log('isDev:', isDev);

export const stripe = {
  apikey: isDev ? stripeDev : stripeLive,
};

export const maximumAllowedMediaSize = 300000000;

export const typeJPEG = 'image/jpeg';
export const typePNG = 'image/png';
export const typeGIF = 'image/gif';
export const typeWEBP = 'image/webp';
export const imageExtensionRegex = /(jpeg|png|gif|webp)$/;

export const typeMP4 = 'video/mp4';
export const typeQuickTime = 'video/quicktime';
export const typeAVI = 'video/x-msvideo';
export const typeWMV = 'video/x-ms-wmv';
export const videoExtensionRegex = /(m4v|mp4|mov|avi|wmv)$/;

export const typePDF = 'application/pdf';
export const typeMP3 = 'audio/mpeg';
