import React, { Component } from 'react';
import { Row, Button, Typography, Form, Input, Select, InputNumber, Col } from 'antd';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { kitConstants } from '../../../../constants/kitConstants';
import lcash from '../../../../assets/lcash.svg';
import star from '../../../../assets/star.png';
import { createKit, editKit } from '../../../../services/kitService';
import { FormComponentProps } from 'antd/lib/form';
import flags from '../../../../helpers/countryCallingCodes.json';
import {
  roleSuperadmin,
  kitPriceMultiplier1,
  kitPriceMultiplier2,
  kitLcashMultiplier,
  requestSuccessful,
} from '../../../../lib/const';
import * as kitCreationStrings from '../../../../lib/strings/kitCreation';
import { languages } from '../../../../lib/interfaces/language';

interface Props extends FormComponentProps {
  handleClose: () => void;
  updateKit: (param: string, value: any) => void;
  updateKitPrice: (param: string, value: any, points: any, param2: string) => void;
  updatePoints: (points: number) => void;
  updateLCash: (name: string, lCash: number) => void;
  updateMediaType: (mediaType: string) => void;
  updatePointsByCountry: (points: number) => void;
  openKitPrices: (menu: string) => void;
  country: string;
  points?: number;
  lCashCountry?: number;
  lCashDefault: number;
  kitData: any;
  pointsByCountry?: number;
  pricePerUnit: any;
  name: string;
  adDuration: number;
  isEditing: true;
  type: string;
  updateEdited: (ID: number, kit: any) => void;
  pricesPerCountry: Array<any>; //response
  ID: number;
  [country: string]: any;
  needsFetch: boolean;
  fetchAgain: (needsFetch: boolean) => void;
  role: number;
  language: languages;
}

const mapState = (state: any) => {
  return {
    kitData: state.kit,
    country: state.userData.country,
    lCashDefault: state.kit.lCashDefault,
    lCashCountry: state.kit.lCashCountry,
    points: state.kit.points,
    pointsByCountry: state.kit.pointsByCountry,
    name: state.kit.name,
    adDuration: state.kit.adDuration,
    isEditing: state.kit.isEditing,
    type: state.kit.type,
    pricesPerCountry: state.kit.pricesPerCountry,
    ID: state.kit.ID,
    role: state.userData.role,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    updateKit: (param: string, value: any) =>
      dispatch({ type: kitConstants.UPDATE_KIT, param, value }),
    updateKitPrice: (param: string, value: any, points: any, param2: string) =>
      dispatch({ type: kitConstants.UPDATE_PRICE, param, value, points, param2 }),
    updatePoints: (points: number) => dispatch({ type: kitConstants.UPDATE_POINTS, points }),
    updateLCash: (name: string, lCash: number) =>
      dispatch({ type: kitConstants.UPDATE_LCASH, name, lCash }),
    updateMediaType: (mediaType: string) => dispatch({ type: kitConstants.MEDIA_TYPE, mediaType }),
    openKitPrices: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_2, menu }),
    updateEdited: (ID: number, kit: any) => dispatch({ type: kitConstants.UPDATE_EDITED, ID, kit }),
    fetchAgain: (needsFetch: boolean) => dispatch({ type: kitConstants.FETCH_KITS, needsFetch }),
  };
};

class KitCreation extends Component<Props, any> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      countryFlag: '',
      countryName: '',
    };
  }

  componentDidMount() {
    const country = flags.find(
      (country: { alpha2Code: string }) => country.alpha2Code === this.props.country
    );
    this.setState({
      countryFlag: country ? country.flag : '',
      countryName: country ? country.name : '',
    });

    if (this.props.isEditing) {
      const pricesPerCountry = this.props.pricesPerCountry;
      const country = pricesPerCountry.findIndex(
        (country: { country: string }) => country.country === this.props.country
      );
      const def = pricesPerCountry.findIndex(
        (country: { country: string }) => country.country === 'default'
      );

      if (country !== -1) {
        this.props.updateKitPrice(
          this.props.country,
          pricesPerCountry[country].price,
          pricesPerCountry[country].price * kitPriceMultiplier1 * kitPriceMultiplier2,
          'pointsByCountry'
        );
        this.props.updateLCash(
          'lCashCountry',
          pricesPerCountry[country].price * kitLcashMultiplier
        );
      }

      if (def !== -1) {
        this.props.updateKitPrice(
          'default',
          pricesPerCountry[def].price,
          pricesPerCountry[def].price * kitPriceMultiplier1 * kitPriceMultiplier2,
          'points'
        );
        this.props.updateLCash('lCashDefault', pricesPerCountry[def].price * kitLcashMultiplier);
      }

      this.props.form.setFieldsValue({
        name: this.props.name,
        duration: this.props.adDuration,
        mediaType: this.props.type,
        countryPrice: country !== -1 ? this.props.pricesPerCountry[country].price : null,
        defaultPrice: this.props.pricesPerCountry[def].price,
      });
    }
  }

  updateInput = (e: { target: { name: string; value: any } }) => {
    this.props.updateKit(e.target.name, e.target.value);
  };

  updateCountryPrice = (e: { target: { name: string; value: any } }) => {
    this.props.updateKitPrice(
      e.target.name,
      e.target.value,
      e.target.value * kitPriceMultiplier1 * kitPriceMultiplier2,
      'pointsByCountry'
    );
    this.props.updateLCash('lCashCountry', e.target.value * kitLcashMultiplier);
  };

  updateDefaultPrice = (e: { target: { name: string; value: any } }) => {
    this.props.updateKitPrice(
      e.target.name,
      e.target.value,
      e.target.value * kitPriceMultiplier1 * kitPriceMultiplier2,
      'points'
    );
    this.props.updateLCash('lCashDefault', e.target.value * kitLcashMultiplier);
  };

  handleSelect = (e: any) => {
    this.props.updateMediaType(e);
  };

  handleSubmit = async () => {
    const { form } = this.props;
    const { validateFields } = form;

    validateFields(async (errors, values) => {
      if (!errors) {
        if (!this.props.isEditing) {
          const res = await createKit(this.props.kitData, this.props.country);
          if (res.code !== requestSuccessful) {
            throw 'error while creating kit';
          } else {
            this.props.handleClose();
            this.props.fetchAgain(true);
          }
        } else {
          const res = await editKit(this.props.kitData, this.props.country);
          if (res.code !== requestSuccessful) {
            throw 'error while creating kit';
          } else {
            this.props.handleClose();
            this.props.fetchAgain(true);
          }
        }
      }
    });
  };

  render() {
    const { language } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Row className="kit-list-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="start" align="middle">
            <Button onClick={this.props.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {this.props.isEditing
                ? kitCreationStrings.kitLabel2[language]
                : kitCreationStrings.kitLabel1[language]}
            </Typography.Text>
          </Row>
          <Row type="flex" justify="center" className="kit-creation-form">
            <Form layout="vertical">
              <Form.Item label={kitCreationStrings.labelKitName[language]}>
                {getFieldDecorator('name', {
                  rules: [
                    { required: true, message: kitCreationStrings.kitCreationRule1[language] },
                  ],
                })(
                  <Input
                    disabled={this.props.role < roleSuperadmin}
                    className="input-treasure"
                    name="name"
                    onBlur={this.updateInput}
                  />
                )}
              </Form.Item>
              <Form.Item label={kitCreationStrings.labelType[language]} required>
                {getFieldDecorator('mediaType', {
                  rules: [
                    { required: true, message: kitCreationStrings.kitTypeErrorMessage[language] },
                  ],
                })(
                  <Select
                    disabled={this.props.role < roleSuperadmin}
                    className="select-treasure-type treasure-type-picker"
                    onChange={this.handleSelect}
                  >
                    <Select.Option value="image">
                      {kitCreationStrings.image[language]}
                    </Select.Option>
                    <Select.Option value="video">
                      {kitCreationStrings.video[language]}
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={kitCreationStrings.labelDuration[language]}>
                {getFieldDecorator('duration', {
                  rules: [
                    { required: true, message: kitCreationStrings.kitCreationRule2[language] },
                  ],
                })(
                  <InputNumber
                    disabled={this.props.role < roleSuperadmin}
                    style={{ width: '20%', marginRight: '1em' }}
                    className="input-number"
                    name="adDuration"
                    min={0}
                    onBlur={this.updateInput}
                  />
                )}
                {kitCreationStrings.seconds[language]}
              </Form.Item>
              {this.props.isEditing ? (
                <Row type="flex" justify="space-between">
                  <div>
                    <Typography.Text className="bold">
                      {kitCreationStrings.price[language]}
                    </Typography.Text>
                    <Typography.Text>{kitCreationStrings.lcashPerUnit[language]}</Typography.Text>
                  </div>
                  <div
                    className="all-prices"
                    onClick={() => this.props.openKitPrices('KitPricesByCountry')}
                  >
                    {kitCreationStrings.allPrices[language]}
                  </div>
                </Row>
              ) : null}
              <Row type="flex" justify="center" style={{ marginTop: '1em' }}>
                <Col>
                  <Typography.Text className="contest-heading">
                    <img className="flags" src={this.state.countryFlag} /> {this.state.countryName}
                  </Typography.Text>
                </Col>
              </Row>
              <Row type="flex" justify="center" className="kit-country">
                <Col style={{ width: '100%' }}>
                  <Row type="flex" justify="center" align="middle">
                    <Form.Item>
                      {getFieldDecorator('countryPrice', {
                        rules: [
                          {
                            required: true,
                            message: kitCreationStrings.kitCreationRule3[language],
                          },
                        ],
                      })(
                        <InputNumber
                          className="input-treasure"
                          min={0}
                          name={this.props.country}
                          onBlur={this.updateCountryPrice}
                        />
                      )}
                      <Typography.Text>
                        <img src={lcash} />
                        {kitCreationStrings.perUnit[language]}
                      </Typography.Text>
                    </Form.Item>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <Row type="flex" justify="center">
                        <Typography.Text className="contest-heading small-text">
                          {kitCreationStrings.userGets[language]}
                        </Typography.Text>
                      </Row>
                      <Row type="flex" justify="center">
                        <Typography.Text>
                          {this.props.pointsByCountry
                            ? Number(this.props.pointsByCountry.toFixed())
                            : null}{' '}
                          <img src={star} />
                        </Typography.Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row type="flex" justify="center">
                        <Typography.Text className="contest-heading small-text">
                          {kitCreationStrings.loaMoaGets[language]}
                        </Typography.Text>
                      </Row>
                      <Row type="flex" justify="center">
                        <Typography.Text>
                          {this.props.pointsByCountry
                            ? Number(this.props.lCashCountry.toFixed(2))
                            : null}{' '}
                          <img src={lcash} />
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="center" style={{ marginTop: '1em' }}>
                <Col>
                  <Typography.Text className="contest-heading">
                    {kitCreationStrings.defaultValue[language]}
                  </Typography.Text>
                </Col>
              </Row>

              <Row type="flex" justify="center" className="kit-default">
                <Col style={{ width: '100%' }}>
                  <Row type="flex" justify="center">
                    <Form.Item>
                      {getFieldDecorator('defaultPrice', {
                        rules: [
                          {
                            required: true,
                            message: kitCreationStrings.kitCreationRule4[language],
                          },
                        ],
                      })(
                        <InputNumber
                          className="input-treasure"
                          min={0}
                          name="default"
                          disabled={this.props.role < roleSuperadmin}
                          onBlur={this.updateDefaultPrice}
                        />
                      )}
                      <Typography.Text>
                        <img src={lcash} />
                        {kitCreationStrings.perUnit[language]}
                      </Typography.Text>
                    </Form.Item>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <Row>
                        <Typography.Text className="contest-heading small-text">
                          {kitCreationStrings.userGets[language]}
                        </Typography.Text>
                      </Row>
                      <Row type="flex" justify="center">
                        <Typography.Text>
                          {this.props.points ? Number(this.props.points.toFixed()) : null}
                          <img src={star} />
                        </Typography.Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row type="flex" justify="center">
                        <Typography.Text className="contest-heading small-text">
                          {kitCreationStrings.loaMoaGets[language]}
                        </Typography.Text>
                      </Row>
                      <Row type="flex" justify="center">
                        <Typography.Text>
                          {this.props.lCashDefault
                            ? Number(this.props.lCashDefault.toFixed(2))
                            : null}{' '}
                          <img src={lcash} />
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="center" className="buttons-kit">
                <Button
                  //style={{ width: '40%', border: 'none', color: 'black' }}
                  onClick={this.props.handleClose}
                  className="transparent-btn-black"
                >
                  {kitCreationStrings.button1[language]}
                </Button>
                <Button
                  //style={{ width: '40%' }}
                  className="buttons purple-gradient-right"
                  onClick={this.handleSubmit}
                >
                  {this.props.isEditing
                    ? kitCreationStrings.button2[language]
                    : kitCreationStrings.button3[language]}
                </Button>
              </Row>
            </Form>
          </Row>
        </div>
      </Row>
    );
  }
}

const wrappedKitCreationForm = Form.create({ name: 'kit' })(KitCreation);
export default connect(mapState, mapDispatch)(wrappedKitCreationForm);
