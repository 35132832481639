import { LanguageMap } from '../interfaces/language';

export const PrizeDataButton1: LanguageMap<string> = {
  EN: 'Create a new treasure',
  KO: '보물 생성하기',
};

export const PrizeDataButton2: LanguageMap<string> = {
  EN: 'Drafts',
  KO: '기초',
};

export const PrizeDataButton3: LanguageMap<string> = {
  EN: 'Treasures',
  KO: '보물',
};

export const PrizeDataButton4: LanguageMap<string> = {
  EN: 'Stats',
  KO: '통계',
};

export const PrizeDataButton5: LanguageMap<string> = {
  EN: 'Candy C.',
  KO: 'Candy C.',
};

export const PrizeDataButton6: LanguageMap<string> = {
  EN: 'Idol',
  KO: '아이돌',
};

export const PrizeDataButton7: LanguageMap<string> = {
  EN: 'Kit',
  KO: '키트',
};

export const PrizeDataButton8: LanguageMap<string> = {
  EN: 'Requests',
  KO: 'Requests',
};

export const PrizeDataButton9: LanguageMap<string> = {
  EN: 'Balance',
  KO: '잔고',
};

export const PrizeDataButton10: LanguageMap<string> = {
  EN: 'Notifs',
  KO: 'Notifs',
};

export const PrizeDataButton11: LanguageMap<string> = {
  EN: 'Characters',
  KO: 'Characters',
};

export const NoPrizes: LanguageMap<string> = {
  EN: 'There is no treasures yet!',
  KO: '아직 보물이 없습니다',
};

export const buttonReviewed: LanguageMap<string> = {
  EN: 'Reviewed',
  KO: '확인 완료',
};

export const buttonPending: LanguageMap<string> = {
  EN: 'Pending',
  KO: '진행중',
};

export const users: LanguageMap<string> = {
  EN: 'Users',
  KO: 'Users',
};
