import React from 'react';
import { Row, Col, Typography } from 'antd';
import lcash from '../../../../assets/lcash.svg';
import flags from '../../../../helpers/countryCallingCodes.json';
import { perUnit } from '../../.././../lib/strings/kitCreation';
import { connect } from 'react-redux';
import { languages } from '../../../../lib/interfaces/language';

interface Props {
  countryName: string;
  points: number;
  price: number;
  language: languages;
}

const mapState = (state: any) => {
  return {
    language: state.authentication.language,
  };
};

const KitPriceCountry = (props: Props) => {
  const countryFlag = flags.find(
    (flag: { alpha2Code: string }) => flag.alpha2Code === props.countryName
  );

  return (
    <Row type="flex" justify="space-between">
      <Col>
        <img className="flags" src={countryFlag ? countryFlag.flag : ''} />
        <Typography.Text>{countryFlag ? countryFlag.name : ''}</Typography.Text>
      </Col>
      <Col>
        {props.price} <img src={lcash} />
        {perUnit[props.language]}
      </Col>
    </Row>
  );
};

export default connect(mapState)(KitPriceCountry);
