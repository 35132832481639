import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Typography, Form, Button } from 'antd';
import * as candyEventStrings from '../../../../lib/strings/candyEvent';
import CreateGlobalInput from '../../../../components/candyEvent/CreateGlobalInput';
import CreatePrizeForm from '../../../../components/candyEvent/CreatePrizeForm';
import * as menuActions from '../../../../actions/menuActions';
import { baseURL, candyEvent as candyEventUrl, isDev } from '../../../../lib/config';
import * as candyEventService from '../../../../services/candyEventService';
import * as sharedServices from '../../../../services/sharedServices';
import { responseCodes } from '../../../../lib/const';
import ModalLoadingProgressText from '../../../../components/ModalLoadingProgressText';
import { useQueryClient, useMutation } from 'react-query';
import { serverError } from '../../../../lib/strings/error';
import PropTypes from 'prop-types';
import { fillCandyChallenge } from '../../../../qucikFill/candyChallengeFill';

function CreateCandyEvent(props) {
  const queryClient = useQueryClient();
  const { language, closeCreateCandyEvent, selectedCountryCode, candyEvent, form } = props;
  const { validateFields } = form;

  const [globalInfo, setGlobalInfo] = useState({
    ID: null,
    country: null,
    createdTime: null,
    adminID: null,
    startDate: null,
    endDate: null,
    title: '',
    month: null,
    eventPictureUrl: null,
    eventPictureFile: '',
  });
  const [firstPrize, setFirstPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });
  const [secondPrize, setSecondPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });
  const [thirdPrize, setThirdPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });

  useEffect(() => {
    if (candyEvent.isEditing && candyEvent.ID) {
      const getCandyEventData = () => {
        const candyEventList: any = queryClient.getQueryData([
          'CandyEventList',
          { country: candyEvent.selectedCountryCode },
        ]);
        const candyEventData = candyEventList.find((cEvent) => cEvent.ID === candyEvent.ID);
        return candyEventData;
      };
      const candyEventData = getCandyEventData();

      form.setFieldsValue({
        title: candyEventData.title,
        month: candyEventData.month,
        eventPictureUrl: `${baseURL}${candyEventData.eventPictureUrl}`,

        'firstPrize-goods': candyEventData.firstPrize?.goods,
        'firstPrize-name': candyEventData.firstPrize?.name,
        'firstPrize-picture': `${baseURL}${candyEventData.firstPrize?.pictureUrl}`,

        'secondPrize-goods': candyEventData.secondPrize?.goods,
        'secondPrize-name': candyEventData.secondPrize?.name,
        'secondPrize-picture': `${baseURL}${candyEventData.secondPrize?.pictureUrl}`,

        'thirdPrize-goods': candyEventData.thirdPrize?.goods,
        'thirdPrize-name': candyEventData.thirdPrize?.name,
        'thirdPrize-picture': `${baseURL}${candyEventData.thirdPrize?.pictureUrl}`,
      });

      setGlobalInfo({
        ...globalInfo,
        eventPictureUrl: `${baseURL}${candyEventData.eventPictureUrl}`,
      });

      setFirstPrize({
        ...firstPrize,
        pictureUrl: `${baseURL}${candyEventData.firstPrize?.pictureUrl}`,
      });
      setSecondPrize({
        ...secondPrize,
        pictureUrl: `${baseURL}${candyEventData.secondPrize?.pictureUrl}`,
      });
      setThirdPrize({
        ...thirdPrize,
        pictureUrl: `${baseURL}${candyEventData.thirdPrize?.pictureUrl}`,
      });
    }
  }, []);

  const onGlobalInfoChangeHandler = (parameterKey: string, parameterValue: any) => {
    setGlobalInfo((currentGlobalInfo) => ({
      ...currentGlobalInfo,
      [parameterKey]: parameterValue,
    }));
  };

  const firstPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setFirstPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const secondPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setSecondPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const thirdPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setThirdPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const [percentage, setPercentage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const onPublishHandler = async () => {
    let validationErrors;
    validateFields((errors, values) => {
      validationErrors = errors;
    });

    if (!validationErrors) {
      setPercentage(0);
      setShowModal(true);

      // create from data
      const formData = new FormData();
      formData.append('title', globalInfo.title);
      formData.append('month', sharedServices.toFormData(globalInfo.month));
      formData.append('eventPicture', globalInfo.eventPictureFile);
      formData.append('startDate', sharedServices.toFormData(globalInfo.startDate));
      formData.append('endDate', sharedServices.toFormData(globalInfo.endDate));

      formData.append('firstPrizeGoods', firstPrize.goods);
      formData.append('firstPrizeName', firstPrize.name);
      formData.append('firstPrizePicture', firstPrize.pictureFile);

      formData.append('secondPrizeGoods', secondPrize.goods);
      formData.append('secondPrizeName', secondPrize.name);
      formData.append('secondPrizePicture', secondPrize.pictureFile);

      formData.append('thirdPrizeGoods', thirdPrize.goods);
      formData.append('thirdPrizeName', thirdPrize.name);
      formData.append('thirdPrizePicture', thirdPrize.pictureFile);

      // check if is editing or creating
      let candyEventID = candyEvent.ID;
      if (!candyEvent.isEditing) {
        const create = await candyEventService.createCandyEvent();
        candyEventID = create.ID;
      }

      const url = baseURL + candyEventUrl + '/' + candyEventID;

      const res = await sharedServices.uploadFiles(
        url,
        formData,
        {
          onProgress: (perc: number) => {
            setPercentage(perc);
          },
        },
        'PUT'
      );
      if (res.code === responseCodes.successful) {
        closeCreateCandyEvent();
        return res.data;
      } else {
        throw `Response code: ${res.code}`;
      }
    }
    setShowModal(false);
  };

  const createCandyEventMutation = useMutation(onPublishHandler, {
    onSuccess: () =>
      queryClient.invalidateQueries(['CandyEventList', { country: selectedCountryCode }]),
    onError: (error) => {
      candyEventService.showErrorMessage(serverError[language], error);
    },
  });

  // console.log('globalInfo', globalInfo);
  // console.log('firstPrize', firstPrize);
  // console.log('secondPrize', secondPrize);
  // console.log('thirdPrize', thirdPrize);

  return (
    <Row type="flex" justify="center" className="create-treasure">
      <Col className="candyEvent-create-container">
        <Row type="flex" justify="center" style={{ flex: 1 }}>
          <Col>
            <Typography.Title className="candyEvent-create-text candyEvent-create-title" level={4}>
              {candyEventStrings.candyEvent[language]}
            </Typography.Title>
          </Col>
        </Row>
        <Form>
          <CreateGlobalInput
            form={props.form}
            language={language}
            onChange={onGlobalInfoChangeHandler}
            title={globalInfo.title}
            month={globalInfo.month}
            eventPictureUrl={globalInfo.eventPictureUrl}
          />
          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="firstPrize"
            label={candyEventStrings.firstPrize[language]}
            placeholder={candyEventStrings.firstPrizePlaceholder[language]}
            onChange={firstPrizeChangeHandler}
            goods={firstPrize.goods}
            name={firstPrize.name}
            pictureUrl={firstPrize.pictureUrl}
          />
          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="secondPrize"
            label={candyEventStrings.secondPrize[language]}
            placeholder={candyEventStrings.secondPrizePlaceholder[language]}
            onChange={secondPrizeChangeHandler}
            goods={secondPrize.goods}
            name={secondPrize.name}
            pictureUrl={secondPrize.pictureUrl}
          />
          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="thirdPrize"
            label={candyEventStrings.thirdPrize[language]}
            placeholder={candyEventStrings.thirdPrizePlaceholder[language]}
            onChange={thirdPrizeChangeHandler}
            goods={thirdPrize.goods}
            name={thirdPrize.name}
            pictureUrl={thirdPrize.pictureUrl}
          />
        </Form>
      </Col>

      {/* Buttons at the bottom Cancel and Publish */}
      <Row className="review-buttons">
        <Col>
          <Row type="flex" justify="space-around" className="buttons-row">
            <Col>
              <Button
                className="buttons transparent-btn-white treasure-button-width"
                onClick={closeCreateCandyEvent}
              >
                <span style={{ textDecoration: 'underline' }}>
                  {candyEventStrings.cancel[language]}
                </span>
              </Button>
            </Col>
            <Col>
              <Button
                className="buttons yellow-gradient treasure-button-width"
                onClick={() => {
                  createCandyEventMutation.mutate();
                }}
              >
                {candyEvent.isEditing
                  ? candyEventStrings.save[language]
                  : candyEventStrings.publish[language]}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalLoadingProgressText
        loading={showModal}
        done={!showModal}
        percentage={percentage}
        text={'Files are uploading'}
      />

      {/* quickFill for testing */}
      {isDev && !candyEvent.isEditing && (
        <div className="quick-fill-container">
          <Button
            onClick={() => {
              fillCandyChallenge(
                form.setFieldsValue,
                setGlobalInfo,
                setFirstPrize,
                setSecondPrize,
                setThirdPrize
              );
            }}
          >
            AUTOFILL
          </Button>
        </div>
      )}
    </Row>
  );
}

CreateCandyEvent.propTypes = {
  language: PropTypes.string,
  selectedCountryCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  selectedCountryCode: state.candyEvent.selectedCountryCode,
  candyEvent: state.candyEvent,
});

const mapDispatchToProps = (dispatch) => ({
  closeCreateCandyEvent: () => dispatch(menuActions.closeMenu()),
});

const wrappedCreateCandyEvent = Form.create({ name: 'candyEvent' })(CreateCandyEvent);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedCreateCandyEvent);
