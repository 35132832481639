import { baseURL, contestAPI, contestVideoAPI } from '../lib/config';
import { store } from '../helpers/store';
import { notification } from 'antd';
import { fileUploadErr } from '../lib/strings/error';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

export const initialCreateContest = async () => {
  console.log('initialCreateContest');
  try {
    const fetchResponse = await fetch(baseURL + contestAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.contestID;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject('failed to create contest');
    }
  } catch (error) {
    return error;
  }
};

export const createContest = async (contestData: any) => {
  console.log('createContest, contestData:', contestData);
  try {
    const fetchResponse = await fetch(`${baseURL}${contestAPI}/${contestData.ID}`, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: contestData.name,
        description: contestData.description,
        characterID: contestData.characterID,
        firstPlacePrize: contestData.firstPlacePrize,
        secondPlacePrize: contestData.secondPlacePrize,
        thirdPlacePrize: contestData.thirdPlacePrize,
        startDate: contestData.startDate,
        endDate: contestData.endDate,
        guideSongEarliestPublishDate: contestData.guideSongEarliestPublishDate,
        guideSongLatestPublishDate: contestData.guideSongLatestPublishDate,
        registrationStartDate: contestData.registrationStartDate,
        registrationEndDate: contestData.registrationEndDate,
        uploadContestantsVideoEarliestDate: contestData.uploadContestantsVideoEarliestDate,
        uploadContestantsVideoLatestDate: contestData.uploadContestantsVideoLatestDate,
        qualificationsStartDate: contestData.qualificationsStartDate,
        qualificationsEndDate: contestData.qualificationsEndDate,
        finalistsAnnouncementDate: contestData.finalistsAnnouncementDate,
        finalsStartDate: contestData.finalsStartDate,
        finalsEndDate: contestData.finalsEndDate,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject('failed to create contest');
    }
  } catch (error) {
    return error;
  }
};

export const deleteContest = async (ID: number) => {
  console.log('deleteContest, ID:', ID);
  try {
    const fetchResponse = await fetch(`${baseURL}${contestAPI}/${ID}`, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.contests;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('Failed to delete contest');
    }
  } catch (error) {
    return error;
  }
};

export const getContest = async () => {
  console.log('getContest');
  try {
    const fetchResponse = await fetch(baseURL + contestAPI, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.contests.filter((cont: any) => cont.name);
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to fetch contests');
    }
  } catch (error) {
    return error;
  }
};

export const updateContest = async (contest: any) => {
  console.log('updateContest, contest:', contest);
  try {
    const {
      ID,
      name,
      title,
      startDate,
      endDate,
      characterID,
      description,
      guideSongEarliestPublishDate,
      registrationStartDate,
      uploadContestantsVideoEarliestDate,
      guideSongLatestPublishDate,
      registrationEndDate,
      uploadContestantsVideoLatestDate,
      qualificationsStartDate,
      qualificationsEndDate,
      finalistsAnnouncementDate,
      finalsStartDate,
      finalsEndDate,
      firstPlacePrize,
      secondPlacePrize,
      thirdPlacePrize,
    } = contest;

    const fetchResponse = await fetch(`${baseURL}${contestAPI}/${ID}`, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ID,
        name,
        title,
        startDate,
        endDate,
        characterID,
        description,
        guideSongEarliestPublishDate,
        registrationStartDate,
        uploadContestantsVideoEarliestDate,
        guideSongLatestPublishDate,
        registrationEndDate,
        uploadContestantsVideoLatestDate,
        qualificationsStartDate,
        qualificationsEndDate,
        finalistsAnnouncementDate,
        finalsStartDate,
        finalsEndDate,
        firstPlacePrize,
        secondPlacePrize,
        thirdPlacePrize,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
};

export const mediaUpload = async (
  id: number,
  file: any,
  listener: any,
  callback?: (res: any) => any
) => {
  console.log(`mediaUpload, id: ${id}, file..., listener, callback`);
  const contestMedia = new FormData();
  contestMedia.append('file', file);
  if (file?.isInstrumental) contestMedia.append('isInstrumental', 'true');
  if (file?.isInstrumentalWithVocals) contestMedia.append('isInstrumentalWithVocals', 'true');
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'json';
  xhr.upload.onprogress = (e: ProgressEvent) => {
    const perc = Math.floor((e.loaded / e.total) * 1000) / 10;
    if (listener && listener.onProgress) listener.onProgress(perc);
  };
  xhr.onreadystatechange = function () {
    if (this.status === 200 && this.readyState === 4 && callback) {
      callback(this.response);
    }
  };
  xhr.open('PUT', `${baseURL}${contestAPI}/${id}/uploadFile`, true);
  xhr.setRequestHeader('access-token', store.getState().authentication.user.newToken);
  xhr.send(contestMedia);
};

export const getContestants = async (id: number, pageNumber: number) => {
  console.log(`getContestants id: ${id}, pageNumber:`, pageNumber);
  try {
    const fetchResponse = await fetch(
      `${baseURL}${contestAPI}/${id}/signup` +
        '?' +
        new URLSearchParams({
          page: `${pageNumber}`,
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('Failed to get contestants');
    }
  } catch (error) {
    return error;
  }
};

export const approveFreeChoiceVideo = async (signupID) => {
  const response = await updateContestantSignUp(signupID, { freeChoiceVideoStatus: 'approved' });
  return response;
};

export const declineFreeChoiceVideo = async (signupID) => {
  const response = await updateContestantSignUp(signupID, {
    status: 'declined',
    freeChoiceVideoStatus: 'declined',
  });
  return response;
};

export const approveGuideVideo = async (signupID) => {
  const response = await updateContestantSignUp(signupID, { status: 'approved' });
  return response;
};

export const declineGuideVideo = async (signupID) => {
  const response = await updateContestantSignUp(signupID, { status: 'declined' });
  return response;
};

export const updateContestantSignUp = async (signupID: number, bodyData: object) => {
  const fetchResponse = await fetch(`${baseURL}${contestAPI}/signup/${signupID}`, {
    method: 'PUT',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.error} ${response.code}`;
  }
};

export const deleteFile = async (videoId: number) => {
  try {
    const fetchMediaResponse = await fetch(`${baseURL}${contestVideoAPI}/${videoId}`, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    });

    const mediaResponse = await fetchMediaResponse.json();
    if (mediaResponse.code === responseCodes.successful) {
      return mediaResponse;
    } else if (mediaResponse.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      notification.error({
        message: fileUploadErr,
      });
      return Promise.reject('Failed to upload');
    }
  } catch (error) {
    throw error;
  }
};

export const getContestantsQueryReview = async ({ queryKey }) => {
  console.log(`getContestantsQueryReview, queryKey[1]:`, queryKey[1]);
  const { id, pageNumber } = queryKey[1];

  const fetchResponse = await fetch(
    `${baseURL}${contestAPI}/${id}/signup` +
      '?' +
      new URLSearchParams({
        page: `${pageNumber}`,
      }),
    {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    }
  );
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.error} ${response.code}`;
  }
};

export const getContestantsQuery = async ({ queryKey }) => {
  console.log(`getContestantsQuery, queryKey[1]:`, queryKey[1]);
  const { id, pageNumber, round } = queryKey[1];

  const fetchResponse = await fetch(
    `${baseURL}${contestAPI}/${id}/contestants` +
      '?' +
      new URLSearchParams({
        page: `${pageNumber}`,
        round: `${round}`,
      }),
    {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    }
  );
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.error} ${response.code}`;
  }
};
