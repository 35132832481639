import React, { Component, ChangeEvent, createRef } from 'react';
import { Input, Button, Form, Select, Row, Col, Typography, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { connect } from 'react-redux';
import * as userActions from '../../actions/signinActions';
import countryData from '../../helpers/country.json';
import MasterInfoScreen from './MasterInfoScreen';
import * as signin from '../../lib/strings/signin';
import { languages } from '../../lib/interfaces/language';

const { Option } = Select;
const { Text } = Typography;

const mapStateToProps = (state: {
  authentication: {
    loggingIn: boolean;
    user: any;
    showMasterInfoButton: boolean;
    language: languages;
  };
}) => {
  const { authentication } = state;
  const { user, showMasterInfoButton, language } = authentication;
  return { user, showMasterInfoButton, language };
};

const actionCreators = {
  onClickSendPhoneNumber: userActions.sendPhoneNumber,
  saveUserCountryCode: userActions.saveUserCountryCode,
};

interface SignInState {
  countries: any;
  phoneNumber: string;
  rememberMe?: boolean;
  showMasterAccountInfoModal: boolean;
  displayedCallingCode: string;
  countryCode?: string;
}

interface SignInProps extends FormComponentProps {
  onClickSendPhoneNumber?: (phoneNumber: string) => void;
  user?: any;
  showMasterInfoButton?: boolean;
  saveUserCountryCode: (code: string) => void;
  language?: languages;
}

class PhoneNumberForm extends Component<SignInProps, SignInState> {
  inputRef: React.RefObject<Input>;

  constructor(props: Readonly<SignInProps>) {
    super(props);

    this.state = {
      countries: null,
      phoneNumber: '',
      rememberMe: true,
      showMasterAccountInfoModal: false,
      displayedCallingCode: '+385',
      countryCode: 'HR',
    };

    this.inputRef = createRef();
  }

  getCountry = async () => {
    try {
      const ip = await fetch(`https://ipapi.co/json/`);
      const res = await ip.json();
      if (res) {
        return res;
      } else {
        throw 'Failed to fetch country data';
      }
    } catch (err) {
      throw 'failed to fetch country data';
    }
  };

  componentDidMount = async () => {
    this.inputRef.current.focus();
    const detectedCountryCode = await this.getCountry();
    if (detectedCountryCode) {
      this.setState({
        countryCode: detectedCountryCode.country_code,
      });
    }
    // this.props.saveUserCountryCode(detectedCountryCode.country_name)

    const setOfCodes = new Set();
    for (let code in countryData) {
      if (countryData[code].callingCodes[0].length > 0) {
        setOfCodes.add(countryData[code].callingCodes[0].replace(/ /g, ''));
      }
    }
    const sortedCallingCodes = [...setOfCodes];

    sortedCallingCodes.sort(function (a: any, b: any) {
      return a - b;
    });
    this.setState({
      countries: sortedCallingCodes,
    });

    const findCallingCode = countryData.find((obj: { alpha2Code: string }) => {
      return obj.alpha2Code === this.state.countryCode;
    });
    this.setState({
      displayedCallingCode: findCallingCode.callingCodes[0],
    });
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    } as Pick<SignInState, any>);
  };

  handleRememberMe = () => {
    const changeRememberMe = !this.state.rememberMe;
    this.setState({
      rememberMe: changeRememberMe,
    });
  };

  handleSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const select: any = document.getElementsByClassName('ant-select-selection-selected-value');
        let inputNumber = this.state.phoneNumber;
        if (inputNumber.charAt(0) === '0') {
          inputNumber = inputNumber.slice(1);
        }
        const phoneNumber = `${select[0].innerText + inputNumber}`;
        this.props.onClickSendPhoneNumber(phoneNumber);
      }
    });
  };

  showMasterInfoScreen = () => {
    this.setState({
      showMasterAccountInfoModal: true,
    });
  };

  hideMasterInfoScreen = (e: React.MouseEvent) => {
    this.setState({
      showMasterAccountInfoModal: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: this.state.displayedCallingCode,
    })(
      <Select style={{ width: 75 }} id="select">
        {this.state.countries
          ? this.state.countries.map((code: number, index: any) => {
              return (
                <Option key={index} value={code}>
                  +{code}
                </Option>
              );
            })
          : null}
      </Select>
    );

    const { language } = this.props;

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit} hideRequiredMark={true}>
        <Row type="flex" justify="center">
          <Col className="signin-col-instructions" style={{ width: '50%' }}>
            <Text className="signin-text-instructions">{signin.Instructions1[language]}</Text>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Form.Item className="phone-form">
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: signin.PhoneNumberError[language] }],
            })(
              <Input
                ref={this.inputRef}
                addonBefore={prefixSelector}
                name="phoneNumber"
                onChange={this.handleChange}
                maxLength={14}
                className="phone-number-form"
              />
            )}
          </Form.Item>
        </Row>

        <Row type="flex" justify="center">
          {/* This is my number */}
          <Button htmlType="submit" className="signin-form-button buttons purple-gradient-right">
            {signin.Button1[language]}
          </Button>
          {this.props.showMasterInfoButton ? (
            <Button
              onClick={this.showMasterInfoScreen}
              className="signin-form-button buttons gray-gradient"
            >
              {signin.Button2[language]}
            </Button>
          ) : null}
          <Modal
            visible={this.state.showMasterAccountInfoModal}
            footer={null}
            closable={false}
            centered={true}
          >
            <MasterInfoScreen onCancel={this.hideMasterInfoScreen} />
          </Modal>
        </Row>
      </Form>
    );
  }
}

const wrappedSignInForm = Form.create<SignInProps>({ name: 'login' })(PhoneNumberForm);
export default connect(mapStateToProps, actionCreators)(wrappedSignInForm);
