import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQueryClient, useMutation } from 'react-query';
import moment from 'moment';

import { Row } from 'antd';
import { DetailsGrid, BackButtonLeftText } from '../../../../../components';
import ButtonWithSpinner from '../../../../../components/ButtonWithSpinner';

import * as partnerRequestsActions from '../../../../../actions/partnerRequestsActions';
import * as menuActions from '../../../../../actions/menuActions';
import * as partnerRequestsStrings from '../../../../../lib/strings/partnerRequestsStrings';
import * as partnerRequestsServices from '../../../../../services/partnerRequestsServices';
import { showErrorMessage } from '../../../../../services/sharedServices';
import { serverError } from '../../../../../lib/strings/error';
import PropTypes from 'prop-types';

function PartnerRequestsDetails(props) {
  const { language, closeSubmenu2, partnerRequests, partnerRequestsEditParameters } = props;

  useEffect(() => {
    return () => {
      partnerRequestsEditParameters({ selectedUserIndex: null });
    };
  }, []);

  const queryClient = useQueryClient();
  const partnerRequestsList: any = queryClient.getQueryData(['PartnerRequestsList', {}]);

  const invalidatePartnerRequestListAndClose = () => {
    queryClient.invalidateQueries(['PartnerRequestsList', {}]);
    closeSubmenu2();
  };

  const {
    ID,
    username,
    beneficiaryName,
    bankName,
    accountNumber,
    ABANumber,
    registrationTimestamp,
    companyName,
    treasuresCreated,
    requestTimestamp,

    requestStatus,
  } = partnerRequestsList[partnerRequests.selectedUserIndex];

  console.log('requestStatus:', requestStatus);

  const onApproveHandler = useMutation(
    () => partnerRequestsServices.updatePartnerStatus(ID, 'approved'),
    {
      onSuccess: invalidatePartnerRequestListAndClose,
      onError: (error) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const onRejectHandler = useMutation(
    () => partnerRequestsServices.updatePartnerStatus(ID, 'declined'),
    {
      onSuccess: invalidatePartnerRequestListAndClose,
      onError: (error) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  return (
    <Row className="cashEvent-request-wrapper cashEvent-text">
      <BackButtonLeftText
        label={partnerRequestsStrings.details[language]}
        onClick={closeSubmenu2}
      />
      <Row className="cashExchange-contestant-details">
        <DetailsGrid
          title={partnerRequestsStrings.username[language]}
          titleSpan={10}
          value={username}
        />
        <DetailsGrid
          title={partnerRequestsStrings.beneficiaryName[language]}
          titleSpan={10}
          value={beneficiaryName}
        />
        <DetailsGrid
          title={partnerRequestsStrings.bankName[language]}
          titleSpan={10}
          value={bankName}
        />
        <DetailsGrid
          title={partnerRequestsStrings.accountNumber[language]}
          titleSpan={10}
          value={accountNumber}
        />
        <DetailsGrid
          title={partnerRequestsStrings.abaNumber[language]}
          titleSpan={10}
          value={ABANumber}
        />
        <DetailsGrid
          title={partnerRequestsStrings.firstRegistrationDate[language]}
          titleSpan={10}
          value={registrationTimestamp && moment(registrationTimestamp).format('DD/MM/YYYY')}
        />
        <DetailsGrid
          title={partnerRequestsStrings.companyName[language]}
          titleSpan={10}
          value={companyName}
        />
        <DetailsGrid
          title={partnerRequestsStrings.treasuresCreated[language]}
          titleSpan={10}
          value={treasuresCreated}
        />
        <DetailsGrid
          title={partnerRequestsStrings.requestDate[language]}
          titleSpan={10}
          value={requestTimestamp && moment(requestTimestamp).format('DD/MM/YYYY')}
        />
      </Row>

      <Row type="flex" justify="center" style={{ marginTop: '3em', marginBottom: '2em' }}>
        <ButtonWithSpinner
          className="buttons transparent-btn weight-700"
          label={partnerRequestsStrings.reject[language]}
          isLoading={onRejectHandler.isLoading}
          onClick={onRejectHandler.mutate}
          disabled={onApproveHandler.isLoading}
        />
        <div style={{ width: '10px' }} />
        <ButtonWithSpinner
          className="buttons yellow-gradient"
          label={partnerRequestsStrings.approve[language]}
          isLoading={onApproveHandler.isLoading}
          onClick={onApproveHandler.mutate}
          disabled={onRejectHandler.isLoading}
        />
      </Row>
    </Row>
  );
}

PartnerRequestsDetails.propTypes = {
  language: PropTypes.string,
};

PartnerRequestsDetails.defaultProps = {
  language: 'EN',
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  partnerRequests: state.partnerRequests,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),

  partnerRequestsEditParameters: (payload) =>
    dispatch(partnerRequestsActions.editParameters(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerRequestsDetails);
