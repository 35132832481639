import { LanguageMap } from '../interfaces/language';

export const paymentMessage: LanguageMap<string> = {
  EN: 'Payment Successful!',
  KO: '결제가 완료되었습니다',
};

export const paymentMessageDetails: LanguageMap<string> = {
  EN: 'Lcash successfully purchased.',
  KO: 'Lcash를 성공적으로 구매하셨습니다',
};

export const paymentFailed: LanguageMap<string> = {
  EN: 'Payment Failed!',
  KO: '결제에 실패하였습니다',
};

export const paymentCanceled: LanguageMap<string> = {
  EN: 'Payment Canceled!',
  KO: 'Payment Canceled!',
};
