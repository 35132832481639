import React, { memo } from 'react';
import { Row, Button, Typography } from 'antd';
import arrow from '../assets/treasure_item_more_info_icon.svg';
import PropTypes from 'prop-types';

function BackButtonLeftText(props) {
  const { label, onClick } = props;
  return (
    <Row className="back-button-left-text">
      <Button className="details-arrow" onClick={onClick}>
        <img src={arrow} alt="close" />
      </Button>
      <Typography.Text className="title">{label}</Typography.Text>
    </Row>
  );
}

BackButtonLeftText.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

BackButtonLeftText.defaultProps = {
  label: '',
  onClick: () => {
    console.log('missing logic');
  },
};

export default memo(BackButtonLeftText);
