import React, { PureComponent } from 'react';
import { GoogleMap, LoadScript, Circle, Marker } from '@react-google-maps/api';
import RadiusComponent from '../../components/RadiusComponent';
import { connect } from 'react-redux';
import * as treasureActions from '../../actions/treasureActions';
import { mapActions } from '../../actions/mapActions';
import LocationsMapControlList from './LocationsList';
import * as constant from '../../lib/const';
import StickerIcon from '../../assets/sticker_item_on_map.svg';
import ZoomControls from './ZoomControls';
import { gm } from '../../lib/config';
import PointIcon from '../../assets/ar_star_large.svg';
import CandyIcon from '../../assets/icon_candy.svg';
import { store } from '../../helpers/store';
import IconLDiscountSmall from '../../assets/iconLDiscountSmall.svg';

interface MapState {
  zoom: number;
  showInfoBox: boolean;
}

interface MapProps {
  lat: number;
  lng: number;
  mapInstance: any;
  zoom: number;
  locations: any;
  openZoomControls: boolean;
  selectedLocation: number;
  messageLocations: any;
  treasureUnitsResponse: any;
  open: number;
  treasureType: number;
  updateZoom: (zoom: number, radius: number) => void;
  enterRadius?: (radius: number) => void;
  storeInstance: (instance: any) => void;
  updateMap: () => void;
  isValidToken?: boolean;
  updateAddressZoom?: any;
  treasureDetails?: any;
}

const libraries = ['places'];

const locationRadiusOptions = {
  strokeColor: '#70329e',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#70329e',
  fillOpacity: 0.35,
  zIndex: 10,
};

const messagingRadiusOptions = {
  strokeColor: '#70329e',
  strokeOpacity: 0,
  strokeWeight: 0,
  fillColor: '#70329e',
  fillOpacity: 0.5,
  zIndex: 9,
};

const mapStateToProps = (state: {
  treasure: any;
  menuUIChange: any;
  address: { lat: number; lng: number; mapInstance: any; zoom: number };
  locations: any;
  openZoomControls: boolean;
  selectedLocation: any;
  messageLocations: any;
  treasureUnitsResponse: any;
  treasureDetails: any;
}) => {
  const {
    menuUIChange,
    address,
    locations,
    selectedLocation,
    messageLocations,
    treasureUnitsResponse,
    treasure,
    treasureDetails,
  } = state;
  const { lat, lng, mapInstance, zoom } = address;
  const { open, openZoomControls } = menuUIChange;
  const { treasureType } = treasure;
  return {
    open,
    lat,
    lng,
    mapInstance,
    zoom,
    locations,
    openZoomControls,
    selectedLocation,
    messageLocations,
    treasureUnitsResponse,
    treasureType,
    treasureDetails,
  };
};

const actionCreators = {
  updateMap: treasureActions.updateMapAddressOnIdle,
  storeInstance: mapActions.storeInstance,
  updateZoom: treasureActions.updateZoom,
  // updateAddressZoom: treasureActions.updateAddressZoom,
};

class Map extends PureComponent<MapProps, MapState> {
  constructor(props: any) {
    super(props);

    this.state = {
      zoom: constant.DefaultZoom,
      showInfoBox: false,
    };
  }

  getMapCenter = () => {
    if (!this.props.mapInstance) return;
    this.props.updateMap();
  };

  updateZoom = () => {
    if (this.props.mapInstance) {
      const zoom = this.props.mapInstance.getZoom();
      this.props.updateZoom(zoom, this.calculateRadius(zoom));
      this.props.mapInstance.panTo({ lat: this.props.lat, lng: this.props.lng });
    }
  };

  calculateRadius = (zoom: number) => {
    const meters_per_pixel =
      (156543.03392 * Math.cos((this.props.lat * Math.PI) / 180)) / Math.pow(2, zoom);
    let radius = 200 * meters_per_pixel;
    let toKm = radius / 1000;

    return toKm;
  };

  pickIcon = () => {
    switch (this.props.treasureType) {
      case constant.sticker:
        return StickerIcon;
      case constant.point:
        return PointIcon;
      case constant.candy:
        return CandyIcon;
      case constant.LDiscount:
        return IconLDiscountSmall;
      default:
        return null;
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() + 1);
      this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() - 1);
    }
  }

  render() {
    const mapOptions = {
      disableDefaultUI: true,
      gestureHandling: store.getState().menuUIChange.open === 2 ? 'none' : 'auto',
    };

    const treasureSet: JSX.Element[] = [];

    for (let key in this.props.treasureUnitsResponse) {
      let icon = this.pickIcon();
      this.props.treasureUnitsResponse[key].map((treasureUnit: any, index: number) => {
        treasureSet.push(
          <div className="treasure-set" key={Number(`${index}.${key}`)}>
            <Marker
              position={{ lat: Number(treasureUnit.latitude), lng: Number(treasureUnit.longitude) }}
              icon={icon}
            />
          </div>
        );
      });
    }

    let markerTest = null;
    // for testing created locations
    // if (
    //   this.props.treasureDetails.treasureUnits?.length > 0 &&
    //   this.props.treasureDetails.locations.length > 0
    // ) {
    //   markerTest = this.props.treasureDetails.treasureUnits.map((treasureUnit) => {
    //     return (
    //       <Marker
    //         key={treasureUnit.ID}
    //         position={{ lat: Number(treasureUnit.latitude), lng: Number(treasureUnit.longitude) }}
    //       />
    //     );
    //   });
    //   this.props.treasureDetails.locations.map((location) => {
    //     markerTest.push(
    //       <Circle
    //         options={messagingRadiusOptions}
    //         key={location.ID}
    //         center={{
    //           lat: Number(location.latitude),
    //           lng: Number(location.longitude),
    //         }}
    //         radius={location.radius * 1000}
    //       />
    //     );
    //   });
    // }

    return (
      <LoadScript id="script-loader" googleMapsApiKey={gm} libraries={libraries}>
        <LocationsMapControlList />
        {this.props.locations.length > 0 && this.props.openZoomControls ? <ZoomControls /> : null}

        <GoogleMap
          id="example-map"
          center={{ lat: this.props.lat, lng: this.props.lng }}
          zoom={this.state.zoom}
          options={mapOptions}
          onLoad={(map) => {
            this.props.storeInstance(map);
          }}
          onDragEnd={this.getMapCenter}
          onZoomChanged={this.updateZoom}
        >
          <div className="map-overlay">
            {this.props.open === 1 ? <RadiusComponent /> : null}

            {this.props.locations.map((location: any) => {
              return (
                <Circle
                  options={locationRadiusOptions}
                  key={location.ID}
                  center={{ lat: location.latitude, lng: location.longitude }}
                  radius={location.radius * 1000}
                />
              );
            })}

            {markerTest}

            {/* {this.props.messageLocations.map((messageLocation: any) => {
              return (
                <Circle
                  options={messagingRadiusOptions}
                  key={messageLocation.ID}
                  center={{ lat: messageLocation.latitude, lng: messageLocation.longitude }}
                  radius={messageLocation.radius * 1000}
                />
              );
            })} */}

            {treasureSet}
          </div>
        </GoogleMap>
      </LoadScript>
    );
  }
}

export default connect(mapStateToProps, actionCreators)(Map);
