import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQueryClient, useMutation } from 'react-query';
import moment from 'moment';

import { Row } from 'antd';
import { DetailsGrid, BackButtonLeftText, ButtonWithSpinner } from '../../../../../components';

import * as cashExchangeActions from '../../../../../actions/cashExchangeActions';
import * as menuActions from '../../../../../actions/menuActions';
import * as cashExchangeStrings from '../../../../../lib/strings/cashExchangeStrings';
import { updateCashExchangeUserStatus } from '../../../../../services/cashExchangeService';
import { showErrorMessage } from '../../../../../services/sharedServices';
import { serverError } from '../../../../../lib/strings/error';
import PropTypes from 'prop-types';

function CashExchangeDetails(props) {
  const { language, closeSubmenu2, cashExchange, cashExchangeEditParameters } = props;

  useEffect(() => {
    return () => {
      cashExchangeEditParameters({ selectedUserIndex: null });
    };
  }, []);

  const queryClient = useQueryClient();
  const cashExchangeList: any = queryClient.getQueryData([
    'CashExchangeList',
    { paymentStatus: cashExchange.pendingOrFinished },
  ]);

  const invalidateCashExchangeListAndClose = () => {
    queryClient.invalidateQueries([
      'CashExchangeList',
      { paymentStatus: cashExchange.pendingOrFinished },
    ]);
    closeSubmenu2();
  };

  const {
    ID,
    username,
    beneficiaryName,
    bankName,
    bankAccountNumber,
    ABAnumber,
    pointsAmount,
    amountUSD,
    requestTimestamp,
    dateOfPayment,
    email,
  } = cashExchangeList[cashExchange.selectedUserIndex];

  const onPaidHandler = useMutation(
    async () => {
      await updateCashExchangeUserStatus(ID, 'paid');
    },
    {
      onSuccess: invalidateCashExchangeListAndClose,
      onError: (error) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const onDeclinedHandler = useMutation(
    async () => {
      await updateCashExchangeUserStatus(ID, 'declined');
    },
    {
      onSuccess: invalidateCashExchangeListAndClose,
      onError: (error) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  return (
    <Row className="cashEvent-request-wrapper cashEvent-text">
      <BackButtonLeftText label={cashExchangeStrings.details[language]} onClick={closeSubmenu2} />
      <Row className="cashExchange-contestant-details">
        <DetailsGrid
          title={cashExchangeStrings.username[language]}
          titleSpan={10}
          value={username}
        />
        <DetailsGrid
          title={cashExchangeStrings.beneficiaryName[language]}
          titleSpan={10}
          value={beneficiaryName}
        />
        <DetailsGrid
          title={cashExchangeStrings.bankName[language]}
          titleSpan={10}
          value={bankName}
        />
        <DetailsGrid
          title={cashExchangeStrings.accountNumber[language]}
          titleSpan={10}
          value={bankAccountNumber}
        />
        <DetailsGrid
          title={cashExchangeStrings.abaNumber[language]}
          titleSpan={10}
          value={ABAnumber}
        />
        <DetailsGrid
          title={cashExchangeStrings.amountOfPoints[language]}
          titleSpan={10}
          value={pointsAmount}
        />
        <DetailsGrid
          title={cashExchangeStrings.amountOfUsd[language]}
          titleSpan={10}
          value={amountUSD}
        />
        <DetailsGrid
          title={cashExchangeStrings.dateOfExchange[language]}
          titleSpan={10}
          value={requestTimestamp && moment(requestTimestamp).format('DD/MM/YYYY')}
        />
        {cashExchange.pendingOrFinished === 'done' && (
          <DetailsGrid
            title={cashExchangeStrings.dateOfPayment[language]}
            titleSpan={10}
            value={dateOfPayment && moment(dateOfPayment).format('DD/MM/YYYY')}
          />
        )}
        <DetailsGrid
          title={cashExchangeStrings.email[language]}
          isEmail={true}
          titleSpan={10}
          value={email}
        />
      </Row>

      {cashExchange.pendingOrFinished === 'pending' && (
        <>
          {/* Mark as paid button */}
          <Row type="flex" justify="center" style={{ marginTop: '3em', marginBottom: '2em' }}>
            <ButtonWithSpinner
              className="buttons yellow-gradient cashExchange-send-notification-button"
              label={cashExchangeStrings.markAsPaid[language]}
              isLoading={onPaidHandler.isLoading}
              onClick={onPaidHandler.mutate}
              disabled={onDeclinedHandler.isLoading}
            />
          </Row>

          {/* Send points back to user button */}
          <Row type="flex" justify="center">
            <ButtonWithSpinner
              className="buttons cashExchange-button-with-border cashExchange-send-notification-button"
              label={cashExchangeStrings.sendPointsBackToUser[language]}
              isLoading={onDeclinedHandler.isLoading}
              onClick={onDeclinedHandler.mutate}
              disabled={onPaidHandler.isLoading}
            />
          </Row>
        </>
      )}
    </Row>
  );
}

CashExchangeDetails.propTypes = {
  language: PropTypes.string,
};

CashExchangeDetails.defaultProps = {
  language: 'EN',
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  cashExchange: state.cashExchange,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),

  cashExchangeEditParameters: (payload) => dispatch(cashExchangeActions.editParameters(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashExchangeDetails);
