import { responseCodes } from '../lib/const';
import { notification } from 'antd';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import { executeSignOut } from './sharedServices';

export const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

let fakeUsersData = [
  {
    _id: '5f6dba8c8ffe5a1b15a8ebd0',
    username: 'Marko',
    role: 1100,
    phoneNumber: '+385989538172',
    lcash: 100,
  },
  {
    _id: '5fbcfc2b612754d7bcfe463f',
    username: 'Marko Admin',
    role: 1000,
    phoneNumber: '+385999999998',
    lcash: 230,
  },
];

export const getUsersList = async ({ queryKey }) => {
  // for react-query
  const { page, username } = queryKey[1];

  const fetchResponse = await fetch(
    config.baseURL + config.users + '?' + new URLSearchParams({ page, username }),
    {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const response = await fetchResponse.json();

  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw new Error(response.code);
  }
};

export const getUserById = async ({ queryKey }) => {
  // for react-query
  const { id } = queryKey[1];
  const fetchResponse = await fetch(config.baseURL + config.users + '/' + id, {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data.user;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw new Error(response.code);
  }
};

export const addLcash = async (userId, value) => {
  const fetchResponse = await fetch(config.baseURL + config.admin + '/' + userId, {
    method: 'PUT',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ lcash: value }),
  });
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw new Error(response.code);
  }
};

export const getRetakeData = async (userId) => {
  let responseData = { token: Object, user: Object };

  const fetchResponse = await fetch(config.baseURL + config.users + '/' + userId + '/token', {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    responseData.token = response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw new Error(response.code);
  }

  const fetchUser = await fetch(`${config.baseURL}${config.userDetailsAPI}/${userId}`, {
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  const userResponse = await fetchUser.json();
  if (userResponse.code === responseCodes.successful) {
    responseData.user = userResponse.data.user;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw new Error(userResponse.code);
  }

  return responseData;
};
