import { cloneDeep } from 'lodash';
import { detailsConstants } from '../constants/treasureDetailsConstants';
import { menuConstants } from '../constants/menuConstants';

export function treasureDetails(state: any = {}, action: any) {
  switch (action.type) {
    case detailsConstants.STORE_DETAILS:
      return {
        ...state,
        ...action.details.treasure,
      };
    case detailsConstants.EDIT_TREASURE_DETAILS_PARAMETAR:
      return { ...state, [action.parametarName]: action.value };
    default:
      return state;
  }
}

export function clients(state: any = [], action: any) {
  switch (action.type) {
    case detailsConstants.STORE_CLIENTS:
      return action.clients;
    default:
      return state;
  }
}

export const treasureListInitialState = {
  treasureData: [],
  currentPage: 1,
  treasureLength: 0,
};

let newTreasureData;
export function treasureList(state = treasureListInitialState, action: any) {
  switch (action.type) {
    case detailsConstants.STORE_LIST:
      newTreasureData = [...state.treasureData, ...action.treasureList.treasureData];
      return { ...action.treasureList, treasureData: newTreasureData };

    case detailsConstants.DELETE_LIST:
      return { ...state, treasureData: [] };

    case detailsConstants.DELETE_TREASURE:
      newTreasureData = state.treasureData.filter(function (obj) {
        return obj.ID !== action.ID;
      });
      return { ...state, treasureData: newTreasureData, treasureLength: state.treasureLength - 1 };

    case detailsConstants.REVIEWED:
      newTreasureData = state.treasureData.filter(function (obj) {
        return obj.ID !== action.ID;
      });
      return { ...state, treasureData: newTreasureData };

    case detailsConstants.PURGE_DATA:
      return treasureListInitialState;

    case detailsConstants.EDIT_TREASURE_LIST_DATA_PARAMETAR:
      const index = state.treasureData.findIndex((treasure) => treasure.ID === action.ID);
      const editedTreasure = { ...state.treasureData[index], [action.parametarName]: action.value };
      let newArray = [...state.treasureData];
      newArray[index] = editedTreasure;
      return { ...state, treasureData: newArray };

    default:
      return state;
  }
}
