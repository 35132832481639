import { LanguageMap } from '../interfaces/language';

export const Welcome: LanguageMap<string> = {
  EN: 'Welcome to LoaMoa',
  KO: 'LoaMoa에 오신걸 환영합니다',
};

export const Master: LanguageMap<string> = {
  EN: 'Master Account',
  KO: '마스터 계정',
};

export const Instructions1: LanguageMap<string> = {
  EN: 'Confirm your phone number to get the verification code.',
  KO: '인증코드를 받기 위해 휴대폰 번호를 확인해주세요',
};

export const Instructions2: LanguageMap<string> = {
  EN: 'Input 6 digit code:',
  KO: '6자리 인증코드를 입력해주세요',
};

export const Button1: LanguageMap<string> = {
  EN: 'This is my number',
  KO: '제 번호가 맞습니다',
};

export const Button2: LanguageMap<string> = {
  EN: 'What is master account?',
  KO: '마스터 계정이란?',
};

export const MasterModalTitle: LanguageMap<string> = {
  EN: 'Master account info',
  KO: '마스터 계정 정보',
};

export const MasterModalText: LanguageMap<string> = {
  EN:
    'If you want to become a Master and have the ability to create treasures, first you need to download the mobile app on app store and inside your profile select "Switch to Pro".',
  KO:
    '마스터가 되기를 희망하신다면, 모바일 App을 다운 받으신 후 프로필 란에서 “Switch to Pro”를 선택해주세요',
};

export const MasterModalLink: LanguageMap<string> = {
  EN: 'Visit Store',
  KO: 'Store 방문',
};

export const MasteModalDownload: LanguageMap<string> = {
  EN: 'Download app here:',
  KO: '앱 다운받기',
};

export const MasterModalButtonClose: LanguageMap<string> = {
  EN: 'Close',
  KO: '닫기',
};

export const VerificationFormText: LanguageMap<string> = {
  EN: 'Code sent to:',
  KO: '코드 전송 완료',
};

export const VerificationFormText2: LanguageMap<string> = {
  EN: 'Input 6 digit code:',
  KO: '6자리 인증코드를 입력해주세요',
};

export const VerificationFormButton1: LanguageMap<string> = {
  EN: 'Verify',
  KO: '인증',
};

export const VerificationFormButton2: LanguageMap<string> = {
  EN: 'Back',
  KO: '뒤로가기',
};

export const PhoneNumberError: LanguageMap<string> = {
  EN: 'Please input your phone number!',
  KO: '휴대폰 번호를 입력해주세요',
};

export const VerificationInputError1: LanguageMap<string> = {
  EN: 'Please input verification code',
  KO: '인증번호를 입력해주세요',
};

export const VerificationInputError2: LanguageMap<string> = {
  EN: 'Code must have 6 numbers!',
  KO: '인증코드는 반드시 6자리 숫자로 이루어져 있습니다',
};

export const LegalButton1: LanguageMap<string> = {
  EN: 'Privacy Policy',
  KO: '개인정보 보호정책',
};

export const LegalButton2: LanguageMap<string> = {
  EN: 'Terms of Use',
  KO: '이용약관',
};

export const LegalText1: LanguageMap<string> = {
  EN: 'To learn more, read',
  KO: '더 알아보기',
};

export const LegalText2: LanguageMap<string> = {
  EN: 'and',
  KO: '그리고',
};

export const validationErorMessageVerification: LanguageMap<string> = {
  EN: 'You did not enter 6 numbers!',
  KO: '6자리를  모두 입력해주세요',
};

export const didntGetCode: LanguageMap<string> = {
  EN: `Didn't get the code?`,
  KO: `Didn't get the code?`,
};

export const resend: LanguageMap<string> = {
  EN: `RESEND`,
  KO: `RESEND`,
};

export const phoneNumberNotRegistered: LanguageMap<string> = {
  EN: `Phone number not registered`,
  KO: `Phone number not registered`,
};

export const switchNumberToMaster: LanguageMap<string> = {
  EN: `It seems that you have not switched your LoaMoa account to master.`,
  KO: `It seems that you have not switched your LoaMoa account to master.`,
};

export const failedToSendPhoneNumber: LanguageMap<string> = {
  EN: `Failed to send phone number, please try again later.`,
  KO: `Failed to send phone number, please try again later.`,
};
