import React from 'react';
import { Row, Col, Spin } from 'antd';
import UserListItem from './UserListItem';
import moment from 'moment';
import PropTypes from 'prop-types';

function UserList(props) {
  const { type, loading, data, selectedUserIndex, onClick } = props;

  if (loading) {
    return (
      <Row type="flex" justify="center" className="cashEvent-user-list">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <Row type="flex" justify="center" className="cashEvent-user-list">
      <Col span={24}>
        {data.map((user, index) => {
          const date = type === 'pending' ? user.requestTimestamp : user.dateOfPayment;

          return (
            <UserListItem
              onClick={onClick}
              key={user.ID}
              index={index}
              username={user.username}
              transactionText={`${user.pointsAmount} ➝ ${user.amountUSD}`}
              date={moment(date).format('DD/MM/YYYY')}
              selected={selectedUserIndex === index}
              profilePicUrl={user.avatar?.thumbnail?.nameOnServer}
            />
          );
        })}
      </Col>
    </Row>
  );
}

UserList.propTypes = {
  type: PropTypes.oneOf(['pending', 'done']),
  loading: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func,
  selectedUserIndex: PropTypes.number,
};

UserList.defaultProps = {
  loading: true,
  data: [],
};

export default UserList;
