import { store } from '../helpers/store';
import { baseURL, getPushNotificationsAPI, notificationAPI, userListAPI } from '../lib/config';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

export const getScheduledPushNotifications = async () => {
  console.log('getScheduledPushNotifications');
  try {
    const fetchResponse = await fetch(baseURL + getPushNotificationsAPI, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.notifications;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const getSentPushNotifications = async () => {
  console.log('getSentPushNotifications');
  try {
    const fetchResponse = await fetch(baseURL + getPushNotificationsAPI, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const createNotification = async (notification: any, time: number, localization: any) => {
  console.log('createNotification, notification', notification);
  console.log(`createNotification, time: ${time}`);
  console.log('createNotification, localization:', localization);

  try {
    const fetchResponse = await fetch(baseURL + notificationAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: notification.title,
        description: notification.description,
        timestamp: time,
        userIds: JSON.stringify(notification.userIDs),
        url: notification.url,
        type: notification.type,
        contestID: notification.contestID,
        localization,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const getUsersList = async (
  category: string,
  page: number,
  type: string,
  searchQuery?: string,
  contestID?: number
) => {
  const queryParams = new URLSearchParams({
    page: `${page}`,
    searchBy: searchQuery,
  });

  console.log(`category: ${category}, type: ${type}`);

  switch (category) {
    case 'all': {
      const response = await getUsersListApiCall(baseURL + userListAPI + '?' + queryParams);
      return response;
    }
    case 'idol': {
      if (contestID) {
        const typeToUserType = (selectedType) => {
          switch (selectedType) {
            case 'contest-contestants':
              return 'contestants';
            case 'contest-voters':
              return 'voters';
            case 'contest-applicants':
              return 'applicants';
            case 'contest-contestantsNotCompleted':
              return 'contestantsNotCompleted';
            default:
              return 'all';
          }
        };
        const userType = typeToUserType(type);

        queryParams.append('audience', userType);
        const response = await getUsersListApiCall(
          baseURL + userListAPI + `/${contestID}` + '?' + queryParams
        );
        return response;
      } else {
        const response = await getUsersListApiCall(baseURL + userListAPI + '?' + queryParams);
        return response;
      }
    }
  }
};

export const getUsersListApiCall = async (url: string) => {
  console.log('url:', url);
  try {
    const fetchResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const deleteNotification = async (id: number) => {
  console.log(`deleteNotification, id:`, id);
  try {
    const fetchResponse = await fetch(baseURL + notificationAPI + '/' + id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    throw new Error('failed to fetch');
  }
};

export const updateNotification = async (notification: any, time: number, localization: any) => {
  console.log(`updateNotification, notification: ${notification}, localization: ${localization}`);

  const oldNotification = store.getState().notificationDetails;
  let { url } = notification;
  if (url.indexOf('https://') === -1) {
    url = `${'https://'}${url}`;
  }
  let updateBody: any = {};
  updateBody.localization = localization;
  if (notification.type !== oldNotification.type) updateBody.type = notification.type;
  if (notification.title !== oldNotification.title) updateBody.title = notification.title;
  if (url !== oldNotification.url) updateBody.url = url;
  if (notification.description !== oldNotification.description)
    updateBody.description = notification.description;
  if (notification.contestID !== oldNotification.contestID)
    updateBody.contestID = notification.contestID;
  if (notification.timestamp !== oldNotification.timestamp)
    updateBody.timestamp = notification.timestamp;
  if (notification.userIDs.length > 0) updateBody.userIds = JSON.stringify(notification.userIDs);

  try {
    const fetchResponse = await fetch(baseURL + notificationAPI + '/' + notification.ID, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...updateBody,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
};
