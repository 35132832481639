import { LanguageMap } from '../interfaces/language';

export const recharge: LanguageMap<string> = {
  EN: 'Recharge',
  KO: '충전',
};

export const spent: LanguageMap<string> = {
  EN: 'Spent',
  KO: '소비됨',
};

export const bought: LanguageMap<string> = {
  EN: 'Bought',
  KO: '구매함',
};

export const currentBalance: LanguageMap<string> = {
  EN: 'Current Balance',
  KO: '현재 잔고',
};

export const lCash: LanguageMap<string> = {
  EN: 'Lcash',
  KO: 'Lcash',
};

export const adKit: LanguageMap<string> = {
  EN: 'Ad KIT',
  KO: '광고 키트',
};
