import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
const { Text, Title } = Typography;
import * as signin from '../../lib/strings/signin';
import { connect } from 'react-redux';
import { languages } from '../../lib/interfaces/language';

interface MasterInfoScreen {
  onCancel: (e: React.MouseEvent) => void;
  language: languages;
}

const mapState = (state: { authentication: any }) => {
  const { authentication } = state;
  const { language } = authentication;
  return { language };
};

const MasterInfoScreen = (props: MasterInfoScreen) => {
  const { language } = props;
  return (
    <>
      <Row className="modal-wrapper" type="flex" justify="center">
        <Col>
          <Row>
            <Col>
              <Title className="signin-title" level={3}>
                {signin.MasterModalTitle[language]}
              </Title>
            </Col>
          </Row>
          <Row style={{ padding: '1em' }}>
            <Col>
              <Text className="signin-text-instructions center">
                {signin.MasterModalText[language]}
              </Text>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{ padding: '1em' }}>
            <Col>
              <Button className="buttons purple-gradient-right">
                <a href="https://www.loamoa.com/download.html">
                  {signin.MasterModalLink[language]}
                </a>
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{ padding: '1em' }}>
            <Col>
              <Button onClick={props.onCancel} className="buttons gray-gradient">
                {signin.MasterModalButtonClose[language]}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapState)(MasterInfoScreen);
