import React from 'react';
import { Modal, Button, Row, Col, Typography } from 'antd';

import * as appConstants from '../lib/strings/appConstants';

const { Text } = Typography;

function DeleteConfirmationModal({ visible, name, onCancel, onDelete, language }) {
  interface DeleteConfirmationModalProps {
    visible: boolean;
    name: string;
    language: string;
    onCancel: () => void;
    onDelete: () => void;
  }

  const onDeleteHandler = () => {
    onCancel();
    onDelete();
  };

  return (
    <Modal visible={visible} footer={null} closable={false} centered width={270}>
      <Row type="flex" justify="center" className="full-width">
        <Row type="flex" justify="center" style={{ paddingBottom: '22px' }} className="full-width">
          <Text>
            {appConstants.deleteConfirmationText[language]} {name}?
          </Text>
        </Row>
        <Row type="flex" justify="space-between" className="full-width">
          <Col style={{ marginRight: '4px' }}>
            <Button
              className="buttons transparent-btn-black"
              style={{ width: '105px', height: '24px' }}
              onClick={onCancel}
            >
              {appConstants.buttonCancel[language]}
            </Button>
          </Col>
          <Col style={{ marginLeft: '4px' }}>
            <Button
              className="buttons yellow-gradient"
              style={{ width: '105px', height: '24px' }}
              onClick={onDeleteHandler}
            >
              {appConstants.buttonDelete[language]}
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
}

DeleteConfirmationModal.defaultProps = {
  visible: true,
  language: 'EN',
  onCancel: () => {},
  onDelete: () => {},
};

export default DeleteConfirmationModal;
