import React, { Component } from 'react';
import { Row, Button, Typography, Col } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import pointImg from '../../../../assets/ar_star_large.svg';
import edit from '../../../../assets/icon_edit.svg';
import deleteIcon from '../../../../assets/icon_delete.svg';
import lcash from '../../../../assets/lcash.svg';
import star from '../../../../assets/star.png';
import { deleteKit } from '../../../../services/kitService';
import { kitConstants } from '../../../../constants/kitConstants';
import flags from '../../../../helpers/countryCallingCodes.json';
import * as kitCreationStrings from '../../../../lib/strings/kitCreation';
import { languages } from '../../../../lib/interfaces/language';
import { DeleteConfirmationModal } from '../../../../components';
import * as appConstants from '../../../../lib/strings/appConstants';

interface Props {
  handleClose: () => void;
  name: string;
  type: string;
  adDuration: number;
  ID: number;
  editKit: (kit: any) => void;
  kit?: any;
  openEdit: (menu: string) => void;
  pricesPerCountry: Array<any>;
  country: string;
  openKitPrices: (menu: string) => void;
  deleteKit: (ID: number) => void;
  language: languages;
}

const mapState = (state: any) => {
  return {
    name: state.kitDetails.name,
    type: state.kitDetails.type,
    adDuration: state.kitDetails.adDuration,
    ID: state.kitDetails.ID,
    pricesPerCountry: state.kitDetails.pricesPerCountry,
    country: state.userData.country,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    editKit: (kit: any) => dispatch({ type: kitConstants.EDIT_KIT, kit }),
    openEdit: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    openKitPrices: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_2, menu }),
    deleteKit: (ID: number) => dispatch({ type: kitConstants.DELETE_KIT, ID }),
  };
};

class KitDetails extends Component<Props, any> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      defaultPrice: 0,
      countryPrice: 0,
      defaultPoints: 0,
      loaMoaLCash: 0,
      countryFlag: '',
      countryName: '',
      deleteConfirmationModalIsOpen: false,
    };
  }

  calculatePrices() {
    const pricesPerCountry = this.props.pricesPerCountry;
    const index = pricesPerCountry.findIndex((element) => element.country === 'default');
    if (index !== -1) {
      const defaultPrice = pricesPerCountry[index].price;
      const defaultPoints = pricesPerCountry[index].points;
      this.setState({
        defaultPrice: defaultPrice,
        defaultPoints: defaultPoints,
        loaMoaLCash: defaultPrice * 0.4,
      });
    }

    const indexOfCurrentCountry = pricesPerCountry.findIndex(
      (element) => element.country === this.props.country
    );

    if (indexOfCurrentCountry !== -1) {
      const countryPrice = pricesPerCountry[indexOfCurrentCountry].price;
      this.setState({ currentCountryPrice: countryPrice });
    } else {
      this.setState({ currentCountryPrice: '-' });
    }

    const country = flags.find(
      (country: { alpha2Code: string }) => country.alpha2Code === this.props.country
    );
    this.setState({
      countryFlag: country ? country.flag : '',
      countryName: country ? country.name : '',
    });
  }

  componentDidMount() {
    this.calculatePrices();
  }

  componentDidUpdate(prevProps: Readonly<Props> & Readonly<{ children?: React.ReactNode }>) {
    if (prevProps !== this.props) {
      this.calculatePrices();
    }
  }

  deleteKit = async () => {
    const res = await deleteKit(this.props.ID);
    if (!res.data) {
      throw 'error while deleting';
    } else {
      this.props.handleClose();
      this.props.deleteKit(this.props.ID);
    }
  };

  editKit = async () => {
    const edit = this.props;
    const { handleClose, editKit, openEdit, openKitPrices, ...propsToEdit } = edit;
    this.props.editKit(propsToEdit);
    this.props.openEdit('KitCreation');
  };

  toggleDeleteConfirmationModalHandler = () => {
    this.setState((currentState) => {
      return { deleteConfirmationModalIsOpen: !currentState.deleteConfirmationModalIsOpen };
    });
  };

  render() {
    const { language, name } = this.props;
    const { deleteConfirmationModalIsOpen } = this.state;
    return (
      <Row className="kit-details-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="space-between" align="middle">
            <Col>
              <Button onClick={this.props.handleClose} className="details-arrow">
                <img src={arrow} alt="close" />
              </Button>

              <Typography.Text className="contest-title bold">
                {kitCreationStrings.kitTitle[language]}
              </Typography.Text>
            </Col>
            <Col>
              <Button className="button-image" onClick={this.editKit}>
                <img src={edit} alt="close" />
              </Button>
            </Col>
          </Row>
          <Row className="kit-details">
            <Row>
              <Typography.Title className="fredoka" level={4}>
                {this.props.name}
              </Typography.Title>
            </Row>
            <Row>
              <Row type="flex" justify="space-between" align="middle">
                <Typography.Text>
                  <img src={lcash} className="flags" />
                  {this.state.defaultPrice} {kitCreationStrings.lcashUnit[language]}
                </Typography.Text>
                <div
                  className="all-prices"
                  onClick={() => this.props.openKitPrices('KitPricesByCountry')}
                >
                  {kitCreationStrings.allPrices[language]}
                  <img className="arrow-decoration" src={arrow} />
                </div>
              </Row>
              <Row type="flex" justify="space-between" align="middle">
                <Typography.Text>
                  <img className="flags" src={this.state.countryFlag} />{' '}
                  {this.state.currentCountryPrice} {kitCreationStrings.lcashUnit[language]}
                </Typography.Text>
              </Row>
            </Row>
            <Row type="flex" justify="center" className="kit-image">
              <img src={pointImg} />
            </Row>
            <Row type="flex" justify="space-between">
              <Col>
                <div className="kit-details-info">
                  <Row>
                    <Typography.Text className="bold">
                      {kitCreationStrings.kitType[language]}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>{this.props.type}</Typography.Text>
                  </Row>
                </div>
                <div className="kit-details-info">
                  <Row>
                    <Typography.Text className="bold">
                      {kitCreationStrings.userGets2[language]}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>
                      {this.state.defaultPoints}
                      <img src={star} />
                    </Typography.Text>
                  </Row>
                </div>
              </Col>
              <Col>
                <div className="kit-details-info">
                  <Row>
                    <Typography.Text className="bold">
                      {kitCreationStrings.kitDuration[language]}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>
                      {this.props.adDuration}
                      {kitCreationStrings.secondsShort[language]}
                    </Typography.Text>
                  </Row>
                </div>
                <div className="kit-details-info">
                  <Row>
                    <Typography.Text className="bold">
                      {kitCreationStrings.loaMoaGets2[language]}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>
                      <img src={lcash} /> {this.state.loaMoaLCash.toFixed(2)}
                    </Typography.Text>
                  </Row>
                </div>
              </Col>
            </Row>
          </Row>
          <Row type="flex" justify="center" className="full-width">
            <Button
              style={{ width: '72px', height: '16px', marginBottom: '15px' }}
              className="buttons transparent-btn-red"
              onClick={this.toggleDeleteConfirmationModalHandler}
            >
              <span style={{ textDecoration: 'underline' }}>
                {appConstants.buttonDelete[language]}
              </span>
            </Button>
          </Row>
        </div>
        <DeleteConfirmationModal
          visible={deleteConfirmationModalIsOpen}
          onCancel={this.toggleDeleteConfirmationModalHandler}
          name={name}
          onDelete={this.deleteKit}
          language={language}
        />
      </Row>
    );
  }
}

export default connect(mapState, mapDispatch)(KitDetails);
