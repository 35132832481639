import { rechargeConstants } from '../constants/rechargeConstants';

export const recharge = (state = {}, action: any) => {
  switch (action.type) {
    case rechargeConstants.STORE_AMOUNT:
      return {
        ...state,
        amount: action.amount,
        usd: action.amount,
      };
    default:
      return state;
  }
};
