import { LanguageMap } from '../interfaces/language';

export const TreasureReviewPrice: LanguageMap<string> = {
  EN: 'Price:',
  KO: '가격',
};

export const TreasureEnding: LanguageMap<string> = {
  EN: 'Ends in:',
  KO: '종료 시각',
};

export const TreasureReviewButton1: LanguageMap<string> = {
  EN: 'Back',
  KO: '뒤로 가기',
};

export const TreasureReviewButton2: LanguageMap<string> = {
  EN: 'Send to review',
  KO: '리뷰에 보내기',
};

export const LocationPricingDataMessagingPoints: LanguageMap<string> = {
  EN: `Messaging points`,
  KO: '메시징 포인트',
};

export const LocationPricingDataMessagingPoint: LanguageMap<string> = {
  EN: `Messaging point`,
  KO: '메시징 포인트',
};

export const LocationPricingDataUnit: LanguageMap<string> = {
  EN: 'sticker',
  KO: '이모티콘',
};

export const LocationPricingDataUnits: LanguageMap<string> = {
  EN: 'sticker',
  KO: '이모티콘',
};

export const TreasureReviewDurationHours: LanguageMap<string> = {
  EN: 'hours',
  KO: '시간',
};

export const TreasureReviewDurationDays: LanguageMap<string> = {
  EN: 'days',
  KO: '일',
};

export const TreasureReviewDurationWeeks: LanguageMap<string> = {
  EN: 'weeks',
  KO: '주',
};

export const TreasureReviewPublish: LanguageMap<string> = {
  EN: 'Publish',
  KO: '발표',
};

export const AdminReviewButton1: LanguageMap<string> = {
  EN: 'Reviewed',
  KO: '확인 완료',
};

export const AdminReviewButton2: LanguageMap<string> = {
  EN: 'Pending',
  KO: '진행중',
};

export const runningText: LanguageMap<string> = {
  EN: 'Running',
  KO: 'Running',
};

export const treasureTypeSticker: LanguageMap<string> = {
  EN: 'Sticker',
  KO: 'Sticker',
};

export const treasureTypePoint: LanguageMap<string> = {
  EN: 'Point',
  KO: 'Point',
};

export const treasureTypeCandy: LanguageMap<string> = {
  EN: 'Candy',
  KO: 'Candy',
};

export const treasureTypeLDiscount: LanguageMap<string> = {
  EN: 'AD Discount',
  KO: 'AD Discount',
};

export const lCash: LanguageMap<string> = {
  EN: 'Lcash',
  KO: 'Lcash',
};

export const selectPlaceholder: LanguageMap<string> = {
  EN: 'Select',
  KO: 'Select',
};

export const dontWantToUseLDiscount: LanguageMap<string> = {
  EN: 'I don’t want to use AD Discount',
  KO: 'I don’t want to use AD Discount',
};

export const totalPrice: LanguageMap<string> = {
  EN: 'Total:',
  KO: 'Total:',
};

export const LDiscountValue: LanguageMap<string> = {
  EN: 'AD Discount:',
  KO: 'AD Discount:',
};

export const LDiscountErrorMessage: LanguageMap<string> = {
  EN:
    'Selected discount value is higher than the price you have to pay, you will lose the difference of',
  KO:
    'Selected discount value is higher than the price you have to pay, you will lose the difference of',
};

export const LDiscountExpiration: LanguageMap<string> = {
  EN: 'expiration:',
  KO: 'expiration:',
};

export const LDiscountExpired: LanguageMap<string> = {
  EN: 'expired:',
  KO: 'expired:',
};

export const useYourLDiscount: LanguageMap<string> = {
  EN: 'Use your AD Discount',
  KO: 'Use your AD Discount',
};
