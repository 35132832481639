import * as quickData from './quickData';
import moment from 'moment';
import { mediaUpload } from '../services/contestService';

export const fillContestCharacter = (setFieldsValue, handleInput) => {
  const currentStartDate = moment()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const currentEndDate = moment()
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999);

  const datesMoment = {
    'start-date': moment(currentStartDate).subtract(1, 'd'),
    registrationStartDate: moment(currentStartDate),
    uploadContestantsVideoEarliestDate: moment(currentStartDate),
    qualificationsStartDate: moment(currentStartDate).add(8, 'd'),
    finalsStartDate: moment(currentStartDate).add(9, 'd'),

    'end-date': moment(currentEndDate).add(10, 'd'),
    registrationEndDate: moment(currentEndDate).add(7, 'd'),
    uploadContestantsVideoLatestDate: moment(currentEndDate).add(7, 'd'),
    qualificationsEndDate: moment(currentEndDate).add(8, 'd'),
    finalsEndDate: moment(currentEndDate).add(9, 'd'),
  };

  setFieldsValue({
    name: 'quickFill w',
    character: 26,
    firstPlacePrize: '1st prize',
    secondPlacePrize: '2nd prize',
    thirdPlacePrize: '3rd prize',

    ...datesMoment,
  });

  handleInput('name', 'quickFill w');
  handleInput('characterID', 26);
  handleInput('firstPlacePrize', '1st prize');
  handleInput('secondPlacePrize', '2nd prize');
  handleInput('thirdPlacePrize', '3rd prize');

  handleInput('startDate', parseInt(datesMoment['start-date'].format('x')));
  handleInput('endDate', parseInt(datesMoment['end-date'].format('x')));
  handleInput('registrationStartDate', parseInt(datesMoment.registrationStartDate.format('x')));
  handleInput(
    'uploadContestantsVideoEarliestDate',
    parseInt(datesMoment.uploadContestantsVideoEarliestDate.format('x'))
  );
  handleInput('qualificationsStartDate', parseInt(datesMoment.qualificationsStartDate.format('x')));
  handleInput('finalsStartDate', parseInt(datesMoment.finalsStartDate.format('x')));
  handleInput('registrationEndDate', parseInt(datesMoment.registrationEndDate.format('x')));
  handleInput(
    'uploadContestantsVideoLatestDate',
    parseInt(datesMoment.uploadContestantsVideoLatestDate.format('x'))
  );
  handleInput('qualificationsEndDate', parseInt(datesMoment.qualificationsEndDate.format('x')));
  handleInput('finalsEndDate', parseInt(datesMoment.finalsEndDate.format('x')));
};

export const fillContestCustom = async (setFieldsValue, handleInput, ID) => {
  const currentStartDate = moment()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const currentEndDate = moment()
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999);

  const datesMoment = {
    'start-date': moment(currentStartDate).subtract(1, 'd'),
    registrationStartDate: moment(currentStartDate),
    uploadContestantsVideoEarliestDate: moment(currentStartDate),
    qualificationsStartDate: moment(currentStartDate).add(8, 'd'),
    finalsStartDate: moment(currentStartDate).add(9, 'd'),

    'end-date': moment(currentEndDate).add(10, 'd'),
    registrationEndDate: moment(currentEndDate).add(7, 'd'),
    uploadContestantsVideoLatestDate: moment(currentEndDate).add(7, 'd'),
    qualificationsEndDate: moment(currentEndDate).add(8, 'd'),
    finalsEndDate: moment(currentEndDate).add(9, 'd'),
  };

  handleInput('name', 'quickFill w/o');
  handleInput('firstPlacePrize', '1st prize');
  handleInput('secondPlacePrize', '2nd prize');
  handleInput('thirdPlacePrize', '3rd prize');
  handleInput('characterID', null);

  handleInput('title', 'quickFill title');
  handleInput('description', 'quickFill description');

  handleInput('startDate', parseInt(datesMoment['start-date'].format('x')));
  handleInput('endDate', parseInt(datesMoment['end-date'].format('x')));
  handleInput('registrationStartDate', parseInt(datesMoment.registrationStartDate.format('x')));
  handleInput(
    'uploadContestantsVideoEarliestDate',
    parseInt(datesMoment.uploadContestantsVideoEarliestDate.format('x'))
  );
  handleInput('qualificationsStartDate', parseInt(datesMoment.qualificationsStartDate.format('x')));
  handleInput('finalsStartDate', parseInt(datesMoment.finalsStartDate.format('x')));
  handleInput('registrationEndDate', parseInt(datesMoment.registrationEndDate.format('x')));
  handleInput(
    'uploadContestantsVideoLatestDate',
    parseInt(datesMoment.uploadContestantsVideoLatestDate.format('x'))
  );
  handleInput('qualificationsEndDate', parseInt(datesMoment.qualificationsEndDate.format('x')));
  handleInput('finalsEndDate', parseInt(datesMoment.finalsEndDate.format('x')));

  const image = await quickData.constructPictureFile(
    'https://dev.loamoa.com//uploads/CG5Y337hgBohSMyLvyJXacRAVNu0hRlC.png'
  );

  handleInput('imgUrl', image.base64);

  await mediaUpload(ID, image.file, {});

  setFieldsValue({
    name: 'quickFill w/o',
    firstPlacePrize: '1st prize',
    secondPlacePrize: '2nd prize',
    thirdPlacePrize: '3rd prize',
    title: 'quickFill title',
    description: 'quickFill description',
    character: '',
    picturePath: image.base64,

    ...datesMoment,
  });
};
