import { LanguageMap } from '../interfaces/language';

export const image: LanguageMap<string> = {
  EN: 'Image',
  KO: '이미지',
};

export const video: LanguageMap<string> = {
  EN: 'Video',
  KO: '동영상',
};

export const seconds: LanguageMap<string> = {
  EN: 'seconds',
  KO: '초',
};

export const price: LanguageMap<string> = {
  EN: 'Price',
  KO: '가격',
};

export const lcashPerUnit: LanguageMap<string> = {
  EN: '(Lcash/unit)',
  KO: '(Lcash / 유닛)',
};

export const allPrices: LanguageMap<string> = {
  EN: 'All prices',
  KO: '모든 가격',
};

export const perUnit: LanguageMap<string> = {
  EN: '/unit',
  KO: '/ 유닛',
};

export const userGets: LanguageMap<string> = {
  EN: 'User gets',
  KO: '유저 할당금',
};

export const loaMoaGets: LanguageMap<string> = {
  EN: 'LoaMoa gets',
  KO: 'LoaMoa 할당금',
};

export const defaultValue: LanguageMap<string> = {
  EN: 'Default',
  KO: '디폴트',
};

export const labelKitName: LanguageMap<string> = {
  EN: 'KIT Name:',
  KO: '키트 이름',
};

export const labelType: LanguageMap<string> = {
  EN: 'KIT type:',
  KO: '키트 종류',
};

export const labelDuration: LanguageMap<string> = {
  EN: 'Duration:',
  KO: '지속 시간',
};

export const kitLabel1: LanguageMap<string> = {
  EN: 'Creating New Kit',
  KO: '새로운 키트를 생성하고 있습니다',
};

export const kitLabel2: LanguageMap<string> = {
  EN: 'Editing Kit type',
  KO: '새로운 키트 종류를 수정중입니다',
};

export const kitCreationRule1: LanguageMap<string> = {
  EN: 'Enter kit name!',
  KO: '키트 이름을 입력해주세요',
};

export const kitCreationRule2: LanguageMap<string> = {
  EN: 'Enter duration!',
  KO: '지속 시간을 입력해주세요',
};

export const kitCreationRule3: LanguageMap<string> = {
  EN: 'Enter price for your country!',
  KO: '귀하의 나라 환율에 맞춰 가격을 입력해주세요',
};

export const kitCreationRule4: LanguageMap<string> = {
  EN: 'Enter default price!',
  KO: '고정 가격을 입력해주세요',
};

export const button1: LanguageMap<string> = {
  EN: 'Cancel',
  KO: '취소',
};

export const button2: LanguageMap<string> = {
  EN: 'Save',
  KO: '저장',
};

export const button3: LanguageMap<string> = {
  EN: 'Create',
  KO: '생성하기',
};

export const kitTitle: LanguageMap<string> = {
  EN: 'Advertisement KIT',
  KO: '광고 키트',
};

export const lcashUnit: LanguageMap<string> = {
  EN: 'Lcash /unit',
  KO: 'Lcash/ 유닛',
};

export const kitType: LanguageMap<string> = {
  EN: 'Type:',
  KO: '종류: ',
};

export const kitDuration: LanguageMap<string> = {
  EN: 'Duration:',
  KO: '지속 시간',
};

export const userGets2: LanguageMap<string> = {
  EN: 'User Gets:',
  KO: '유저 할당금',
};

export const loaMoaGets2: LanguageMap<string> = {
  EN: 'LoaMoa gets:',
  KO: 'LoaMoa 할당금',
};

export const secondsShort: LanguageMap<string> = {
  EN: 's',
  KO: 's',
};

export const kitPricesPerCountry: LanguageMap<string> = {
  EN: 'KIT prices by country',
  KO: '나라별 키트 가격',
};

export const createNewKitButton: LanguageMap<string> = {
  EN: 'Create new kit type',
  KO: '키트 종류 새로 만들기',
};

export const seeExchangeRateButton: LanguageMap<string> = {
  EN: 'See exchange rate',
  KO: '환율 보기',
};

export const kitTypeErrorMessage: LanguageMap<string> = {
  EN: 'Select KIT type!',
  KO: 'Select KIT type!',
};
