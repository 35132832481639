import React from 'react';
import * as statisticsString from '../../lib/strings/statistics';
import { Row, Spin } from 'antd';
import PropTypes from 'prop-types';

function Table(props) {
  const { columns, dataSource, loading, language, width, height } = props;

  const makeHeader = (columnsData) => {
    const columns = columnsData.map((columnData) => {
      return (
        <th key={columnData.dataIndex} style={columnData.titleStyle}>
          {columnData.title}
        </th>
      );
    });
    return columns;
  };

  const makeColumnsForRow = (rowData, columnsData) => {
    const columnsForRow = columnsData.map((columnData) => {
      return (
        <td
          key={columnData.dataIndex + rowData.treasureId}
          style={columnData.dataStyle}
          onClick={() => columnData.onClick(rowData.id, rowData.username)}
        >
          {rowData[columnData.dataIndex]}
        </td>
      );
    });
    return columnsForRow;
  };

  const makeRows = (rowsData, columnsData) => {
    const rows = rowsData.map((rowData) => {
      return <tr key={rowData.id}>{makeColumnsForRow(rowData, columnsData)}</tr>;
    });
    return rows;
  };

  return (
    <>
      {dataSource.length === 0 && !loading && (
        <p className="warningText">{statisticsString.noResultWareFound[language]}</p>
      )}
      {dataSource.length !== 0 && !loading && (
        <div className="table-scroll-bar">
          <div className="tableFixHead" style={{ width: width, maxHeight: height }}>
            <table>
              <thead>
                <tr>{makeHeader(columns)}</tr>
              </thead>
              <tbody>{makeRows(dataSource, columns)}</tbody>
            </table>
          </div>
        </div>
      )}
      {loading && (
        <Row type="flex" justify="center" align="middle" style={{ padding: '2em' }}>
          <Spin size="large" spinning={props.isLoading} />
        </Row>
      )}
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  language: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
};

Table.defaultProps = {
  language: 'EN',
  columns: [
    {
      title: 'Date',
      titleStyle: {},
      dataIndex: 'date',
      dataStyle: {},
    },
    {
      title: 'Treasure Name',
      dataIndex: 'treasureName',
    },
  ],
  dataSource: [
    {
      date: '22/10/20',
      treasureName: 'tname blaa1',
      id: 1,
    },
    {
      date: '20/10/20',
      treasureName: 'tname blaa2',
      id: 2,
    },
  ],
  loading: false,
  // width: '100%',
};

export default Table;
