import React, { useEffect } from 'react';
import PhoneNumberForm from './PhoneNumberForm';
import VerificationForm from './VerificationForm';
import { Typography, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as signin from '../../lib/strings/signin';
import { locationConstants } from '../../constants/locationConstants';
import { LOCAL_STORAGE_USER_LOCATION, LOCAL_STORAGE_LANGUAGE } from '../../lib/config';
import { userConstants } from '../../constants/signinConstants';
import logo from '../../assets/legalscreen_logo.svg';

const { Title, Text, Paragraph } = Typography;

const mapStateToProps = (state: {
  authentication: { waitingVerification: boolean; user: any; language: any };
}) => {
  const { authentication } = state;
  const { waitingVerification, user, language } = authentication;
  return { waitingVerification, user, language };
};

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    address?: { lat: number; lng: number };
    language?: string;
  }) => any
) => {
  return {
    storeUserLocation: (address: { lat: number; lng: number }) =>
      dispatch({ type: locationConstants.UPDATE_MAP_ADDRESS, address }),
    saveLangPreferences: (language: string) => dispatch({ type: userConstants.LANGUAGE, language }),
    resetReduxToInitial: () => dispatch({ type: userConstants.RESET }),
  };
};

interface SignInProps {
  waitingVerification: boolean;
  user: any;
  storeUserLocation: (address: { lat: number; lng: number }) => void;
  language: languages;
  saveLangPreferences: (code: string) => void;
  resetReduxToInitial: () => void;
}

type languages = 'EN' | 'KO';

const SignIn = (props: SignInProps) => {
  useEffect(() => {
    // reset redux state to initial
    if (!props.user) {
      props.resetReduxToInitial();
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getLocation);
    }
    if (navigator.language === 'ko') {
      props.saveLangPreferences('KO');
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, 'KO');
    } else {
      props.saveLangPreferences('EN');
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, 'EN');
    }
  }, []);

  const getLocation = (position: any) => {
    props.storeUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
    localStorage.setItem(
      LOCAL_STORAGE_USER_LOCATION,
      JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude })
    );
  };

  if (props.user) {
    return <Redirect to="/dashboard" />;
  }

  const { language } = props;

  return (
    <>
      <Row
        className={props.language === 'KO' ? 'signin gmarket-medium' : 'signin'}
        type="flex"
        justify="center"
        align="middle"
      >
        <Col
          className="signin-wrapper"
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 16 }}
          lg={{ span: 10 }}
          xl={{ span: 8 }}
        >
          <Row type="flex" justify="center" className="signin-title">
            <Col>
              <Title className="signin-title" level={3}>
                {signin.Welcome[language]}
                <Text className="master-account-title">{signin.Master[language]}</Text>
              </Title>
            </Col>
          </Row>
          <Row type="flex" justify="center" className="logo-img">
            <img src={logo} alt="LoaMoa" />
          </Row>
          {props.waitingVerification ? <VerificationForm /> : <PhoneNumberForm />}
          <Row className="legal link" type="flex" justify="center">
            <Paragraph>
              {' '}
              {signin.LegalText1[language]}{' '}
              <Link to="terms-of-use">{signin.LegalButton2[language]}</Link>{' '}
              {signin.LegalText2[language]}{' '}
              <Link to="privacy-policy">{signin.LegalButton1[language]}</Link>
            </Paragraph>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
