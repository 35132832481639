import React, { Component, RefObject } from 'react';
import { Form, Row, Col, Button, Select, notification, Modal, InputNumber, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import * as treasureService from '../../../services/treasureService';
import * as treasureActions from '../../../actions/treasureActions';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { languages } from '../../../lib/interfaces/language';
import ChooseSticker from './ChooseSticker';
import { baseURL, isDev } from '../../../lib/config';

import StartEndDatePicker from '../../../components/treasure/StartEndDatePicker';
import LocationForm from './LocationForm';
import location_icon from '../../../assets/location_add_new_location_icon.svg';
import ReviewButtons from '../../../components/treasure/ReviewButtons';
import moment from 'moment';
import { uploadFiles } from '../../../services/sharedServices';
import { UploadFile } from '../../../components';
import { isEmpty } from 'lodash';
import { fillSticker } from '../../../qucikFill/stickerFill';
import LocationFormV2 from './LocationFormV2';

const mapStateToProps = (state: {
  treasure: any;
  locations: any;
  treasureUnitsLocation: any;
  authentication: any;
  characters: any;
  treasureErrors: object;
}) => {
  const { treasure, authentication, characters, locations, treasureErrors } = state;
  const { stickerName, imgUrl, characterID, stickerType, sectionPosition, sectionID } = treasure;
  const { language } = authentication;
  return {
    stickerName,
    imgUrl,
    language,
    characters,
    treasure,
    characterID,
    stickerType,
    sectionPosition,
    sectionID,
    locations,
    treasureErrors,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeStickerImage: treasureActions.storeStickerImage,
      selectCharacter: treasureActions.selectCharacter,
      typeOfSticker: treasureActions.typeOfSticker,
      updateSection: treasureActions.updateSection,
      updatePosition: treasureActions.updatePosition,
      handleInput: treasureActions.handleInput,
      resetTreasureErrors: () => dispatch(treasureActions.resetTreasureErrors()),
    },
    dispatch
  );

const { Option } = Select;

interface StickerCreationProps extends FormComponentProps {
  imgUrl?: any;
  stickerImage?: any;
  storeStickerImage?: (img: File, imagePreviewUrl: any, selectedCharacter: number) => void;
  language: languages;
  form: any;
  characters?: Array<any>;
  selectCharacter?: (id: string) => void;
  characterID?: number;
  typeOfSticker: (type: string) => void;
  stickerType: string;
  updatePosition: (position: number) => void;
  updateSection: (sectionID: number) => void;
  sectionPosition: number;
  sectionID: number;
  takenPositions?: Array<number>;
  role: number;
  treasure: any;
  onHashtagChange: (any) => void;
  onStartDateChange: (any) => void;
  onEndDateChange: (any) => void;
  onAnotherLocationClick: (any) => void;
  onCancel: (any) => void;
  onReview: (any?) => void;
  handleInput: (parameterKey: string, parameterValue: any) => void;
  locations: Array<object>;
  resetTreasureErrors: () => void;
  treasureErrors: object;
}

interface StickerCreationState {
  isModalOpen: boolean;
  loading: boolean;
  done: boolean;
  percentage: number;
}

class StickerCreation extends Component<StickerCreationProps, StickerCreationState> {
  uploadImageRef: RefObject<any>;

  constructor(props: Readonly<StickerCreationProps>) {
    super(props);

    this.uploadImageRef = React.createRef();

    this.state = {
      isModalOpen: false,
      loading: false,
      done: false,
      percentage: 0,
    };
  }

  componentDidMount() {
    const { treasure, form, handleInput } = this.props;
    const { inputParameters } = treasureActions;
    if (!treasure.isEditing) {
      form.setFieldsValue({ [inputParameters.characterID]: 1 });
      handleInput(inputParameters.characterID, 1);
    }
  }

  componentDidUpdate() {
    const { form, treasureErrors, resetTreasureErrors } = this.props;
    if (!isEmpty(treasureErrors)) {
      const errorKeys = Object.keys(treasureErrors);
      let setValues = {};
      errorKeys.forEach((errorKey) => {
        setValues[errorKey] = null;
      });
      // tested only on treasureUnit, and treasureUpdate
      form.setFieldsValue(setValues);

      resetTreasureErrors();
    }
  }

  onStickerChange = async ({ file, fileUrl }) => {
    const { handleInput, treasure, form } = this.props;
    const { setFieldsValue } = form;

    if (fileUrl !== treasure.logoUrl) {
      handleInput('imgUrl', fileUrl);

      setFieldsValue({ stickerImage: fileUrl });
      handleInput('image', file);
    }
  };

  showModal = (e: React.MouseEvent) => {
    this.props.typeOfSticker('existing');
    this.setState({
      isModalOpen: true,
    });
  };

  hideModal = (e: React.MouseEvent) => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleCharacter = (characterID: any) => {
    if (this.props.stickerType === 'new') {
      const { form } = this.props;
      const { inputParameters } = treasureActions;

      // this is batch edit when status is changed
      // treasureService.treasureUpdate(treasure.ID, inputParameters.characterID, characterID);
      form.setFieldsValue({
        [inputParameters.sectionID]: null,
        [inputParameters.sectionPosition]: null,
      });
    }
  };

  getSections = () => {
    const { characters, characterID } = this.props;
    let sections;
    let options;
    if (characters.length > 1 && characterID) {
      sections = [
        ...new Set(
          characters[characterID - 1].sections.map((character: { ID: any; name: string }) => {
            return {
              ID: character.ID,
              name: character.name,
            };
          })
        ),
      ];
      options = sections.map((section: { ID: number; name: string }) => {
        if (section.ID) {
          return (
            <Option key={section.ID} value={section.ID}>
              {section.ID}. {section.name}
            </Option>
          );
        }
      });
    }
    return options;
  };

  onReviewHandler = () => {
    const { onReview, language } = this.props;
    const { validateFields } = this.props.form;
    validateFields((errors, values) => {
      if (!errors) {
        onReview();
      } else {
        console.log('onReviewHandler, there is errors:', errors);
        notification.error({
          message: treasureCreation.treasureCreationErrorTitle[language],
        });
      }
    });
  };

  handleStickerName = async (event) => {
    const { handleInput, treasure } = this.props;
    const { inputParameters } = treasureActions;
    handleInput(inputParameters.treasureName, event.target.value);
    treasureService.treasureUpdate(treasure.ID, inputParameters.treasureName, event.target.value);
  };

  sectionPositionHandler = (event) => {
    const { form, handleInput, language } = this.props;
    const { inputParameters } = treasureActions;

    if (!isNaN(event.target.value)) {
      const parserValue = parseInt(event.target.value);

      if (this.checkIfSectionPositionIsFree(parserValue)) {
        handleInput(inputParameters.sectionPosition, parserValue);
      } else {
        // clear fields
        form.setFieldsValue({ [inputParameters.sectionPosition]: null });
        handleInput(inputParameters.sectionPosition, null);
        notification.error({
          message: treasureCreation.sectionPositionErrorMessage[language],
        });
      }
    } else {
      form.setFieldsValue({ [inputParameters.sectionPosition]: null });
      handleInput(inputParameters.sectionPosition, null);
    }
  };

  checkIfSectionPositionIsFree = (sectionPosition) => {
    const { characters, treasure } = this.props;

    const character = characters.find((character) => character.ID === treasure.characterID);
    const section = character.sections?.find((section) => section.ID === treasure.sectionID);
    const isPositionFree =
      -1 === section.stickers?.findIndex((sticker) => sticker.sectionPosition === sectionPosition);

    return isPositionFree;
  };

  render() {
    const {
      language,
      treasure,
      role,
      onCancel,
      onHashtagChange,
      form,
      onStartDateChange,
      onEndDateChange,
      locations,
      onAnotherLocationClick,
      characterID,
      sectionID,
      sectionPosition,
      imgUrl,
    } = this.props;
    const { getFieldDecorator } = form;
    const { inputParameters } = treasureActions;

    return (
      <>
        <div className="create-treasure-padding" style={{ flex: 1 }}>
          <Form>
            {/* Sticker treasure name */}
            <Form.Item
              className="roboto-regular"
              label={treasureCreation.TreasureCreationNameLabel[language]}
            >
              {getFieldDecorator(inputParameters.treasureName, {
                rules: [
                  {
                    required: true,
                    message: treasureCreation.TreasureCreationNameError[language],
                  },
                ],
                initialValue: treasure.treasureName || '',
              })(
                <Input
                  className="input-treasure full-width"
                  onBlur={this.handleStickerName}
                  placeholder={treasureCreation.TreasureCreationNamePlaceholder[language]}
                  name="candyName"
                />
              )}
            </Form.Item>

            {/* Sticker hashtag */}
            <Form.Item
              className="roboto-regular"
              label={treasureCreation.TreasureCreationHashtagLabel[language]}
            >
              <Row>
                <Col>
                  {getFieldDecorator(inputParameters.hashtag, {
                    rules: [
                      {
                        required: true,
                        message: treasureCreation.TreasureCreationHashtagError[language],
                      },
                    ],
                    initialValue:
                      treasure.hashtags.map((hashtagObject) => hashtagObject.name) || [],
                  })(
                    <Select
                      placeholder="#"
                      className="hashtag"
                      mode="tags"
                      tokenSeparators={[',']}
                      onBlur={onHashtagChange}
                      maxTagCount={10}
                    ></Select>
                  )}
                </Col>
              </Row>
            </Form.Item>

            {/* Buttons Choose or MakeNew */}
            <Form.Item label={treasureCreation.TreasureCreationCreateLabel[language]}>
              <Row type="flex" justify="space-around">
                {/* Choose Button */}
                <Button
                  onClick={this.showModal}
                  className={
                    this.props.stickerType === 'existing'
                      ? 'buttons white-bg-btn-selected'
                      : 'buttons white-bg-btn'
                  }
                >
                  {treasureCreation.TreasureCreationCreateButton1[language]}
                </Button>
                {/* MakeNew Button */}
                <Button
                  onClick={() => this.props.typeOfSticker('new')}
                  className={
                    this.props.stickerType === 'new'
                      ? 'buttons white-bg-btn-selected'
                      : 'buttons white-bg-btn'
                  }
                >
                  {treasureCreation.TreasureCreationCreateButton2[language]}
                </Button>
              </Row>
            </Form.Item>

            {this.props.stickerType === 'new' && (
              <>
                {/* Character Selector */}
                <Form.Item label={treasureCreation.TreasureCreationCharacterLabel[language]}>
                  <Row type="flex" justify="center">
                    {getFieldDecorator(inputParameters.characterID, {
                      rules: [
                        {
                          required: true,
                          message: treasureCreation.characterErrorMessage[language],
                        },
                      ],
                      initialValue: characterID || '',
                    })(
                      <Select
                        disabled={this.props.stickerType !== 'new'}
                        onChange={(e: any) => this.handleCharacter(e)}
                        className="select-treasure-type treasure-type-picker"
                      >
                        {this.props.characters.map((character) => {
                          return (
                            <Option
                              key={character.ID}
                              className="sticker-character"
                              value={character.ID}
                            >
                              <img src={`${baseURL}/${character.image}`} />
                              {character.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Row>
                </Form.Item>

                {/* Section & Order selector */}
                <Form.Item label={treasureCreation.TreasureCreationCharacterLabel2[language]}>
                  {this.props.characters.length > 1 ? (
                    <Row type="flex" justify="center">
                      {getFieldDecorator(inputParameters.sectionID, {
                        rules: [
                          ,
                          {
                            required: true,
                            message: treasureCreation.sectionErrorMessage[language],
                          },
                        ],
                        initialValue: sectionID || '',
                      })(
                        <Select
                          className="select-treasure-type treasure-type-picker"
                          onChange={this.props.updateSection}
                          disabled={this.props.stickerType !== 'new'}
                        >
                          {this.getSections()}
                        </Select>
                      )}
                    </Row>
                  ) : null}
                </Form.Item>

                {/* Sticker position number */}
                <div style={{ opacity: !treasure.sectionID && 0.5 }}>
                  <Form.Item
                    label={treasureCreation.TreasureCreationCharacterLabel3[language]}
                    className="sticker-pos-number"
                    colon={false}
                  >
                    <Row type="flex" justify="center">
                      {getFieldDecorator(inputParameters.sectionPosition, {
                        rules: [
                          {
                            required: true,
                            message: treasureCreation.sectionPositionErrorMessage2[language],
                          },
                        ],
                        initialValue: sectionPosition || '',
                      })(
                        <InputNumber
                          disabled={this.props.stickerType !== 'new' || !treasure.sectionID}
                          onBlur={this.sectionPositionHandler}
                          className="input-number"
                          style={{ opacity: !treasure.sectionID && 0.5 }}
                          min={0}
                        />
                      )}
                    </Row>
                  </Form.Item>
                </div>
              </>
            )}

            <Form.Item
              className="roboto-regular image-upload-req"
              label={treasureCreation.TreasureCreationImageLabel[language]}
            >
              {getFieldDecorator('stickerImage', {
                rules: [
                  {
                    required: true,
                    message: treasureCreation.uploadFileMissing[language],
                  },
                ],
                initialValue: imgUrl || '',
              })(
                <div>
                  <UploadFile
                    language={language}
                    id="stickerImage"
                    onChange={this.onStickerChange}
                    initialFileUrl={treasure.mediaPath?.pic?.path}
                    valueUrl={treasure.imgUrl ? treasure.imgUrl : ''}
                  />
                </div>
              )}
            </Form.Item>

            <StartEndDatePicker
              language={language}
              initialStartDateValue={
                treasure.isEditing || treasure.onBack
                  ? treasure.startDate
                  : parseInt(moment().format('x'))
              }
              initialEndDateValue={treasure.endDate}
              onChangeStartDate={onStartDateChange}
              onChangeEndDate={onEndDateChange}
              form={form}
              formStartDateId={inputParameters.startDate}
              formEndDateId={inputParameters.endDate}
            />

            {/* locations */}
            {/* {locations.map((location: any, index: number) => {
              return (
                <LocationForm
                  key={index}
                  locationID_UI={index}
                  form={form}
                  location={(treasure.isEditing || treasure.onBack) && location}
                />
              );
            })} */}

            {/* add another location button */}
            {/* <Row type="flex" justify="center" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
              <Button
                style={{ width: '100%' }}
                className="buttons purple-gradient-right menu-button-width"
                onClick={onAnotherLocationClick}
              >
                <img src={location_icon} alt="location" />
                {treasureCreation.TreasureCreationAddLocation[language]}
              </Button>
            </Row> */}

            <LocationFormV2 form={form} />
          </Form>
        </div>

        {/* Cancel, Save as draft, Next, buttons on bottom */}
        <ReviewButtons
          treasure={treasure}
          role={role}
          onCancel={onCancel}
          onReview={this.onReviewHandler}
        />

        <Modal
          bodyStyle={{
            padding: '0',
            paddingTop: '1em',
            borderRadius: '10px',
            maxHeight: '80vh',
            height: 'fit-content',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
          visible={this.state.isModalOpen}
          footer={null}
          closable={false}
          maskClosable={true}
          centered={true}
          width={540}
          onCancel={(e) => this.hideModal(e)}
        >
          <ChooseSticker close={this.hideModal} form={form} />
        </Modal>

        {/* quickFill for testing */}
        {isDev && !treasure.isEditing && (
          <div className="quick-fill-container">
            <Button
              onClick={() => {
                fillSticker(
                  this.props.form.setFieldsValue,
                  this.handleStickerName,
                  this.props.onHashtagChange,
                  onEndDateChange,
                  this.props.typeOfSticker,
                  treasure.ID
                );
              }}
            >
              AUTOFILL Sticker
            </Button>
          </div>
        )}
      </>
    );
  }
}

const wrappedTreasureCreationForm = Form.create<any>({ name: 'stickerTreasure' })(StickerCreation);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedTreasureCreationForm);
