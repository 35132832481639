import * as quickData from './quickData';
import moment from 'moment';
import { enterLatLng } from '../actions/treasureActions';
import { store } from '../helpers/store';

export const latLngMoj = {
  coordinates: { lat: 45.8327039, lng: 16.0535014 },
  address: 'Aleja Blaža Jurišića 9A, 10000, Zagreb, Croatia',
};

export const latLngLana = {
  coordinates: { lat: 45.810498763091715, lng: 16.018992480328382 },
  address: 'Ul. Frana Alfirevića 17, 10000, Zagreb, Croatia',
};

export const latLng = latLngLana;

export const fillImage = async (
  handleKitData,
  handlePointName,
  onHashtagChange,
  setFieldsValue,
  onEndDateChange,
  onAdvertisementChange
) => {
  store.dispatch(enterLatLng(latLng.coordinates, 0));

  const url = 'https://dev.loamoa.com/uploads/6myG4htMDgjeX5TCFHJ7kiKOIoNDnb38.png';
  const image = await quickData.constructPictureFile(url);

  const kitTypeID = 81;
  handleKitData(kitTypeID);
  setFieldsValue({
    kitTypeID: kitTypeID,
    lat0: latLng.coordinates.lat,
    lng0: latLng.coordinates.lng,
  });

  handlePointName({ target: { value: 'quickFill Point image' } });

  onHashtagChange(['quickFill']);

  onEndDateChange(parseInt(moment().add(7, 'd').format('x')));

  onAdvertisementChange({ file: image.file, fileUrl: image.base64 });
};

export const fillVideo = async (
  handleKitData,
  handlePointName,
  onHashtagChange,
  setFieldsValue,
  onEndDateChange,
  onAdvertisementChange
) => {
  const latLng = latLngMoj;
  store.dispatch(enterLatLng(latLng.coordinates, 0));

  const url = 'https://dev.loamoa.com/uploads/0Hr7USzsh1F8BWqlRYDxuE6OaL3lijID.mp4';
  const image = await quickData.constructPictureFile(url);

  const kitTypeID = 123;
  handleKitData(kitTypeID);
  setFieldsValue({
    kitTypeID: kitTypeID,
    lat0: latLng.coordinates.lat,
    lng0: latLng.coordinates.lng,
    address0: latLng.address,
  });

  handlePointName({ target: { value: 'quickFill Point video' } });

  onHashtagChange(['quickFill']);

  onEndDateChange(parseInt(moment().add(7, 'd').format('x')));

  onAdvertisementChange({ file: image.file, fileUrl: image.base64 });
};
