import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Typography, Spin } from 'antd';
import { connect } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { languageList } from '../../../../lib/strings/character';
import * as contestStrings from '../../../../lib/strings/contest';
import {
  OneLineInput,
  TitleAndDescription,
  UploadImgOrVidDecorator,
  ButtonWithSpinner,
  BackButtonLeftText,
  ModalLoadingProgressText,
} from '../../../../components';
import { constructLocalizationBody } from '../../../../components/localization/localizationHelperFunctions';
import {
  getModelLocalizationQuery,
  postModelLocalizationQuery,
  uploadLocalizationFilesQuery,
} from '../../../../services/localizationService';
import { showErrorMessage } from '../../../../services/sharedServices';
import { serverError } from '../../../../lib/strings/error';
import { baseURL } from '../../../../lib/config';
import * as menuActions from '../../../../actions/menuActions';
import PropTypes from 'prop-types';
import LanguageChangeModal from '../../../../components/contest/editBanner/LanguageChangeModal';

function EditIdolBanner(props) {
  const queryClient = useQueryClient();
  const { language, form, closeSubmenu1 } = props;
  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;

  const [percentage, setPercentage] = useState(0);

  const [selectedLanguage, setSelectedLanguage] = useState('EN');

  const [file0, setFile0] = useState();
  const [fileUrl0, setFileUrl0] = useState('');
  const [file1, setFile1] = useState();
  const [fileUrl1, setFileUrl1] = useState('');

  const [formIsChanged, setFormIsChanged] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [languageBuffer, setLanguageBuffer] = useState('');

  const idolBanner = useQuery(
    ['IdolBanner', { ID: 1, model: 'idol_banner' }],
    getModelLocalizationQuery,
    {
      onError: (error: any) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  useEffect(() => {
    if (idolBanner.data && idolBanner.data[selectedLanguage.toLocaleLowerCase()]) {
      const bannerData = idolBanner.data[selectedLanguage.toLocaleLowerCase()];
      setFieldsValue({
        title: bannerData.title,
        subtitle: bannerData.subtitle,
        fileUrl0: bannerData.fileUrl0,
        title1: bannerData.title1,
        fileUrl1: bannerData.fileUrl1,
        title2: bannerData.title2,
        description2: bannerData.description2,
        title3: bannerData.title3,
        description3: bannerData.description3,
        title4: bannerData.title4,
        description4: bannerData.description4,
      });

      setFile0(null);
      setFile1(null);
      setFileUrl0(bannerData.fileUrl0 ? baseURL + bannerData.fileUrl0 : '');
      setFileUrl1(bannerData.fileUrl1 ? baseURL + bannerData.fileUrl1 : '');
    } else {
      form.resetFields();

      setFile0(null);
      setFile1(null);
      setFileUrl0('');
      setFileUrl1('');
    }
  }, [idolBanner.data, selectedLanguage]);

  const uploadLogic = async () => {
    let { fileUrl0, fileUrl1 } = getFieldsValue();

    // upload files and get files links
    if (file0 || file1) {
      const formData = new FormData();
      formData.append('file0', file0);
      formData.append('file1', file1);
      const res: any = await uploadLocalizationFilesQuery(formData, {
        onProgress: (perc: number) => {
          if (perc >= 100) {
          }
          setPercentage(perc);
        },
      });
      if (res.file0) {
        fileUrl0 = res.file0.image;
      }
      if (res.file1) {
        fileUrl1 = res.file1.image;
      }
    }

    const body = { ...getFieldsValue(), fileUrl0, fileUrl1 };
    const localizationBody = constructLocalizationBody(1, 'idol_banner', selectedLanguage, body);

    await postModelLocalizationQuery(localizationBody);
  };

  const onSubmitHandler = useMutation(uploadLogic, {
    onSuccess: () => {
      queryClient.invalidateQueries(['IdolBanner', { ID: 1, model: 'idol_banner' }]);
    },
    onError: (error) => {
      showErrorMessage(serverError[language], error);
    },
  });

  const onFile0ChangeHandler = ({ file, fileUrl }) => {
    setFile0(file);
    setFileUrl0(fileUrl);
    onFormChangeHandler();
  };

  const onFile1ChangeHandler = ({ file, fileUrl }) => {
    setFile1(file);
    setFileUrl1(fileUrl);
    onFormChangeHandler();
  };

  const onLanguageChangeHandler = (selectedLanguage) => {
    // setSelectedLanguage(selectedLanguage);
    if (formIsChanged) {
      setShowWarningPopup(true);
      setLanguageBuffer(selectedLanguage);
    } else {
      setSelectedLanguage(selectedLanguage);
    }
  };

  const onFormChangeHandler = () => {
    setFormIsChanged(true);
  };

  const popupConfirmHandler = () => {
    setSelectedLanguage(languageBuffer);
    setShowWarningPopup(false);
    setFormIsChanged(false);
  };

  const popupCancelHandler = () => {
    setShowWarningPopup(false);
  };

  if (idolBanner.isLoading) {
    return (
      <Row className="cashEvent-request-wrapper">
        <BackButtonLeftText
          label={contestStrings.editIdolBanner[language]}
          onClick={closeSubmenu1}
        />
        <Row type="flex" justify="center" className="contestants-user-list">
          <Spin size="large" />
        </Row>
      </Row>
    );
  }

  return (
    <>
      <Row className="cashEvent-request-wrapper">
        <BackButtonLeftText
          label={contestStrings.editIdolBanner[language]}
          onClick={closeSubmenu1}
        />
        <Row className="cashExchange-contestant-details scrollable">
          <Col className="edit-idol-banner">
            <Form>
              {/* Language selector */}
              <Row>
                <Form.Item>
                  <Select
                    className="select-treasure-type treasure-type-picker full-width"
                    onChange={onLanguageChangeHandler}
                    defaultValue={'EN'}
                    value={selectedLanguage}
                  >
                    {languageList[language].map((language) => (
                      <Select.Option value={language.value} key={language.value}>
                        {language.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>

              <Row className="section" style={{ paddingTop: 8 }}>
                <Col>
                  <Typography.Text className="section-text">
                    {contestStrings.banner[language]}
                  </Typography.Text>
                </Col>
              </Row>

              <OneLineInput
                getFieldDecorator={getFieldDecorator}
                id="title"
                label={contestStrings.title[language]}
                placeholder={contestStrings.placeholderTitle[language]}
                className="title"
                onChange={onFormChangeHandler}
              />
              <OneLineInput
                getFieldDecorator={getFieldDecorator}
                id="subtitle"
                label={contestStrings.subtitle[language]}
                placeholder={contestStrings.placeholderSubtitle[language]}
                className="title"
                onChange={onFormChangeHandler}
              />

              <UploadImgOrVidDecorator
                form={form}
                id="fileUrl0"
                label={contestStrings.image[language]}
                onChange={onFile0ChangeHandler}
                pictureUrl={fileUrl0}
              />

              <Row className="section">
                <Col>
                  <Typography.Text className="section-text">
                    {contestStrings.moreInfo[language]}
                  </Typography.Text>
                </Col>
              </Row>

              <OneLineInput
                getFieldDecorator={getFieldDecorator}
                id="title1"
                label={contestStrings.title1[language]}
                placeholder={contestStrings.placeholderTitle[language]}
                className="title"
                onChange={onFormChangeHandler}
              />

              <UploadImgOrVidDecorator
                form={form}
                id="fileUrl1"
                label={contestStrings.image[language]}
                onChange={onFile1ChangeHandler}
                pictureUrl={fileUrl1}
              />

              <TitleAndDescription
                getFieldDecorator={getFieldDecorator}
                id={2}
                labelTitle={contestStrings.title2[language]}
                labelDescription={contestStrings.description[language]}
                titlePlaceholder={contestStrings.placeholderTitle[language]}
                descriptionPlaceholder={contestStrings.placeholderDescription[language]}
                onChangeTitle={onFormChangeHandler}
                onChangeDescription={onFormChangeHandler}
              />

              <TitleAndDescription
                getFieldDecorator={getFieldDecorator}
                id={3}
                labelTitle={contestStrings.title3[language]}
                labelDescription={contestStrings.description[language]}
                titlePlaceholder={contestStrings.placeholderTitle[language]}
                descriptionPlaceholder={contestStrings.placeholderDescription[language]}
                onChangeTitle={onFormChangeHandler}
                onChangeDescription={onFormChangeHandler}
              />

              <TitleAndDescription
                getFieldDecorator={getFieldDecorator}
                id={4}
                labelTitle={contestStrings.title4[language]}
                labelDescription={contestStrings.description[language]}
                titlePlaceholder={contestStrings.placeholderTitle[language]}
                descriptionPlaceholder={contestStrings.placeholderDescription[language]}
                onChangeTitle={onFormChangeHandler}
                onChangeDescription={onFormChangeHandler}
              />
            </Form>
            <Row type="flex" justify="space-around" className="banner-buttons">
              <Col>
                <ButtonWithSpinner
                  label={contestStrings.cancel[language]}
                  type="transparentBlack"
                  underline
                  onClick={closeSubmenu1}
                />
              </Col>
              <Col>
                <ButtonWithSpinner
                  label={contestStrings.save[language]}
                  type="purpleGradient"
                  onClick={onSubmitHandler.mutate}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalLoadingProgressText
          loading={onSubmitHandler.isLoading}
          percentage={percentage}
          text={contestStrings.uploadingFilesPleaseWait[language]}
        />
      </Row>
      <LanguageChangeModal
        language={language}
        visible={showWarningPopup}
        onCancel={popupCancelHandler}
        onConfirm={popupConfirmHandler}
      />
    </>
  );
}

EditIdolBanner.propTypes = {
  language: PropTypes.string,
};

EditIdolBanner.defaultProps = {
  language: 'EN',
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
});

const wrappedContestCreationForm = Form.create({ name: 'editIdolBanner' })(EditIdolBanner);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedContestCreationForm);
