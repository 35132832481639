import React from 'react';
import { Col, Row, Form, Input, Select } from 'antd';
import * as candyEvent from '../../lib/strings/candyEvent';
import UploadFile from '../UploadFile';
import moment from 'moment';
import PropTypes from 'prop-types';

function CreateGlobalInput(props) {
  const { language, title, month, eventPictureUrl, onChange } = props;
  const { getFieldDecorator } = props.form;

  const onTitleChange = (event) => {
    onChange('title', event.target.value);
  };

  const onMonthChange = (value) => {
    const startDate = parseInt(moment().month(value).startOf('month').format('x'));
    const endDate = parseInt(moment().month(value).endOf('month').format('x'));

    onChange('month', value);
    onChange('startDate', startDate);
    onChange('endDate', endDate);
  };

  const onFileChange = ({ file, fileUrl }) => {
    if (fileUrl !== eventPictureUrl) {
      onChange('eventPictureUrl', fileUrl);
      onChange('eventPictureFile', file);
    }
  };

  return (
    <>
      <Form.Item label={candyEvent.eventTitle[language]}>
        {getFieldDecorator('title', {
          rules: [{ required: true, message: candyEvent.errorTitle[language] }],
        })(
          <Input
            className="input-treasure full-width"
            onBlur={onTitleChange}
            placeholder={candyEvent.eventTitlePlaceholder[language]}
          />
        )}
      </Form.Item>
      <Form.Item label={candyEvent.uploadPicture[language]}>
        {getFieldDecorator('eventPictureUrl', {
          rules: [{ required: true, message: candyEvent.errorFile[language] }],
        })(
          <div>
            <UploadFile onChange={onFileChange} valueUrl={eventPictureUrl} />
          </div>
        )}
      </Form.Item>
      <Form.Item label={candyEvent.selectorTitle[language]}>
        <Row className="treasure-list-location">
          <Col>
            {getFieldDecorator('month', {
              rules: [{ required: true, message: candyEvent.errorMonth[language] }],
            })(
              <Select
                className="treasure-list-location-selector treasure-list-status-selector-picker"
                placeholder={candyEvent.selectorPlaceholder[language]}
                onChange={onMonthChange}
              >
                {candyEvent.selectorMonthsList[language].map((month, index) => (
                  <Select.Option className="text-centered" key={month} value={index}>
                    {month}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}

CreateGlobalInput.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
  title: PropTypes.string,
  month: PropTypes.number,
  eventPictureUrl: PropTypes.string,
  onChange: PropTypes.func,
};

CreateGlobalInput.defaultProps = {
  language: 'EN',
  onChange: () => {},
};

export default CreateGlobalInput;
