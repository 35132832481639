import React from 'react';
import { Row, Col, Typography } from 'antd';
import redDot from '../../../../assets/treasure_item_red_dot_icon.svg';
import { baseURL } from '../../../../lib/config';
import * as appConstants from '../../../../lib/strings/appConstants';
import { connect } from 'react-redux';
import { languages } from '../../../../lib/interfaces/language';
import * as treasureActions from '../../../../actions/treasureActions';
import stickerImg from '../../../../assets/sticker_item_on_map.svg';
import pointImg from '../../../../assets/ar_star_large.svg';
import {
  roleAdmin,
  sticker,
  point,
  candy,
  LDiscount,
  inReview,
  approved,
  draft,
} from '../../../../lib/const';
import candyImg from '../../../../assets/icon_candy.svg';
import moment from 'moment';
import * as treasureInfo from '../../../../lib/strings/treasureInfo';
import iconLDiscountSmall from '../../../../assets/iconLDiscountSmall.svg';
import PropTypes from 'prop-types';

const { Text } = Typography;

interface TreasureProps {
  name: string;
  totalNumberOfUnits: number;
  exposureDuration: number;
  treasureType: number;
  unitImage: any;
  language: languages;
  locations: Array<any>;
  ID: number;
  getTreasureByID: (ID: number) => void;
  status: number;
  pointImage: any;
  selected: boolean;
  handleSelectedTreasure: (id: number) => void;
  role: number;
  username?: string;
  createdAt: number | string;
  startsAt: number | string;
  reuse: boolean;
  reuseHandler: (id: number) => void;
  startDate?: number;
  endDate?: number;
}

const mapState = (state: any) => {
  const { authentication, userData } = state;
  const { language } = authentication;
  const { role } = userData;
  return { language, role };
};

const actionCreators = {
  getTreasureByID: treasureActions.getTreasureByID,
};

const Treasure = (props: TreasureProps) => {
  const { reuse, reuseHandler, startDate, endDate } = props;

  const handleMouseEnter = () => {
    if (reuse) {
      reuseHandler(props.pointImage);
    } else {
      props.getTreasureByID(props.ID);
      //props.handleSelectedTreasure(props.ID)
    }
  };

  const language = props.language;

  let duration;
  let durationUnit;
  if (props.exposureDuration <= 24) {
    duration = props.exposureDuration.toFixed();
    durationUnit =
      duration === '1'
        ? appConstants.TreasureCreationDurationOption3[language]
        : appConstants.TreasureReviewDurationHours[language];
  } else if (props.exposureDuration > 24 && props.exposureDuration <= 24 * 7) {
    const hoursToDays = props.exposureDuration / 24;
    duration = hoursToDays.toFixed();
    durationUnit =
      duration === '1'
        ? appConstants.TreasureCreationDurationOption2[language]
        : appConstants.TreasureReviewDurationDays[language];
  } else if (props.exposureDuration > 24 * 7) {
    const hoursToWeeks = props.exposureDuration / (24 * 7);
    duration = hoursToWeeks.toFixed();
    durationUnit =
      duration === '1'
        ? appConstants.TreasureCreationDurationOption1[language]
        : appConstants.TreasureReviewDurationWeeks[language];
  }

  if (duration < 0) {
    duration = 0;
  }

  let image;
  switch (props.treasureType) {
    case sticker:
      image = props.unitImage ? `${baseURL}${props.unitImage.pic.path}` : stickerImg;
      break;
    case point:
      if (props.pointImage?.pic?.path) {
        image = `${baseURL}${props.pointImage.pic.path}`;
      } else if (props.pointImage?.thumbnail?.path) {
        image = `${baseURL}${props.pointImage.thumbnail.path}`;
      } else {
        image = pointImg;
      }
      break;
    case candy:
      image = candyImg;
      break;
    case LDiscount:
      image = iconLDiscountSmall;
      break;
    default:
      break;
  }

  let createdAt;
  if (props.createdAt !== '-') {
    createdAt = new Date(props.createdAt);
    let date = createdAt.getDate();
    let month = createdAt.getMonth();
    let year = createdAt.getFullYear();
    createdAt = `${date}/${month + 1}/${year}`;
  } else {
    createdAt = props.createdAt;
  }

  const currentDate = parseInt(moment().format('x'));

  const serverStatusToText = (status: number) => {
    switch (status) {
      case draft:
        return { text: treasureInfo.draftsText[language], cssClass: 'treasure-icon-draft' };
      case inReview:
        return { text: treasureInfo.reviewText[language], cssClass: 'treasure-icon-review' };
      case approved:
        if (currentDate < startDate) {
          // pending
          return { text: treasureInfo.pendingText[language], cssClass: 'treasure-icon-pending' };
        } else if (currentDate > endDate) {
          // finished
          return { text: treasureInfo.finishedText[language], cssClass: 'treasure-icon-finished' };
        } else if (currentDate >= startDate && currentDate <= endDate) {
          // running
          return { text: treasureInfo.runningText[language], cssClass: 'treasure-icon-running' };
        }
      default:
        console.log('wrong treasure status', 'status:', status, 'ID', props.ID);
        return { text: treasureInfo.draftsText[language], cssClass: 'treasure-icon-draft' };
    }
  };
  const treasureStatus = serverStatusToText(props.status);

  return (
    <Row
      type="flex"
      justify="center"
      onClick={handleMouseEnter}
      className={props.selected ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
    >
      <Row type="flex" justify="space-between" align="middle" style={{ width: '95%' }}>
        <Col className="treasure-img">
          <img src={image} />
        </Col>
        <Col style={{ width: '72%' }}>
          <Row type="flex" align="middle" justify="space-between">
            <Text className="treasure-item-text bold">{props.name}</Text>
            <Row type="flex" style={{ position: 'relative' }}>
              <div className="treasure-eye"></div>
              <div className="treasure-arrow"></div>
            </Row>
          </Row>

          {props.status === inReview && props.role < roleAdmin ? (
            <Row type="flex" align="middle" justify="space-between">
              <Text className="treasure-item-text">
                {appConstants.review[language]} <img src={redDot} />
              </Text>
            </Row>
          ) : null}
          {props.role === roleAdmin ? (
            <Row type="flex" align="middle" justify="space-between">
              <Text className="treasure-item-text bold">{props.username}</Text>
            </Row>
          ) : null}

          {/* status of treasure text */}
          <Row type="flex" align="middle" justify="start">
            {/* style should be deleted when rest of UI is repaired */}
            <Col style={{ marginLeft: '3px' }}>
              <div className={`status-icon ${treasureStatus.cssClass}`}></div>
            </Col>
            <Col>
              <Text className="treasure-item-text status-list-text">
                {`${treasureStatus.text}`}
              </Text>
            </Col>
          </Row>
          {!reuse && (
            <Row type="flex" align="middle" justify="space-between">
              <Text className="treasure-item-text treasure-list-text">
                <div className="treasure-icon treasure-icon-location"></div>
                {props.locations.length} {appConstants.locations[language]}
              </Text>
              <Text className="treasure-item-text gray-caps">
                <span>{appConstants.created[language]} </span>
                <span>
                  {typeof props.createdAt === 'number'
                    ? moment(props.createdAt, 'x').format('DD/MM/YY')
                    : '-'}
                </span>
              </Text>
            </Row>
          )}
          <Row type="flex" align="middle" justify="space-between">
            <Text className="treasure-item-text treasure-list-text">
              <div className="treasure-icon treasure-icon-time"></div>
              {props.treasureType !== candy ? `${duration} ${durationUnit}` : '-'}
            </Text>
            <Text className="treasure-item-text gray-caps">
              {appConstants.starts[language]}{' '}
              <span>
                {props.status > inReview ? moment(props.startsAt, 'x').format('DD/MM/YY') : '-'}
              </span>
            </Text>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

Treasure.defaultProps = {
  reuse: false,
  reuseHandler: () => {},
};

export default connect(mapState, actionCreators)(Treasure);
