import { pending, requestSuccessful } from '../lib/const';
import { notification } from 'antd';
import { serverError } from '../lib/strings/error';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import moment from 'moment';
import countryData from '../helpers/country.json';
import { statusConstants } from '../containers/dashboard/menu/statistics/apiToTabel';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

const countryToCountryCode = (countryName) => {
  // country codes from applicationCache, saved in redux

  const country = countryData.find((country) => countryName === country.name);
  return country.alpha2Code;
};

const unixTimestamp = (momentDate) => parseInt(moment(momentDate).format('x'));

export const getStatisticsForTreasures = async (query: any) => {
  // console.log(`getStatistics, page: ${query.page}, query:`, query)

  const findKitId = (kitType) => {
    const kitList = store.getState().kitList;
    const kit = kitList.find((kit) => kitType === kit.name);
    return kit?.ID;
  };

  const statusNameToApi = (statusName) => {
    switch (statusName) {
      case 'Pending':
        return statusConstants.pending;
      case 'Active':
        return statusConstants.active;
      case 'Inactive':
        return statusConstants.inactive;
    }
    // 'Pending', 'Active', 'Inactive'
  };

  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statistics +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          status: query?.status ? `${statusNameToApi(query.status)}` : '',
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          treasureName: query?.treasureName ? query.treasureName : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          kittypeid: query?.kitType ? `${findKitId(query.kitType)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForCoins = async (query: any) => {
  // console.log(`getStatisticsForCoins, page: ${query.page}, query:`, query)

  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statisticsCoins +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForPoints = async (query: any) => {
  // console.log(`getStatisticsForPoints, page: ${query.page}, query:`, query)

  try {
    const fetchResponse = await fetch(
      config.baseURL +
        config.statisticsPoints +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForPartnersAdmin = async (query: any) => {
  console.log(`getStatisticsForPartnersAdmin, page: ${query.page}, query:`, query);
  const paymentStatusQuery = query?.paymentStatus === 'Not paid' ? 'pending' : query?.paymentStatus;

  try {
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsPartners}-admin?` +
        new URLSearchParams({
          page: `${query.page}`,
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          paymentStatus: query?.paymentStatus ? `${paymentStatusQuery}` : '',
          status: query?.status ? `${query.status}` : '',
          partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const markAsPaid = async (ID) => {
  console.log(`markAsPaid, ID: ${ID}`);

  const fetchResponse = await fetch(
    `${config.baseURL}${config.statisticsPartners}/markAsPaid/${ID}`,
    {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const response = await fetchResponse.json();
  if (response.code === responseCodes.successful && response.data === 'Success!') {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.data}, ${response.code}`;
  }
};

export const getStatisticsForPartners = async (query: any) => {
  console.log(`getStatisticsForPartners, page: ${query.page}, query:`, query);
  const paymentStatusQuery = query?.paymentStatus === 'Not paid' ? 'pending' : 'paid';

  console.log('query?.status:', query?.status);
  const treasureStatusQuery = query?.status === 'Inactive' ? 'finished' : query?.status;

  console.log(
    `${config.baseURL}${config.statisticsPartners}?` +
      new URLSearchParams({
        page: `${query.page}`,
        startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
        endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        paymentStatus: query?.paymentStatus ? paymentStatusQuery : '',
        treasureStatus: query?.status ? `${treasureStatusQuery.toLowerCase()}` : '',
        partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
      })
  );

  try {
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsPartners}?` +
        new URLSearchParams({
          page: `${query.page}`,
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          paymentStatus: query?.paymentStatus ? paymentStatusQuery : '',
          treasureStatus: query?.status ? `${treasureStatusQuery.toLowerCase()}` : '',
          partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};
