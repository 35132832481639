import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Select, InputNumber, DatePicker, notification, Button } from 'antd';
import { connect } from 'react-redux';
import * as treasureActions from '../../../actions/treasureActions';
import * as treasureService from '../../../services/treasureService';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import moment from 'moment';
import calendar from '../../../assets//icon_date.svg';
import ReviewButtons from '../../../components/treasure/ReviewButtons';
import StartEndDatePicker from '../../../components/treasure/StartEndDatePicker';
import LocationForm from './LocationForm';
import { isEmpty } from 'lodash';
import location_icon from '../../../assets/location_add_new_location_icon.svg';
import PropTypes from 'prop-types';
import { isDev } from '../../../lib/config';
import { fillAdDiscount } from '../../../qucikFill/adDiscountFill';
import LocationFormV2 from './LocationFormV2';

function LDiscountCreation(props) {
  const {
    language,
    treasure,
    handleInput,
    onHashtagChange,
    onStartDateChange,
    onEndDateChange,
    locations,
    onAnotherLocationClick,
    role,
    onCancel,
    onReview,
    treasureErrors,
    resetTreasureErrors,
    form,
  } = props;

  const { getFieldDecorator, setFieldsValue, validateFields } = form;
  const { inputParameters } = treasureActions;

  const maximumNameCharacters = 18;
  const maximumDescriptionCharacters = 112;

  const [nameCharacterLeft, setNameCharacterLeft] = useState(maximumNameCharacters);

  const [descriptionCharacterLeft, setDescriptionCharacterLeft] = useState(
    maximumDescriptionCharacters
  );

  useEffect(() => {
    if (treasure.treasureName) {
      setNameCharacterLeft(maximumNameCharacters - treasure.treasureName.length);
    }
    if (treasure.description) {
      setDescriptionCharacterLeft(maximumDescriptionCharacters - treasure.description.length);
    }
  }, []);

  useEffect(() => {
    const checkIfError = () => {
      if (!isEmpty(treasureErrors)) {
        const errorKeys = Object.keys(treasureErrors);
        let setValues = {};
        errorKeys.forEach((errorKey) => {
          setValues[errorKey] = null;
        });
        // tested only on treasureUnit, and treasureUpdate
        setFieldsValue(setValues);

        resetTreasureErrors();
      }
    };

    checkIfError();
  }, [treasureErrors]);

  const updateApi = (treasureKey: string, treasureParameter: any) => {
    treasureService.treasureUpdate(treasure.ID, treasureKey, treasureParameter);
  };

  const onInputChange = (event) => {
    handleInput(inputParameters.treasureName, event.target.value);
    updateApi(inputParameters.treasureName, event.target.value);
  };

  const onDescriptionChange = (event) => {
    handleInput(inputParameters.description, event.target.value);
    updateApi(inputParameters.description, event.target.value);
  };

  const onLDiscountChange = (event) => {
    handleInput(inputParameters.discount_value, event.target.value);
    updateApi(inputParameters.discount_value, event.target.value);
  };

  const currentDate = moment().subtract(1, 'minute');

  const ldiscountExpirationDateHandler = (date) => {
    const expirationDate = parseInt(
      moment(date)
        .set('hours', 23)
        .set('minutes', 59)
        .set('second', 59)
        .set('millisecond', 999)
        .format('x')
    );

    handleInput(inputParameters.ldiscountExpirationDate, expirationDate);
    updateApi(inputParameters.ldiscountExpirationDate, expirationDate);
  };

  const onReviewHandler = () => {
    validateFields((errors, values) => {
      if (!errors) {
        onReview();
      } else {
        console.log('onReviewHandler, there are errors:', errors);
        notification.error({
          message: treasureCreation.treasureCreationErrorTitle[language],
        });
      }
    });
  };

  return (
    <>
      <div className="create-treasure-padding" style={{ flex: 1 }}>
        <Form>
          {/* Treasure name input field */}
          <Form.Item
            className="roboto-regular"
            label={treasureCreation.TreasureCreationNameLabel[language]}
          >
            {getFieldDecorator(inputParameters.treasureName, {
              rules: [{ required: true, message: treasureCreation.LDiscountNameError[language] }],
              initialValue: treasure.treasureName || '',
            })(
              <Input
                className="input-treasure full-width"
                onBlur={onInputChange}
                placeholder={treasureCreation.TreasureCreationNamePlaceholder[language]}
                maxLength={maximumNameCharacters}
                onChange={(event: any) => {
                  setNameCharacterLeft(maximumNameCharacters - event.target.value.length);
                }}
              />
            )}
            <div className="ldiscount-character-count">{nameCharacterLeft}</div>
          </Form.Item>

          {/* Hasthtags input field */}
          <Form.Item
            className="roboto-regular"
            label={treasureCreation.TreasureCreationHashtagLabel[language]}
          >
            <Row>
              <Col>
                {getFieldDecorator('hashtag', {
                  rules: [
                    {
                      required: true,
                      message: treasureCreation.TreasureCreationHashtagError[language],
                    },
                  ],
                  initialValue: treasure.hashtags.map((hashtagObject) => hashtagObject.name) || [],
                })(
                  <Select
                    placeholder="#"
                    className="hashtag"
                    mode="tags"
                    tokenSeparators={[',']}
                    onBlur={onHashtagChange}
                    maxTagCount={10}
                  ></Select>
                )}
              </Col>
            </Row>
          </Form.Item>

          {/* Description input */}
          <Form.Item label={treasureCreation.labelDescription[language]}>
            <Row>
              <Col>
                {getFieldDecorator(inputParameters.description, {
                  rules: [
                    {
                      required: true,
                      message: treasureCreation.LDiscountDescriptionError[language],
                    },
                  ],
                  initialValue: treasure.description || '',
                })(
                  <Input.TextArea
                    className="input-treasure-text-area full-width"
                    placeholder={treasureCreation.addMessage[language]}
                    style={{ height: '100px' }}
                    onBlur={onDescriptionChange}
                    maxLength={maximumDescriptionCharacters}
                    onChange={(event: any) => {
                      setDescriptionCharacterLeft(
                        maximumDescriptionCharacters - event.target.value.length
                      );
                    }}
                  />
                )}
                <div
                  className="ldiscount-character-count"
                  style={{ position: 'absolute', bottom: 8, right: 10 }}
                >
                  {descriptionCharacterLeft}
                </div>
              </Col>
            </Row>
          </Form.Item>

          {/* LDiscount value input */}
          <Form.Item label={treasureCreation.LDiscountValue[language]}>
            <Row>
              <Col>
                {getFieldDecorator(inputParameters.discount_value, {
                  rules: [
                    {
                      required: true,
                      message: treasureCreation.LDiscountDescriptionError[language],
                    },
                  ],
                  initialValue: treasure.discount_value || '',
                })(
                  <InputNumber
                    className="input-treasure full-width"
                    placeholder={treasureCreation.amount[language]}
                    onBlur={onLDiscountChange}
                    // defaultValue={text}
                  />
                )}
              </Col>
            </Row>
          </Form.Item>

          {/* LDiscount expiration date */}
          <Form.Item label={treasureCreation.LDiscountExpirationDate[language]}>
            <Row>
              <Col>
                {getFieldDecorator(inputParameters.ldiscountExpirationDate, {
                  rules: [
                    {
                      required: true,
                      message: treasureCreation.LDiscountExpirationError[language],
                    },
                  ],
                  initialValue: treasure.ldiscountExpirationDate
                    ? moment(treasure.ldiscountExpirationDate)
                    : null,
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    format={'DD/MM/YY'}
                    showTime={false}
                    suffixIcon={<img src={calendar} />}
                    onChange={ldiscountExpirationDateHandler}
                    disabled={false}
                    disabledDate={(current) => {
                      return (
                        currentDate.isAfter(current) ||
                        (treasure.endDate && moment(treasure.endDate).isAfter(current)) ||
                        (treasure.startDate && moment(treasure.startDate).isAfter(current))
                      );
                    }}
                  />
                )}
              </Col>
            </Row>
          </Form.Item>

          <StartEndDatePicker
            language={language}
            initialStartDateValue={
              treasure.isEditing || treasure.onBack
                ? treasure.startDate
                : parseInt(moment().format('x'))
            }
            initialEndDateValue={treasure.endDate}
            onChangeStartDate={onStartDateChange}
            onChangeEndDate={onEndDateChange}
            form={form}
            formStartDateId={inputParameters.startDate}
            formEndDateId={inputParameters.endDate}
          />

          <LocationFormV2 form={form} />

          {/* {locations.map((location: any, index: number) => {
            return (
              <LocationForm
                key={index}
                locationID_UI={index}
                form={form}
                location={(treasure.isEditing || treasure.onBack) && location}
              />
            );
          })}

          <Row type="flex" justify="center" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <Button
              style={{ width: '100%' }}
              className="buttons purple-gradient-right menu-button-width"
              onClick={onAnotherLocationClick}
            >
              <img src={location_icon} alt="location" />
              {treasureCreation.TreasureCreationAddLocation[language]}
            </Button>
          </Row> */}
        </Form>
      </div>
      <ReviewButtons
        treasure={treasure}
        role={role}
        onCancel={onCancel}
        onReview={onReviewHandler}
      />

      {/* quickFill for testing */}
      {isDev && !treasure.isEditing && (
        <div className="quick-fill-container">
          <Button
            onClick={() => {
              fillAdDiscount(
                form.setFieldsValue,
                onInputChange,
                onHashtagChange,
                onEndDateChange,
                onDescriptionChange,
                onLDiscountChange,
                ldiscountExpirationDateHandler
              );
            }}
          >
            AD Discount
          </Button>
        </div>
      )}
    </>
  );
}

LDiscountCreation.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
  treasure: PropTypes.object,
  onHashtagChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onAnotherLocationClick: PropTypes.func,
  onCancel: PropTypes.func,
  onReview: PropTypes.func,
};

LDiscountCreation.defaultProps = {
  language: 'EN',
  onHashtagChange: (value) => {
    console.log('Missing onHashtagChangeLogic, value:', value);
  },
  onStartDateChange: () => {},
  onEndDateChange: () => {},
  onAnotherLocationClick: () => {},
  onCancel: () => {},
  onReview: () => {},
};

const mapStateToProps = (state) => ({
  treasure: state.treasure,
  role: state.userData.role,
  locations: state.locations,
  treasureErrors: state.treasureErrors,
});

const mapDispatchToProps = (dispatch) => ({
  handleInput: (treasureKey: string, treasureParameter: any) =>
    dispatch(treasureActions.singleStoreToTreasure(treasureKey, treasureParameter)),
  resetTreasureErrors: () => dispatch(treasureActions.resetTreasureErrors()),
});

const wrappedTreasureCreationForm = Form.create({ name: 'adDiscountTreasure' })(LDiscountCreation);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedTreasureCreationForm);
