import React, { useState } from 'react';
import { Row, Typography, Col, Button } from 'antd';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { characterConstants } from '../../../../constants/characterConstants';
import edit from '../../../../assets/icon_edit.svg';
import deleteIcon from '../../../../assets/icon_delete.svg';
import { deleteCharacterStory } from '../../../../services/characterService';
import { menuConstants } from '../../../../constants/menuConstants';
import * as subMenuConstants from '../../../../lib/SubMenuConstants';
import { baseURL } from '../../../../lib/config';
import { languages } from '../../../../lib/interfaces/language';
import { Dispatch, Action } from 'redux';
import playButton from '../../../../assets/play_button_small.svg';
import ReactPlayer from 'react-player';
import { DeleteConfirmationModal } from '../../../../components';
import * as appConstants from '../../../../lib/strings/appConstants';

const { Text, Title } = Typography;

interface Props {
  characterDetails: any;
  closeDetails: () => Action;
  editCharacter: (contest: any) => Action;
  deleteCharacter: (contest: any) => Action;
  openSubmenu1: (menu: string) => Action;
  language: languages;
}

const mapState = (state: { characterDetails: any; authentication: any }) => {
  const { characterDetails, authentication } = state;
  const { language } = authentication;
  return { characterDetails, language };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    closeDetails: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    editCharacter: (character: any) =>
      dispatch({ type: characterConstants.EDIT_CHARACTER, character }),
    deleteCharacter: (characterId: any) =>
      dispatch({ type: characterConstants.DELETE_CHARACTER, id: characterId }),
    openSubmenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
  };
};

const CharacterInfo = (props: Props) => {
  const [video, setVideo] = useState(false);

  const handleDelete = async () => {
    await deleteCharacterStory(props.characterDetails.id);
    props.deleteCharacter(props.characterDetails.id);
    props.closeDetails();
  };

  const editCharacter = () => {
    const editObj = props.characterDetails;
    const { picturePath, ...rest } = editObj;
    const imgUrl = picturePath;
    editObj.imgUrl = imgUrl;
    props.editCharacter(editObj);
    props.openSubmenu1(subMenuConstants.characterForm);
  };

  const playVideo = () => {
    setVideo(true);
  };

  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState(false);
  const toggleDeleteConfirmationModalHandler = () => {
    setDeleteConfirmationModalIsOpen((currentState: boolean) => !currentState);
  };

  const { characterDetails, language } = props;

  return (
    <Row className="characterStoryInfo-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="space-between">
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={props.closeDetails} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Text className="bold" style={{ fontSize: '1.3em' }}>
              {characterDetails?.name}
            </Text>
          </Col>
          <Col style={{ marginRight: '1em' }}>
            <Button className="button-image" onClick={editCharacter}>
              <img src={edit} alt="close" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Row className="characterStory-image">
            {!video ? (
              characterDetails?.video_thumbnail?.image && (
                <img src={`${baseURL}${characterDetails?.video_thumbnail?.image}`} />
              )
            ) : (
              <ReactPlayer
                url={`${baseURL}${characterDetails?.preview_video?.video}`}
                playing
                height={'auto'}
                width={'100%'}
              />
            )}
            {characterDetails?.video_thumbnail?.image && (
              <button className="button-image">
                {!video ? <img src={playButton} alt="play" onClick={playVideo} /> : null}
              </button>
            )}
          </Row>
        </Row>

        <div style={{ padding: '1em 2em' }}>
          <Col>
            <Row>
              <Title style={{ marginBottom: 0 }} className="fredoka" level={4}>
                {characterDetails?.title}
              </Title>
            </Row>
            <Row style={{ paddingTop: '1em', paddingBottom: '1em' }}>
              <Typography.Text className="bold">
                {characterDetails?.mainDescription}
              </Typography.Text>
            </Row>
            <Row style={{ paddingTop: '1em', paddingBottom: '1em' }}>
              <Typography.Text className="bold">
                {characterDetails?.auxillaryDescription}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text className="bold">{characterDetails?.description}</Typography.Text>
            </Row>
          </Col>
        </div>

        <Row>
          <Row className="characterStory-image">
            {characterDetails.preview_image ? (
              <img src={`${baseURL}${characterDetails?.preview_image?.image}`} />
            ) : null}
          </Row>
        </Row>

        <Row type="flex" justify="center">
          <Button
            style={{ width: '72px', height: '16px', paddingTop: '30px', paddingBottom: '30px' }}
            className="buttons transparent-btn-red"
            onClick={toggleDeleteConfirmationModalHandler}
          >
            <span style={{ textDecoration: 'underline' }}>
              {appConstants.buttonDelete[language]}
            </span>
          </Button>
        </Row>
      </div>
      <DeleteConfirmationModal
        visible={deleteConfirmationModalIsOpen}
        onCancel={toggleDeleteConfirmationModalHandler}
        name={characterDetails.title}
        onDelete={handleDelete}
        language={language}
      />
    </Row>
  );
};

export default connect(mapState, mapDispatch)(CharacterInfo);
