import React, { Component } from 'react';
import { Row, Button, Typography, Col, InputNumber, Form } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import coin from '../../../../assets/coin_icon.svg';
import star from '../../../../assets/star.png';
import arrowRight from '../../../../assets/arrow_right.svg';
import { kitConstants } from '../../../../constants/kitConstants';
import flags from '../../../../helpers/countryCallingCodes.json';
import { exchange, exchangeRate } from '../../../../services/kitService';
import { FormComponentProps } from 'antd/lib/form';
import { roleSuperadmin } from '../../../../lib/const';
import { languages } from '../../../../lib/interfaces/language';
import * as exchangeStrings from '../../../../lib/strings/exchangeRate';

interface Props extends FormComponentProps {
  handleClose: () => void;
  openExchangeRates: (menu: string) => void;
  updateDefaultPoints: (points: number) => void;
  updateCountryPoints: (country: string, points: number) => void;
  country: string;
  pointsByCountry: number;
  storeExchangeRates: (rates: any, country: string) => void;
  role: number;
  pointsDefault: number;
  language: languages;
}

const mapState = (state: any) => {
  return {
    pointsByCountry: state.exchangeRate.pointsByCountry,
    cointsByCountry: state.exchangeRate.coinsByCountry,
    country: state.userData.country,
    role: state.userData.role,
    pointsDefault: state.exchangeRate.pointsDefault,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    openExchangeRates: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_2, menu }),
    updateDefaultPoints: (points: number) =>
      dispatch({ type: kitConstants.UPDATE_POINTS_DEFAULT, points }),
    updateCountryPoints: (country: string, points: number) =>
      dispatch({ type: kitConstants.UPDATE_POINTS_COUNTRY, country, points }),
    storeExchangeRates: (rates: any, country: string) =>
      dispatch({ type: kitConstants.STORE_RATES, rates, country }),
  };
};

class ExchangeRate extends Component<Props, any> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      countryFlag: '',
      countryName: '',
      isCountryTouched: false,
      isDefaultTouched: false,
    };
  }

  store = async () => {
    const res = await exchangeRate();
    if (res) {
      this.props.storeExchangeRates(res.data.Coefficients, this.props.country);
    }
  };

  componentDidMount = async () => {
    await this.store();

    const country = flags.find(
      (country: { alpha2Code: string }) => country.alpha2Code === this.props.country
    );
    this.setState({
      countryFlag: country ? country.flag : '',
      countryName: country ? country.name : '',
    });
    this.props.form.setFieldsValue({ country: this.props.pointsByCountry });
    this.props.form.setFieldsValue({ default: this.props.pointsDefault });
  };

  updateCountry = (e: { target: { name: string; value: any } }) => {
    this.props.updateCountryPoints(e.target.name, e.target.value);
    this.setState({ isCountryTouched: true });
  };

  updateDefault = (e: { target: { name: string; value: any } }) => {
    this.props.updateDefaultPoints(e.target.value);
    this.setState({ isDefaultTouched: true });
  };

  updateExchangeRate = async () => {
    if (this.state.isCountryTouched) {
      await exchange(this.props.country, this.props.pointsByCountry);
    }

    if (this.state.isDefaultTouched) {
      console.log(this.props.pointsDefault);
      await exchange('default', this.props.pointsDefault);
    }

    this.props.handleClose();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { language } = this.props;
    return (
      <Row className="kit-details-wrapper">
        <div className="scrollable">
          <Row className="er-top-row" type="flex" justify="space-between" align="middle">
            <Col onClick={this.props.handleClose}>
              <Button className="details-arrow">
                <img src={arrow} alt="close" />
              </Button>
              <Typography.Text className="contest-title bold">
                {exchangeStrings.exchangeRateTitle[language]}
              </Typography.Text>
            </Col>
            <Col onClick={() => this.props.openExchangeRates('ExchangeRateByCountry')}>
              <Typography.Text className="contest-title bold">
                {exchangeStrings.allRates[language]}
              </Typography.Text>
              <Button style={{ border: 'none' }}>
                <img src={arrow} alt="close" />
              </Button>
            </Col>

            <Form style={{ flexDirection: 'column', width: '100%' }}>
              <Row type="flex" justify="center" style={{ flexDirection: 'column', width: '100%' }}>
                <Row type="flex" justify="center">
                  <Col>
                    <Typography.Text className="contest-heading">
                      <img className="flags" src={this.state.countryFlag} />{' '}
                      {this.state.countryName}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row type="flex" justify="center" className="kit-country">
                  <Col>
                    <Row type="flex" justify="center">
                      <img src={star} />
                    </Row>
                    <Row type="flex" justify="center">
                      <Typography.Text className="contest-heading">
                        {exchangeStrings.points[language]}
                      </Typography.Text>
                    </Row>
                    <Form.Item>
                      <Row type="flex" justify="center">
                        {getFieldDecorator('country')(
                          <InputNumber
                            className="input-treasure exchange-input"
                            name={this.props.country}
                            onBlur={this.updateCountry}
                          />
                        )}
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col>
                    <img src={arrowRight} />
                  </Col>
                  <Col>
                    <Row type="flex" justify="center">
                      <img src={coin} />
                    </Row>
                    <Row type="flex" justify="center">
                      <Typography.Text className="contest-heading">
                        {exchangeStrings.coins[language]}
                      </Typography.Text>
                    </Row>
                    <Row type="flex" justify="center">
                      1
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col>
                    <Typography.Text className="contest-heading">
                      {exchangeStrings.defaultLabel[language]}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row type="flex" justify="center" className="kit-default">
                  <Col>
                    <Row type="flex" justify="center">
                      <img src={star} />
                    </Row>
                    <Row type="flex" justify="center">
                      <Typography.Text className="contest-heading">
                        {exchangeStrings.points[language]}
                      </Typography.Text>
                    </Row>
                    <Row type="flex" justify="center">
                      {getFieldDecorator('default')(
                        <InputNumber
                          disabled={this.props.role < roleSuperadmin}
                          className="input-treasure exchange-input"
                          name={this.props.country}
                          onBlur={this.updateDefault}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <img src={arrowRight} />
                  </Col>
                  <Col>
                    <Row type="flex" justify="center">
                      <img src={coin} />
                    </Row>
                    <Row type="flex" justify="center">
                      <Typography.Text className="contest-heading">
                        {exchangeStrings.coins[language]}
                      </Typography.Text>
                    </Row>
                    <Row type="flex" justify="center">
                      1
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" justify="center" style={{ padding: '25px' }}>
                  <Button
                    className="buttons purple-gradient-right"
                    onClick={this.updateExchangeRate}
                  >
                    {exchangeStrings.saveButton[language]}
                  </Button>
                </Row>
              </Row>
            </Form>
          </Row>
        </div>
      </Row>
    );
  }
}

const wrappedExchangeRateForm = Form.create({ name: 'exchangeRate' })(ExchangeRate);
export default connect(mapState, mapDispatch)(wrappedExchangeRateForm);
