export const menuConstants = {
  OPEN_TREASURE_CREATION: 'OPEN_TREASURE_CREATION',
  CANCEL_TREASURE_CREATION: 'CANCEL_TREASURE_CREATION',
  OPEN_REVIEW_SCREEN: 'REVIEW',
  BACK_TO_TREASURE_CREATION: 'BACK_TO_TREASURE_CREATION',
  SEND_TO_REVIEW: 'SEND_TO_REVIEW',
  OPEN_MAIN: 'OPEN_MAIN',
  IMAGE: 'IMAGE',
  OPEN_LOCATION_UI: 'OPEN_LOCATION_UI',
  CLOSE_LOCATION_UI: 'CLOSE_LOCATION_UI',
  EDIT_DRAFT: 'EDIT_DRAFT',
  OPEN_KIT_CREATION: 'OPEN_KIT_CREATION',
  OPEN_SUBMENU_1: 'OPEN_SUBMENU_1',
  CLOSE_SUBMENU_1: 'CLOSE_SUBMENU_1',
  OPEN_SUBMENU_2: 'OPEN_SUBMENU_2',
  CLOSE_SUBMENU_2: 'CLOSE_SUBMENU_2',
  OPEN_SUBMENU_FULL_SCREEN: 'OPEN_SUBMENU_FULL_SCREEN',
  CLOSE_SUBMENU_FULL_SCREEN: 'CLOSE_SUBMENU_FULL_SCREEN',
  CHANGE_MENU: 'CHANGE_MENU',
  OPEN_CREATE_CANDY_EVENT: 'OPEN_CREATE_CANDY_EVENT',
  CLOSE_MENU: 'CLOSE_MENU',
};

interface OpenReviewAction {
  type: typeof menuConstants.OPEN_REVIEW_SCREEN;
}

interface BackToTreasureCreationAction {
  type: typeof menuConstants.BACK_TO_TREASURE_CREATION;
}

interface CancelTreasureCreationAction {
  type: typeof menuConstants.CANCEL_TREASURE_CREATION;
}

interface OpenTreasureCreationAction {
  type: typeof menuConstants.OPEN_TREASURE_CREATION;
}

interface SendToReviewAction {
  type: typeof menuConstants.SEND_TO_REVIEW;
}

export type menuActionTypes =
  | OpenReviewAction
  | BackToTreasureCreationAction
  | CancelTreasureCreationAction
  | OpenTreasureCreationAction
  | SendToReviewAction;
