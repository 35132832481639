import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col, Button, Popover } from 'antd';
import * as treasureCreation from '../../lib/strings/treasureCreation';
import { baseURL } from '../../lib/config';
import ReuseList from './ReuseList';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

function MakeNewUploadReuse(props) {
  const { language, kitType, onChange, value, form, ...otherProps } = props;
  const [uploadReuseMakeNew, setUploadReuseMakeNew] = useState<'upload' | 'makeNew' | 'reuse'>(
    'upload'
  );
  const windowDimensions: any = useRef(null);

  const numberOfButtons = kitType === 'image' ? 3 : 2;

  useEffect(() => {
    onChange(uploadReuseMakeNew);
  }, [uploadReuseMakeNew]);

  useEffect(() => {
    if (value !== uploadReuseMakeNew) {
      setUploadReuseMakeNew(value);
    }
  }, [value]);

  return (
    <div ref={windowDimensions}>
      <Form.Item
        label={treasureCreation.TreasureCreationCreateLabel[language]}
        className="toggle-buttons"
      >
        <Row type="flex" justify="space-around">
          {kitType === 'image' && (
            <Button
              onClick={() => setUploadReuseMakeNew('makeNew')}
              className={
                uploadReuseMakeNew === 'makeNew'
                  ? 'buttons white-bg-btn-selected'
                  : 'buttons white-bg-btn'
              }
              size="small"
              // disabled
            >
              {treasureCreation.pointCreationMakeNew[language]}
            </Button>
          )}
          <Button
            onClick={() => setUploadReuseMakeNew('upload')}
            className={
              uploadReuseMakeNew === 'upload'
                ? 'buttons white-bg-btn-selected'
                : 'buttons white-bg-btn'
            }
            size="small"
          >
            {treasureCreation.pointCreationUpload[language]}
          </Button>
          <Popover
            placement="rightTop"
            trigger="click"
            align={{
              offset: [
                (windowDimensions.current?.offsetWidth - numberOfButtons * 100) /
                  (2 * numberOfButtons) +
                  21 +
                  5,
                0,
              ],
            }}
            content={<ReuseList {...otherProps} kitType={kitType} language={language} />}
          >
            <Button
              onClick={() => setUploadReuseMakeNew('reuse')}
              className={
                uploadReuseMakeNew === 'reuse'
                  ? 'buttons white-bg-btn-selected'
                  : 'buttons white-bg-btn'
              }
              size="small"
            >
              {treasureCreation.pointCreationReuse[language]}
            </Button>
          </Popover>
        </Row>
      </Form.Item>

      {/* show image or video when reusing  */}
      {uploadReuseMakeNew === 'reuse' && (
        <Form.Item>
          {form.getFieldDecorator('treasureAdvertisement', {
            rules: [
              {
                required: true,
                message: treasureCreation.uploadFileMissing[language],
              },
            ],
            initialValue: 'treasure.mediaPath',
          })(
            <Row type="flex" justify="center">
              <Col className="upload" style={{ width: '100%' }}>
                {props.mediaPath?.pic?.path && (
                  <img
                    src={`${baseURL}${props.mediaPath.pic?.path}`}
                    className="upload-sticker-image adjust"
                  />
                )}
                {props.mediaPath?.video?.path && (
                  <ReactPlayer
                    url={`${baseURL}${props.mediaPath.video?.path}`}
                    controls
                    height="auto"
                  />
                )}
              </Col>
            </Row>
          )}
        </Form.Item>
      )}
    </div>
  );
}

MakeNewUploadReuse.propTypes = {
  language: PropTypes.string,
  kitType: PropTypes.oneOf(['image', 'video']),
  mediaPath: PropTypes.object,
  onChange: PropTypes.func,
  updateMediaPath: PropTypes.func,
  value: PropTypes.oneOf(['upload', 'makeNew', 'reuse']),
  form: PropTypes.object,
};

MakeNewUploadReuse.defaultProps = {
  language: 'EN',
  kitType: 'image',
  mediaPath: {},
  onChange: () => {},
  updateMediaPath: () => {},
};

export default MakeNewUploadReuse;
