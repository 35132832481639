import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Button } from 'antd';
import PropTypes from 'prop-types';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import location_icon from '../../../assets/location_add_new_location_icon.svg';
import LocationFormItem from './LocationFormItem';
import { locationConstants } from '../../../constants/locationConstants';
import { debounce } from 'lodash';

import { createLocationV2 } from '../../../services/treasureService';

const createLocationWithDelay = debounce(async (locations, treasureID) => {
  const response = await createLocationV2(locations, treasureID);
}, 1000);

function LocationFormV2(props) {
  const {
    language,
    role,
    treasure,
    form,
    address,
    changeAddress,
    setAddress,
    setReduxLocations,
    treasureDetails,
    selectLocationRedux,
    addAnotherLocation,
    deleteLocation,
    changeLocation,
    locations,
  } = props;

  useEffect(() => {
    if (treasure.isEditing) {
      if (treasureDetails.locations.length > 0) {
        setReduxLocations(treasureDetails.locations);
      }
    }
  }, []);

  useEffect(() => {
    createLocationWithDelay(locations, treasure.ID);
  }, [locations]);

  const selectLocation = (index) => {
    selectLocationRedux(index);
  };

  const addAnotherLocationHandler = () => {
    addAnotherLocation({
      ID: Date.now(),
      selected: true,
      latitude: address.lat,
      longitude: address.lng,
      radius: address.radius,
      zoom: address.zoom,
      disableShow: true,
    });
  };

  const deleteLocationHandler = (index) => {
    deleteLocation(index);
    selectLocation(0);
  };

  const locationChangeHandler = (objectValues, index) => {
    changeLocation({ ...objectValues, radius: address.radius, zoom: address.zoom }, index);
  };

  const enableDelete = locations.length > 1;
  return (
    <>
      {locations.map((location, index) => {
        return (
          <LocationFormItem
            key={index}
            index={index}
            ID={location.ID}
            language={language}
            role={role}
            treasure={treasure}
            location={location}
            enableDelete={enableDelete}
            form={form}
            onDeleteLocation={deleteLocationHandler}
            changeLocation={locationChangeHandler}
            selectLocation={selectLocation}
            address={address}
            setAddress={setAddress}
            treasureDetails={treasureDetails}
          />
        );
      })}

      <Row type="flex" justify="center" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
        <Button
          style={{ width: '100%' }}
          className="buttons purple-gradient-right menu-button-width"
          onClick={addAnotherLocationHandler}
        >
          <img src={location_icon} alt="location" />
          {treasureCreation.TreasureCreationAddLocation[language]}
        </Button>
      </Row>
    </>
  );
}

LocationFormV2.propTypes = {
  language: PropTypes.string,
  role: PropTypes.number,
  treasure: PropTypes.object,
  form: PropTypes.object,
};

LocationFormV2.defaultProps = {
  language: 'EN',
  role: 800,
  treasure: {},
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
  address: state.address,
  treasure: state.treasure,
  treasureDetails: state.treasureDetails,
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  setAddress: (payload) => dispatch({ type: locationConstants.SET_ADDRESS, payload }),
  setReduxLocations: (payload) => dispatch({ type: locationConstants.SET_LOCATIONS, payload }),
  selectLocationRedux: (index) => dispatch({ type: locationConstants.SELECT_LOCATION, index }),
  addAnotherLocation: (payload) =>
    dispatch({ type: locationConstants.ADD_ANOTHER_LOCATION, payload }),
  deleteLocation: (index) => dispatch({ type: locationConstants.DELETE_LOCATION2, index }),
  changeLocation: (payload, index) =>
    dispatch({ type: locationConstants.CHANGE_LOCATION, payload, index }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationFormV2);
