import React from 'react';
import { connect } from 'react-redux';
import {
  Statistics,
  StatisticsForTreasure,
  StatisticsForPoints,
  StatisticsForCoins,
  StatisticsForPartners,
} from './menu/statistics';
import * as subMenuConstants from '../../lib/SubMenuConstants';

const SubMenuFullScreen = (props) => {
  const { subMenuFullScreen } = props;

  const selectOpenMenu = () => {
    switch (subMenuFullScreen) {
      case subMenuConstants.statisticsForTreasure:
        return <StatisticsForTreasure />;
      case subMenuConstants.statisticsForPoints:
        return <StatisticsForPoints />;
      case subMenuConstants.statisticsForCoins:
        return <StatisticsForCoins />;
      case subMenuConstants.statisticsForPartners:
        return <StatisticsForPartners />;
      default:
        console.log('Unknown case');
        return <Statistics />;
    }
  };

  return <>{selectOpenMenu()}</>;
};

const mapStateToProps = (state) => ({
  subMenuFullScreen: state.subMenuFullScreen,
});

export default connect(mapStateToProps)(SubMenuFullScreen);
