import {
  baseURL,
  treasureAPI,
  hashtagAPI,
  locationAPI,
  messageLocationAPI,
  treasureUnitAPI,
  post,
  jsonHeaders,
  put,
  del,
  get,
  gm,
  getUserTreasure,
  stickerCharactersAPI,
  getTreasureListByStatusAPI,
  headers,
  adKitReuseList,
} from '../lib/config';
import { store } from '../helpers/store';
import { responseCodes } from '../lib/const';
import { notification } from 'antd';
import { fileUploadErr, serverError, insufficientFunds } from '../lib/strings/error';
import { executeSignOut } from './sharedServices';
import { addTreasureErrors } from '../actions/treasureActions';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 5,
  });
};

export const errorHandling = (payload: object) => {
  store.dispatch(addTreasureErrors(payload));
};

export async function createTreasure(treasureType: number) {
  console.log('createTreasure, treasureType:', treasureType);

  try {
    const treasureData = new FormData();
    const ownerID = store.getState().authentication.user.user._id;

    treasureData.append('ownerID', ownerID);
    treasureData.append('characterID', '1'); // todo
    treasureData.append('treasureType', `${treasureType}`);
    const fetchResponse = await fetch(baseURL + treasureAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
      body: treasureData,
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function treasureUpdate(
  treasureID: Number,
  treasureParamName: string,
  treasureParam: any
) {
  console.log(
    `treasureUpdate, treasureID: ${treasureID}, treasureParamName: ${treasureParamName}, treasureParam: ${treasureParam}`
  );
  if (!treasureID) {
    console.log('treasureUpdate, missing ID');
    return;
  }
  try {
    const payload = {
      [`${treasureParamName}`]: treasureParam,
    };
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    errorHandling({ [treasureParamName]: error });
    return { error: error };
  }
}

export const treasureSendToReview = async (
  treasureID: Number,
  status: number,
  price: number,
  isPaid: true,
  LDiscountId?: number
) => {
  // console.log(`treasureSendToReview, treasureID: ${treasureID}, status: ${status}, price: ${price}, LDiscountId: ${LDiscountId}`)
  console.log(
    `treasureSendToReview, status: ${status}, lcashPrice: ${price}, useDiscount: ${
      LDiscountId ? LDiscountId : null
    }, treasurePayed: ${isPaid}`
  );
  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: `${status}`,
        lcashPrice: `${price}`,
        useDiscount: LDiscountId ? LDiscountId : null,
        treasurePayed: isPaid,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    } else if (response.code === responseCodes.insufficientFunds) {
      showErrorMessage(
        serverError[store.getState().authentication.language],
        insufficientFunds[store.getState().authentication.language]
      );
      return { error: response.code };
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    return { error: error };
  }
};

export async function treasureUpdateImage(treasureID: Number, imageFile: any) {
  console.log(`treasureUpdateImage, treasureID: ${treasureID}, imageFile: ${imageFile}`);
  try {
    const treasureData = new FormData();
    treasureData.append('file', imageFile);

    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}/uploadFile`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
      body: treasureData,
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    return { error: error };
  }
}

export async function updateStickerProps(
  treasureID: Number,
  characterID: number,
  sectionID: number,
  sectionPosition: number
) {
  console.log(
    `updateStickerProps, treasureID: ${treasureID}, characterID: ${characterID}, sectionID: ${sectionID}, sectionPosition: ${sectionPosition}`
  );
  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        characterID: characterID,
        treasureType: '1',
        sectionID: sectionID,
        sectionPosition: sectionPosition,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    return { error: error };
  }
}

export async function updateHashtag(treasureID: number, hashtag: string) {
  console.log(`updateHashtag, treasureID: ${treasureID}, hashtag: ${hashtag}`);
  try {
    const fetchResponse = await fetch(baseURL + hashtagAPI, {
      method: post,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: hashtag,
        treasureID: treasureID,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    errorHandling({ hashtag: error });
    return { error: error };
  }
}

export async function deleteHashtag(hashtag: any) {
  console.log(`deleteHashtag, hashtag:`, hashtag);
  try {
    const fetchResponse = await fetch(`${baseURL}${hashtagAPI}/${hashtag.ID}`, {
      method: del,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        treasureID: hashtag.treasureID,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function geocode(address: string) {
  console.log(`geocode, address: ${address}`);
  try {
    const fetchLatLng = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCILB_5kOOX6JzFe7QfNfxqNtTHB9knt3A`
    );
    const response = await fetchLatLng.json();
    const latLng = response.results[0].geometry.location;
    return latLng;
  } catch (error) {
    return error;
  }
}

export async function reverseGeocode(lat: number, lng: number) {
  console.log(`reverseGeocode, lat: ${lat}, lng: ${lng}`);
  try {
    const fetchLocation = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${gm}`
    );
    const response = await fetchLocation.json();
    return response.results[0].formatted_address;
  } catch (error) {
    return error;
  }
}

export async function reverseGeocodeAdressComponents(lat: number, lng: number) {
  console.log(`reverseGeocode, lat: ${lat}, lng: ${lng}`);
  try {
    const fetchLocation = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${gm}`
    );
    const response = await fetchLocation.json();
    return response.results[0].address_components;
  } catch (error) {
    return error;
  }
}

export async function createLocation(
  treasureID: number,
  latitude: any,
  longitude: any,
  radius: any
) {
  console.log(
    `createLocation, treasureID: ${treasureID}, latitude: ${latitude}, longitude: ${longitude}, radius: ${radius}`
  );

  try {
    const fetchResponse = await fetch(baseURL + locationAPI, {
      method: post,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        treasureID: treasureID,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function updateLocationLatLng(address: { lat: number; lng: number }, location: any) {
  const { lat, lng } = address;

  try {
    const locationID = location.ID;

    console.log(
      `updateLocationLatLng, locationID: ${locationID}, lat: ${lat}, lng: ${lng}, location:`,
      location
    );

    const fetchResponse = await fetch(`${baseURL}${locationAPI}/${locationID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        latitude: lat,
        longitude: lng,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function updateLocationRadius(treasureID: number, radius: number) {
  console.log(`updateLocationRadius, treasureID: ${treasureID}, radius: ${radius}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${locationAPI}/${treasureID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        radius: radius,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function updateNumberOfUnits(location: any, numberOfUnits: string, key: number) {
  console.log(`updateNumberOfUnits, numberOfUnits: ${numberOfUnits}, location:`, location);

  try {
    const fetchResponse = await fetch(`${baseURL}${locationAPI}/${location.ID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        unitCopies: numberOfUnits,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function messageLocation(location: any, radius: Number) {
  return true;
  // console.log(`messageLocation, location: ${location}, radius: ${radius}`);

  // try {
  //   const fetchResponse = await fetch(baseURL + messageLocationAPI, {
  //     method: post,
  //     headers: {
  //       'access-token': store.getState().authentication.user.newToken,
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       locationID: location.ID,
  //       latitude: location.latitude,
  //       longitude: location.longitude,
  //       radius: radius,
  //     }),
  //   });
  //   const response = await fetchResponse.json();
  //   if (response.code === responseCodes.successful) {
  //     return response.data.location;
  //   } else if (response.code === responseCodes.tokenNotValid) {
  //     executeSignOut();
  //   } else {
  //     Promise.reject();
  //   }
  // } catch (error) {
  //   return error;
  // }
}

export async function deleteMessageLocation(messageLocationId: number) {
  return true;
  // console.log(`deleteMessageLocation, messageLocationId: ${messageLocationId}`);

  // try {
  //   const fetchResponse = await fetch(`${baseURL}${messageLocationAPI}/${messageLocationId}`, {
  //     method: del,
  //     headers: {
  //       'access-token': store.getState().authentication.user.newToken,
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   const response = await fetchResponse.json();
  //   if (response.code === responseCodes.successful) {
  //     return response;
  //   } else if (response.code === responseCodes.tokenNotValid) {
  //     executeSignOut();
  //   }
  // } catch (error) {
  //   return error;
  // }
}

export async function updateMessageLocationRadius(
  messagingRadius: number,
  messageLocationID: number
) {
  return true;

  // console.log(
  //   `updateMessageLocationRadius, messagingRadius: ${messagingRadius}, messageLocationID: ${messageLocationID}`
  // );

  // try {
  //   const fetchResponse = await fetch(`${baseURL}${messageLocationAPI}/${messageLocationID}`, {
  //     method: put,
  //     headers: {
  //       'access-token': store.getState().authentication.user.newToken,
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       radius: messagingRadius,
  //     }),
  //   });
  //   const response = await fetchResponse.json();
  //   if (response.code === responseCodes.successful) {
  //     return response;
  //   } else if (response.code === responseCodes.tokenNotValid) {
  //     executeSignOut();
  //   }
  // } catch (error) {
  //   return error;
  // }
}

export async function updateMessageLocationLatLng(
  messageLocationID: number,
  lat: number,
  lng: number
) {
  return true;
  // console.log(
  //   `updateMessageLocationLatLng, messageLocationID: ${messageLocationID}, lat: ${lat}, lng: ${lng}`
  // );

  // try {
  //   const fetchResponse = await fetch(`${baseURL}${messageLocationAPI}/${messageLocationID}`, {
  //     method: put,
  //     headers: {
  //       'access-token': store.getState().authentication.user.newToken,
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       latitude: lat,
  //       longitude: lng,
  //     }),
  //   });
  //   const response = await fetchResponse.json();
  //   if (response.code === responseCodes.successful) {
  //     return response;
  //   } else if (response.code === responseCodes.tokenNotValid) {
  //     executeSignOut();
  //   }
  // } catch (error) {
  //   return error;
  // }
}

export async function treasureUnit(treasureUnitArray: Array<any>, locationID_UI?) {
  console.log(`treasureUnit, treasureUnitArray:`, treasureUnitArray);

  try {
    const fetchResponse = await fetch(baseURL + treasureUnitAPI, {
      method: post,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        treasureUnitArray: treasureUnitArray,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.treasureUnit;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    errorHandling({ ['numberOfUnits' + locationID_UI]: error });
    return { error: error };
  }
}

export async function updateTreasureUnit(treasureUnitID: number, newUnit: any) {
  console.log(`updateTreasureUnit, treasureUnitID: ${treasureUnitID}, newUnit:`, newUnit);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureUnitAPI}/${treasureUnitID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locationID: newUnit.locationID,
        latitude: newUnit.latitude,
        longitude: newUnit.longitude,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.treasureUnit;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function deleteTreasureUnit(treasureUnit: {
  ID: any;
  locationID: any;
  latitude: any;
  longitude: any;
}) {
  console.log(`deleteTreasureUnit, treasureUnit: ${treasureUnit}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureUnitAPI}/${treasureUnit.ID}`, {
      method: del,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.treasureUnit;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function deleteLocation(locationID: number) {
  console.log(`deleteLocation, locationID: ${locationID}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${locationAPI}/${locationID}`, {
      method: del,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.treasureUnit;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function deleteTreasure(treasureID: number) {
  console.log(`deleteTreasure, treasureID: ${treasureID}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: del,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function expireTreasure(treasureID: number) {
  console.log(`expireTreasure, treasureID: ${treasureID}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        markTreasureExpired: true,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function getCreatedTreasure(status?: string) {
  console.log(`getCreatedTreasure, status: ${status}`);

  try {
    const fetchResponse = await fetch(
      baseURL +
        getUserTreasure +
        '?' +
        new URLSearchParams({
          status: status,
        }),
      {
        method: get,
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.treasureData;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    notification.error({
      message: 'Error while fetching data! Please try again.',
    });
  }
}

export async function getCreatedTreasurePaging(status: string | number = 'all', page = 0) {
  console.log(`getCreatedTreasurePaging, page: ${page}`);

  try {
    const fetchResponse = await fetch(
      baseURL +
        getUserTreasure +
        '?' +
        new URLSearchParams({
          status: `${status}`,
          page: `${page}`,
        }),
      {
        method: get,
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return {
        treasureData: response.data.treasureData,
        currentPage: page,
        treasureLength: response.data.treasureLength,
      };
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    notification.error({
      message: 'Error while fetching data! Please try again.',
    });
  }
}

export async function getStickerCharacters() {
  console.log(`getStickerCharacters, empty call`);

  try {
    const fetchResponse = await fetch(baseURL + stickerCharactersAPI, {
      method: get,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to fetch characters');
    }
  } catch (error) {
    return error;
  }
}

export async function getTreasureByID(treasureID: number) {
  console.log(`getTreasureByID, treasureID: ${treasureID}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: get,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function updateCharacter(
  treasureID: number,
  characterID: string,
  stickerID: number,
  treasureType: string,
  sectionID: number,
  position: number
) {
  console.log(
    `updateCharacter, treasureID: ${treasureID}, characterID: ${characterID}, stickerID: ${stickerID}, treasureType: ${treasureType}, sectionID: ${sectionID}, position: ${position}`
  );

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        characterID: characterID,
        stickerID: stickerID,
        treasureType: treasureType,
        sectionID: sectionID,
        sectionPosition: position,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    return { error: error };
  }
}

export async function getTreasureByStatus(status: string) {
  console.log(`getTreasureByStatus, status: ${status}`);

  try {
    const fetchResponse = await fetch(
      baseURL +
        getTreasureListByStatusAPI +
        '?' +
        new URLSearchParams({
          status: status,
        }),
      {
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.treasures;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to get response');
    }
  } catch (error) {
    notification.error({
      message: 'Error while fetching data! Please try again.',
    });
  }
}

export async function getUsersByTreasureID(treasureID: number) {
  console.log(`getUsersByTreasureID, treasureID: ${treasureID}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}/users`, {
      method: get,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to get users');
    }
  } catch (error) {
    return error;
  }
}

// not used in Advertisement kit anymore
export async function treasureUpdateAdvertisement(treasureID: Number, imageFile: any) {
  console.log(`treasureUpdateAdvertisement, treasureID: ${treasureID}, imageFile: ${imageFile}`);

  try {
    const treasureData = new FormData();
    treasureData.append('file', imageFile);
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}/uploadFile`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
      body: treasureData,
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    }
  } catch (error) {
    return error;
  }
}

export async function updatePointProps(
  treasureID: Number,
  pointsPerUnit: number,
  kitTypeID: number
) {
  console.log(
    `updatePointProps, treasureID: ${treasureID}, pointsPerUnit: ${pointsPerUnit}, kitTypeID: ${kitTypeID}`
  );

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${treasureID}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        points: pointsPerUnit,
        pointsPerUnit: pointsPerUnit,
        treasureType: '2',
        kitTypeID: `${kitTypeID}`,
      }),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return null;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    errorHandling({ kitTypeID: error });
    return { error: error };
  }
}
export async function getSection(characterID: number) {
  console.log(`getSection, characterID: ${characterID}`);

  try {
    const fetchResponse = await fetch(`${baseURL}${stickerCharactersAPI}/${characterID}/sections`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.characterSections;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    return error;
  }
}

export async function getAdKitReuseList(type: 'image' | 'video', page = 1) {
  console.log(`getReuseList, type: ${type}, page: ${page}`);

  try {
    const fetchResponse = await fetch(
      `${baseURL}${adKitReuseList}?` +
        new URLSearchParams({
          status: '3',
          page: `${page}`,
          type: type,
        }),
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      console.log('getAdKitReuseList wrong response, code', response.code);
      notification.error({
        message: fileUploadErr[store.getState().authentication.language],
        description: `${response.code}`,
      });
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    notification.error({
      message: fileUploadErr[store.getState().authentication.language],
    });
    return error;
  }
}

export async function updateTreasureMediaPath(id: number, mediaPath: any) {
  console.log(`updateTreasureMediaPath, id: ${id}, mediaPath:`, mediaPath);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/${id}/updateFile`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(mediaPath),
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
      return;
    }
    throw response.code;
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `Response code: ${error}`
    );
    return { error: error };
  }
}

export async function getUserLDiscount() {
  console.log(`getUserLDiscount no input parametars`);

  try {
    const fetchResponse = await fetch(`${baseURL}${treasureAPI}/ldiscount`, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    const response = await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      showErrorMessage(serverError[store.getState().authentication.language], response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
    return error;
  }
}

export async function createLocationV2(locationsArray, treasureID) {
  console.log(`createLocationV2, treasureID: ${treasureID}, locationsArray:`, locationsArray);

  let locations = locationsArray.map((location) => ({
    latitude: location.latitude,
    longitude: location.longitude,
    radius: location.radius,
    unitCopies: location.numberOfUnits,
  }));

  locations = locations.filter((location) => location.unitCopies > 0);

  if (locations.length > 0) {
    const constructBody = { treasureID: treasureID, locations: locations };
    try {
      const fetchResponse = await fetch(`${baseURL}${locationAPI}`, {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(constructBody),
      });
      const response = await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        return response.data;
      } else if (response.code === responseCodes.tokenNotValid) {
        executeSignOut();
      } else {
        showErrorMessage(serverError[store.getState().authentication.language], response.code);
        return Promise.reject('failed to get sections');
      }
    } catch (error) {
      showErrorMessage(serverError[store.getState().authentication.language]);
      return error;
    }
  }
}
