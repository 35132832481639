import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Spin, Col, Input, Form, Button } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import * as menuActions from '../../../../actions/menuActions';
import { useQuery } from 'react-query';
import * as usersServices from '../../../../services/usersServices';
import { searchUsers } from '../../../../lib/strings/users';
import UsersList from './UsersList';

function Users({ language }) {
  const [page, setPage] = useState(1);
  const [username, setUsername] = useState('');

  const usersListQuery = useQuery(['UsersList', { page, username }], usersServices.getUsersList);

  const debounceUsername = useCallback(
    debounce((value) => {
      if (value.length > 2 || value.length === 0) {
        setUsername(value);
        setPage(1);
      }
    }, 500),
    []
  );

  const usernameChangeHandler = (event) => {
    debounceUsername(event.target.value);
  };

  const nextPageHandler = () => {
    setPage((page) => page + 1);
  };

  const previousPageHandler = () => {
    setPage((page) => page - 1);
  };

  return (
    <Row type="flex" justify="center" className="candyEvents-list">
      <Col style={{ width: '100%' }}>
        <Row
          className="create-treasure-padding"
          style={{ width: '100%', marginTop: '2em', marginBottom: '2em' }}
        >
          <Col>
            <Form>
              <Form.Item label={searchUsers[language]}>
                <Input className="input-treasure full-width" onChange={usernameChangeHandler} />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        {usersListQuery.isLoading && (
          <Row type="flex" justify="center">
            <Spin size="large" />
          </Row>
        )}
        {page > 1 && (
          <Row type="flex" justify="center">
            <Button className="transparent-btn" onClick={previousPageHandler}>
              Load previous page
            </Button>
          </Row>
        )}
        <Row style={{ width: '100%' }}>
          <UsersList
            usersList={usersListQuery.data?.users}
            loading={usersListQuery.isLoading}
            language={language}
          />
        </Row>
        {usersListQuery.data?.pagination.total >
          usersListQuery.data?.pagination.itemsPerPage * page && (
          <Row type="flex" justify="center">
            <Button className="transparent-btn" onClick={nextPageHandler}>
              Load next page
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
}

Users.propTypes = {
  language: PropTypes.string,
  role: PropTypes.number,
  openCreateCandyEvent: PropTypes.func,
  closeSubmenu1: PropTypes.func,
  closeSubmenu2: PropTypes.func,
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
});

export default connect(mapsStateToProps, mapDispatchToProps)(Users);
