import React, { useState } from 'react';
import { Row, Col, Typography, Button, Form, Modal, Divider } from 'antd';
import { connect } from 'react-redux';
import * as menuActions from '../../../actions/menuActions';
import LocationPricingDataList from './LocationPricingDataList';
import * as treasureReview from '../../../lib/strings/treasureReview';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { languages } from '../../../lib/interfaces/language';
import lcash from '../../../assets/lcash.svg';
import { sticker, roleAdmin, candy, point, LDiscount, rolePartner } from '../../../lib/const';
import * as treasureActions from '../../../actions/treasureActions';
import { SelectorLDiscount } from '../../../components';
import * as appConstants from '../../../lib/strings/appConstants';

const { Text } = Typography;

const mapStateToProps = (state: {
  menuUIChange: any;
  treasure: any;
  locations: any;
  authentication: any;
  userData?: any;
  isPaymentInitiated: boolean;
  address: any;
}) => {
  const { menuUIChange, treasure, locations, authentication, userData, address } = state;
  const { mapInstance } = address;
  const { language } = authentication;
  const {
    treasureName,
    totalNumberOfUnits,
    treasureType,
    duration,
    durationUnit,
    totalPrice,
    pointsPerUnit,
    ID,
  } = treasure;
  const { role } = userData;
  const { goBackToTreasureCreation } = menuUIChange;
  return {
    goBackToTreasureCreation,
    treasureName,
    totalNumberOfUnits,
    locations,
    treasureType,
    duration,
    language,
    durationUnit,
    totalPrice,
    role,
    pointsPerUnit,
    ID,
    state,
    mapInstance,
    treasure,
  };
};

const actionCreators = {
  backToTreasureCreation: menuActions.goBackToTreasureCreation,
  finishTreasureCreation: menuActions.finishReview,
  handleInput: treasureActions.handleInput,
};

interface TreasureReviewProps {
  treasureName?: string;
  totalNumberOfUnits?: number;
  locations?: any;
  treasureType?: number;
  duration?: number;
  language: languages;
  durationUnit: string;
  totalPrice: number;
  role?: number;
  pointsPerUnit?: number;
  ID: number;
  state: any;
  mapInstance: any;
  backToTreasureCreation?: () => void;
  finishTreasureCreation?: (role: number, LDiscountId?: number | null) => void;
  handleInput: any;
  treasure: any;
}

const TreasureReview = (props: TreasureReviewProps) => {
  const {
    language,
    treasure,
    role,
    locations,
    handleInput,
    backToTreasureCreation,
    finishTreasureCreation,
  } = props;

  const [selectedLDiscount, setSelectedLDiscount] = useState({
    treasureName: '',
    ID: 0,
    discountValue: 0,
  });
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(treasure.totalPrice);

  const calculateDurationInHours = (startTime, endTime) => {
    return (endTime - startTime) / 1000 / 3600;
  };

  const calculateDurationUnit = (exposureDuration) => {
    let duration;
    let durationUnit;
    if (exposureDuration <= 24) {
      duration = exposureDuration.toFixed();
      durationUnit =
        duration === '1'
          ? appConstants.TreasureCreationDurationOption3[language]
          : appConstants.TreasureReviewDurationHours[language];
    } else if (exposureDuration > 24 && exposureDuration <= 24 * 7) {
      const hoursToDays = exposureDuration / 24;
      duration = hoursToDays.toFixed();
      durationUnit =
        duration === '1'
          ? appConstants.TreasureCreationDurationOption2[language]
          : appConstants.TreasureReviewDurationDays[language];
    } else if (exposureDuration > 24 * 7) {
      const hoursToWeeks = exposureDuration / (24 * 7);
      duration = hoursToWeeks.toFixed();
      durationUnit =
        duration === '1'
          ? appConstants.TreasureCreationDurationOption1[language]
          : appConstants.TreasureReviewDurationWeeks[language];
    }
    return { duration, durationUnit };
  };

  let treasureTypeText;
  switch (treasure.treasureType) {
    case sticker:
      treasureTypeText = treasureReview.treasureTypeSticker[language];
      break;
    case point:
      treasureTypeText = treasureReview.treasureTypePoint[language];
      break;
    case candy:
      treasureTypeText = treasureReview.treasureTypeCandy[language];
      break;
    case LDiscount:
      treasureTypeText = treasureReview.treasureTypeLDiscount[language];
      break;
    default:
      break;
  }

  const onBackHandler = () => {
    handleInput('onBack', true);
    backToTreasureCreation();
  };

  const onLDiscountSelectHandler = (idValue) => {
    const selectedValue = treasure.availableDiscounts.find((object) => object.ID === idValue);
    let price = treasure.totalPrice;
    if (selectedValue) {
      price = treasure.totalPrice - selectedValue.discountValue;
      if (price < 0) {
        price = 0;
        setShowWarningModal(true);
      }
    }
    setTotalPrice(price);
    setSelectedLDiscount(selectedValue);
  };

  const onFinishHandler = async () => {
    if (role < roleAdmin && selectedLDiscount) {
      finishTreasureCreation(role, selectedLDiscount.ID);
    } else {
      finishTreasureCreation(role);
    }
  };

  const duration = calculateDurationUnit(
    calculateDurationInHours(treasure.startDate, treasure.endDate)
  );

  return (
    // Treasure informations: tresureType, treasureName, treasureDuration
    <Row className="review-treasure">
      <Col className="review-treasure-child">
        <Row type="flex" align="top" justify="center" className="pricing-container">
          <Col>
            <Row>
              <Col className="center">
                <Text className="treasure-review-treasure-type-text">{treasureTypeText}</Text>
              </Col>
            </Row>
            <Row>
              <Col className="center ">
                <Text className="treasure-review-treasure-name-text">{treasure.treasureName}</Text>
              </Col>
            </Row>
            {Number(treasure.treasureType) !== candy && (
              <Row>
                <Col className="center">
                  <Text className="treasure-review-treasure-duration">
                    {duration.duration} {duration.durationUnit}
                  </Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {/* List of locations */}
        <LocationPricingDataList />

        {/* Use your LDiscount for master ONLY! */}
        {treasure.treasureType > sticker && role < roleAdmin && (
          <Row>
            <Row
              type="flex"
              justify="start"
              align="middle"
              className="treasure-review-row-container"
            >
              <Col>
                <Text className="treasure-review-section-text">
                  {treasureReview.useYourLDiscount[language]}
                </Text>
              </Col>
            </Row>

            <Row>
              <Col>
                <SelectorLDiscount
                  language={language}
                  size="large"
                  placeholder={treasureReview.selectPlaceholder[language]}
                  list={treasure.availableDiscounts}
                  onChange={onLDiscountSelectHandler}
                />
              </Col>
            </Row>
          </Row>
        )}

        {/* Price, LDiscount, Total */}
        {Number(treasure.treasureType) > sticker && role < roleAdmin ? (
          <Row>
            <Col>
              {/* Price */}
              <Row type="flex" justify="space-between" className="treasure-review-row-container">
                <Text className="treasure-review-section-text">
                  {treasureReview.TreasureReviewPrice[language]}
                </Text>
                <Text className="treasure-review-section-text">
                  {treasure.totalPrice.toFixed(2)} {treasureReview.lCash[language]}
                </Text>
              </Row>
              {selectedLDiscount && (
                <>
                  {/* LDiscount */}
                  <Row
                    type="flex"
                    justify="space-between"
                    className="treasure-review-row-container"
                  >
                    <Text className="treasure-review-section-text">
                      {treasureReview.LDiscountValue[language]}
                    </Text>
                    <Text className="treasure-review-section-text">
                      {selectedLDiscount.discountValue.toFixed(2)} {treasureReview.lCash[language]}
                    </Text>
                  </Row>

                  <div style={{ marginLeft: '1em', marginRight: '1em' }}>
                    <Divider style={{ marginBottom: '0px', marginTop: '0px' }} />
                  </div>

                  {/* Total */}
                  <Row
                    type="flex"
                    justify="space-between"
                    className="treasure-review-row-container"
                  >
                    <Text className="treasure-review-section-text">
                      {treasureReview.totalPrice[language]}
                    </Text>
                    <Text className="treasure-review-section-text">
                      {totalPrice.toFixed(2)} {treasureReview.lCash[language]}
                    </Text>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        ) : null}
        <Row className="review-buttons">
          <Col>
            <Row type="flex" justify="space-between">
              <Col>
                <Button className="purple-gradient-right buttons white-txt" onClick={onBackHandler}>
                  {treasureReview.TreasureReviewButton1[language]}
                </Button>
              </Col>
              <Col>
                <Button className="yellow-gradient white-txt buttons" onClick={onFinishHandler}>
                  {role < roleAdmin
                    ? treasureReview.TreasureReviewButton2[language]
                    : treasureReview.TreasureReviewPublish[language]}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Modal
        visible={showWarningModal}
        footer={null}
        centered
        onCancel={() => setShowWarningModal(false)}
      >
        <Typography.Text>
          {treasureReview.LDiscountErrorMessage[language]}{' '}
          {(selectedLDiscount?.discountValue - treasure.totalPrice).toFixed(2)}{' '}
          {treasureReview.lCash[language]}.
        </Typography.Text>
      </Modal>
    </Row>
  );
};

export default connect(mapStateToProps, actionCreators)(TreasureReview);
