import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Button } from 'antd';
import Treasure from '../../containers/dashboard/menu/getTreasure/Treasure';
import NoTreasure from '../../containers/dashboard/menu/getTreasure/NoTreasure';
import * as treasureService from '../../services/treasureService';
import * as treasureCreation from '../../lib/strings/treasureCreation';

import PropTypes from 'prop-types';

function ReuseList(props) {
  const { updateMediaPath, kitType, language } = props;

  const [treasureID, setTreasureID] = useState(null);
  const [initialization, setInitialization] = useState(true);
  const [loading, setLoading] = useState(false);
  const [treasureList, setTresureList] = useState([]);
  const [adKitsLength, setAdKitsLength] = useState(0);
  const [page, setPage] = useState(1);

  const itemsPerPage = 20;

  useEffect(() => {
    setInitialization(true);
    const fetchTreasureList = async () => {
      // API call
      const treasureListData: any = await treasureService.getAdKitReuseList(kitType, 1);
      setPage(1);
      if (treasureListData.adKits) {
        setTresureList(treasureListData.adKits);
        setAdKitsLength(treasureListData.adKitsLength);
        setInitialization(false);
      }
    };
    fetchTreasureList();
  }, [kitType]);

  const handleSelectedTreasure = (id: number) => {
    setTreasureID(id);
  };

  const reuseHandler = (mediaPath) => {
    if (mediaPath.pic?.path) {
      //update reducer for picture, api upload
      updateMediaPath({ pic: mediaPath.pic });
    } else if (mediaPath.video?.path) {
      //must add thumbnail thumbnail: mediaPath.thumbnail
      //update reducer for video, api upload
      updateMediaPath({ video: mediaPath.video, thumbnail: mediaPath.thumbnail });
    }
  };

  const loadNewPage = async () => {
    setLoading(true);
    const newPage = await treasureService.getAdKitReuseList(kitType, page + 1);
    setPage(page + 1);
    if (newPage.adKits) {
      setTresureList((currentList) => [...currentList, ...newPage.adKits]);
    }
    setLoading(false);
  };

  return (
    <Row type="flex" justify="center" className="scrollable-parent">
      <Col className="scrollable-child">
        <Row>
          <div className="scrollable" style={{ width: '300px', maxHeight: '360px' }}>
            {!initialization &&
              treasureList.length > 0 &&
              treasureList.map((treasure: any) => {
                return (
                  <Treasure
                    ID={treasure.ID}
                    key={treasure.ID}
                    name={treasure.treasureName}
                    totalNumberOfUnits={treasure.totalNumberOfUnits}
                    exposureDuration={(treasure.endDate - treasure.startDate) / 1000 / 3600}
                    startDate={treasure.startDate}
                    endDate={treasure.endDate}
                    treasureType={treasure.treasureType}
                    unitImage={treasure.unitImage}
                    locations={treasure.locations}
                    status={treasure.status}
                    pointImage={treasure.mediaPath}
                    selected={treasure.ID === treasureID}
                    handleSelectedTreasure={handleSelectedTreasure}
                    createdAt={treasure.created ? treasure.created : '-'}
                    startsAt={treasure.lastStatusUpdate ? treasure.startDate : '-'}
                    username={treasure.ownerData ? treasure.ownerData.username : ''}
                    reuse={true}
                    reuseHandler={reuseHandler}
                  />
                );
              })}

            {loading && (
              <Row type="flex" justify="center" style={{ padding: '2em' }}>
                <Spin size="large" spinning={props.isLoading} />
              </Row>
            )}

            {/* Button when API return arrayLength refactor is needed */}
            {!initialization && treasureList.length < adKitsLength && (
              <Row type="flex" justify="center" align="middle">
                <Button className="transparent-btn" onClick={loadNewPage}>
                  {treasureCreation.loadMore[language]}
                </Button>
              </Row>
            )}
            {treasureList.length === 0 && <NoTreasure />}
            {initialization && (
              <Row type="flex" justify="center" style={{ padding: '2em' }}>
                <Spin size="large" spinning={props.isLoading} />
              </Row>
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
}

ReuseList.propTypes = {
  updateMediaPath: PropTypes.func,
  kitType: PropTypes.oneOf(['image', 'video']),
  language: PropTypes.string,
};

ReuseList.defaultProps = {
  updateMediaPath: () => {},
  kitType: 'image',
  language: 'EN',
};

export default ReuseList;
