import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Spin, Col } from 'antd';
import * as candyEvent from '../../../../lib/strings/candyEvent';
import CandyEventList from './CandyEventList';
import SelectorGray from '../../../../components/SelectorGray';
import { roleAdmin, roleSuperadmin } from '../../../../lib/const';
import countryData from '../../../../helpers/country.json';
import * as menuActions from '../../../../actions/menuActions';
import * as candyEventActions from '../../../../actions/candyEventActions';
import { useQuery } from 'react-query';
import * as candyEventService from '../../../../services/candyEventService';
import { serverError } from '../../../../lib/strings/error';
import PropTypes from 'prop-types';

function CandyEvents(props) {
  const {
    language,
    role,
    openCreateCandyEvent,
    resetCandyEvent,
    closeSubmenu1,
    closeSubmenu2,
    selectedCountryCode,
    candyEventEditParameter,
  } = props;
  const [countryNames] = useState(['All', ...countryData.map((country) => country.name)]);

  useEffect(() => {
    return () => {
      // resetCandyEvent();
    };
  }, []);

  const candyEventList = useQuery(
    ['CandyEventList', { country: selectedCountryCode }],
    candyEventService.getCandyEventList,
    {
      onError: (error) => {
        candyEventService.showErrorMessage(serverError[language], error);
      },
    }
  );

  const openCandyEventHandler = () => {
    closeSubmenu2();
    closeSubmenu1();
    resetCandyEvent();
    openCreateCandyEvent();
  };

  const onSelectChangeHandler = (value) => {
    closeSubmenu2();
    closeSubmenu1();
    if (value !== 'All') {
      const countryCode = countryData.find((country) => country.name === value);
      candyEventEditParameter(
        candyEventActions.editableParameters.selectedCountryCode,
        countryCode.alpha2Code
      );
    } else {
      candyEventEditParameter(candyEventActions.editableParameters.selectedCountryCode, '');
    }
  };

  return (
    <Row type="flex" justify="center" className="candyEvents-list">
      {role === roleAdmin && (
        <Button
          onClick={openCandyEventHandler}
          className="buttons purple-gradient-right margin-contest menu-button-width"
        >
          {candyEvent.createNewCandyEvent[language]}
        </Button>
      )}
      <Col style={{ width: '100%' }}>
        {role === roleSuperadmin && (
          <Row style={{ width: '100%', marginTop: '2em', marginBottom: '2em' }}>
            <SelectorGray
              selectorTextPosition="start"
              dropDownTextPosition="start"
              list={countryNames}
              onChange={onSelectChangeHandler}
              showSearch={true}
            />
          </Row>
        )}
        {candyEventList.isLoading && (
          <Row type="flex" justify="center">
            <Spin size="large" />
          </Row>
        )}
        <Row style={{ width: '100%' }}>
          <CandyEventList candyEventList={candyEventList.data} language={language} />
        </Row>
      </Col>
    </Row>
  );
}

CandyEvents.propTypes = {
  language: PropTypes.string,
  role: PropTypes.number,
  selectedCountryCode: PropTypes.string,
  openCreateCandyEvent: PropTypes.func,
  resetCandyEvent: PropTypes.func,
  closeSubmenu1: PropTypes.func,
  closeSubmenu2: PropTypes.func,
  candyEventEditParameter: PropTypes.func,
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
  selectedCountryCode: state.candyEvent.selectedCountryCode,
});

const mapDispatchToProps = (dispatch) => ({
  openCreateCandyEvent: () => dispatch(menuActions.openCreateCandyEvent()),
  resetCandyEvent: () => dispatch(candyEventActions.resetCandyEvent()),
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  candyEventEditParameter: (parameterKey, parameterValue) =>
    dispatch(candyEventActions.editParameter(parameterKey, parameterValue)),
});

export default connect(mapsStateToProps, mapDispatchToProps)(CandyEvents);
