import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Form, InputNumber, Radio, DatePicker } from 'antd';
import calendar from '../../assets/icon_date.svg';
import moment, { Moment } from 'moment';

import * as treasureCreation from '../../lib/strings/treasureCreation';

import PropTypes from 'prop-types';

export interface duration {
  durationSelection: string;
}
function StartEndDatePicker(props) {
  const {
    language,
    initialStartDateValue,
    initialEndDateValue,
    onChangeStartDate,
    onChangeEndDate,
    formStartDateId,
    formEndDateId,
    startDateFormMessage,
    endDateFormMessage,
  } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const currentDate = moment().subtract(1, 'minute');

  const [startDate, setStartDate] = useState(initialStartDateValue);
  const [endDate, setEndDate] = useState(initialEndDateValue);
  const [duration, setDuration] = useState(Number);
  const [durationSelection, setDurationSelection] = useState<'hours' | 'days' | 'weeks'>('hours');

  useEffect(() => {
    if (initialStartDateValue && initialEndDateValue) {
      setFieldsValue({
        duration: calculateDuration(initialStartDateValue, initialEndDateValue),
      });
    }
  }, []);

  const handleStartDate = (date) => {
    const newStartDateMs = parseInt(
      moment(date).set('second', 0).set('millisecond', 0).format('x')
    );

    if (endDate) {
      setFieldsValue({
        duration: calculateDuration(newStartDateMs, endDate),
      });
    }

    setStartDate(newStartDateMs);
  };

  const handleEndDate = (date) => {
    const newEndDateMs = parseInt(moment(date).set('second', 0).set('millisecond', 0).format('x'));

    if (startDate) {
      setFieldsValue({
        duration: calculateDuration(startDate, newEndDateMs),
      });
    }

    setEndDate(newEndDateMs);
    setDuration(0);
  };

  const calculateDuration = (start, end) => {
    let differenceTime = (end - start) / 1000 / 60 / 60;
    let durationDisplay = 0;

    if (differenceTime >= 24) {
      durationDisplay = differenceTime / 24;
      setDurationSelection('days');
      return durationDisplay.toFixed(0);
    }

    setDurationSelection('hours');
    return differenceTime.toFixed(0);
  };

  const handleDurationSelection = (event) => {
    setDurationSelection(event.target.value);
  };

  const handleDuration = (value: number) => {
    setDuration(value);
  };

  useEffect(() => {
    if (startDate && duration && durationSelection) {
      const newEndDate = moment(startDate).add(duration, durationSelection);
      setFieldsValue({
        endDate: newEndDate,
      });
      setEndDate(parseInt(newEndDate.format('x')));
    }
  }, [startDate, duration, durationSelection]);

  useEffect(() => {
    if (startDate !== 0) {
      onChangeStartDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== 0) {
      onChangeEndDate(endDate);
    }
  }, [endDate]);

  return (
    <div className="startEndDatePicker">
      <Form.Item>
        <Row type="flex" justify="start" align="middle" className="full-width">
          <Col className="dateTextContainer">
            <Typography.Text>{treasureCreation.start[language]}</Typography.Text>
          </Col>
          <Col>
            {getFieldDecorator(formStartDateId, {
              rules: [
                {
                  required: formStartDateId && true,
                  message: startDateFormMessage || treasureCreation.startDateErrorMassage[language],
                },
              ],
              initialValue: initialStartDateValue ? moment(initialStartDateValue) : null,
            })(
              <DatePicker
                className="dateSelectorContainer"
                format={'DD/MM/YY hh:mm A'}
                showTime={true}
                suffixIcon={<img src={calendar} />}
                onChange={handleStartDate}
                disabled={false}
                disabledDate={(current) => {
                  return (
                    currentDate.isAfter(current) || (endDate && moment(endDate).isBefore(current))
                  );
                }}
              />
            )}
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row type="flex" justify="start" align="middle" className="full-width">
          <Col className="dateTextContainer">
            <Typography.Text>{treasureCreation.end[language]}</Typography.Text>
          </Col>
          <Col>
            {getFieldDecorator(formEndDateId, {
              rules: [
                {
                  required: formEndDateId && true,
                  message: endDateFormMessage || treasureCreation.endDateErrorMassage[language],
                },
              ],
              initialValue: initialEndDateValue ? moment(initialEndDateValue) : null,
            })(
              <DatePicker
                className="dateSelectorContainer"
                format={'DD/MM/YY hh:mm A'}
                showTime={true}
                suffixIcon={<img src={calendar} />}
                onChange={handleEndDate}
                disabled={false}
                disabledDate={(current) => {
                  const newCurrent = moment(current);
                  newCurrent.set('hours', 23).set('minutes', 59);
                  return moment(startDate).isAfter(newCurrent);
                }}
              />
            )}
          </Col>
        </Row>
      </Form.Item>

      <Form.Item className="roboto-regular">
        <Row type="flex" justify="space-between" className="row-duration">
          <Col className="durationTextContainer">
            <Typography.Text>
              {treasureCreation.TreasureCreationDurationLabel[language]}
            </Typography.Text>
          </Col>

          <Col className="durationTextContainer">
            {getFieldDecorator('duration', {
              rules: [
                {
                  required: false,
                  message: treasureCreation.TreasureCreationDurationError[language],
                },
              ],
            })(
              <InputNumber
                className="character-input-number"
                placeholder="..."
                name="duration"
                onChange={handleDuration}
                min={1}
              />
            )}
          </Col>
          {/* onChange={e => this.handleDuration(e, 'radio')}   value={this.props.durationUnit} */}
          <Radio.Group
            style={{ display: 'flex' }}
            onChange={handleDurationSelection}
            value={durationSelection}
          >
            <Col className="durationTextContainer">
              <Radio.Button className="character-radio-button" value="weeks">
                {treasureCreation.TreasureCreationDurationOption1[language]}
              </Radio.Button>
            </Col>
            <Col className="durationTextContainer">
              <Radio.Button className="character-radio-button" value="days">
                {treasureCreation.TreasureCreationDurationOption2[language]}
              </Radio.Button>
            </Col>
            <Col className="durationTextContainer">
              <Radio.Button className="character-radio-button" value="hours">
                {treasureCreation.TreasureCreationDurationOption3[language]}
              </Radio.Button>
            </Col>
          </Radio.Group>
        </Row>
      </Form.Item>
      <div className="bottomSpacer"></div>
    </div>
  );
}

StartEndDatePicker.propTypes = {
  language: PropTypes.string,
  initialStartDateValue: PropTypes.number,
  initialEndDateValue: PropTypes.number,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  formStartDateId: PropTypes.string,
  formEndDateId: PropTypes.string,
  startDateFormMessage: PropTypes.string,
  endDateFormMessage: PropTypes.string,
  form: PropTypes.object.isRequired,
};

StartEndDatePicker.defaultProps = {
  language: 'EN',
  onChangeStartDate: () => {},
  onChangeEndDate: () => {},
  formStartDateId: 'startDate',
  formEndDateId: 'endDate',
};

export default StartEndDatePicker;
