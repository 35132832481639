import React, { useState } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import Treasures from './getTreasure/Treasures';
import Stats from './statistics/Stats';
import { connect } from 'react-redux';
import * as menuActions from '../../../actions/menuActions';
import { bindActionCreators } from 'redux';
import * as menu from '../../../lib/strings/menu';
import { languages } from '../../../lib/interfaces/language';
import Review from './getTreasure/Review';
import Drafts from './getTreasure/Drafts';
import { roleAdmin, rolePartner, roleSuperadmin } from '../../../lib/const';
import Contests from './contest/Contests';
import Kits from './kit/Kits';
import Balance from './balance/Balance';
import Notifications from './notifications/Notifications';
import Characters from './characters/Characters';
import { isDev } from '../../../lib/config';
import CandyEvents from './candyEvent/CandyEvents';
import Requests from './requests/Requests';
import Users from './users/Users';

const mapStateToProps = (state: {
  isSubMenuFullScreenOpen;
  menu;
  menuUIChange: {
    goToReview: boolean;
    openTreasureCreation: boolean;
    cancelTreasureCreation: boolean;
  };
  authentication: { language: languages };
  userData: { role: number };
  isDetailsUIOpen: boolean;
}) => {
  const {
    menuUIChange,
    authentication,
    userData,
    isDetailsUIOpen,
    isSubMenuFullScreenOpen,
    menu,
  } = state;
  const { goToReview, openTreasureCreation, cancelTreasureCreation } = menuUIChange;
  const { language } = authentication;
  const { role } = userData;
  return {
    goToReview,
    openTreasureCreation,
    cancelTreasureCreation,
    language,
    role,
    isDetailsUIOpen,
    isSubMenuFullScreenOpen,
    menu,
  };
};

// const actionCreators = {
//     handleOpenTreasureCreationUI: menuActions.openTreasureCreation,
//     handleCloseDetailsUI: menuActions.closeDetailsUI
// }

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleOpenTreasureCreationUI: menuActions.openTreasureCreation,
      handleCloseDetailsUI: menuActions.closeDetailsUI,
      menuChange: (menu) => dispatch(menuActions.changeMenu(menu)),
      closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
      closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
    },
    dispatch
  );

interface TreasureDataProps {
  role: number;
  language: languages;
  goToReview: boolean;
  isDetailsUIOpen: boolean;
  openTreasureCreation: boolean;
  cancelTreasureCreation: boolean;
  handleCloseDetailsUI: () => void;
  handleOpenTreasureCreationUI: () => void;
  isSubMenuFullScreenOpen: boolean;
  menuChange: (value: string) => void;
  menu: string;
  closeSubmenu1: () => void;
  closeSubmenu2: () => void;
}

const MenuButtons = (props: TreasureDataProps) => {
  //prizes - creation and review switch here
  // const [current, setcurrent] = useState('')
  const current = props.menu;

  const handleClick = (e: any) => {
    // setcurrent(e.target.value)
    props.closeSubmenu2();
    props.closeSubmenu1();
    props.menuChange(e.target.value);
  };

  const handleSelectedMenuItem = () => {
    switch (current) {
      case 'treasures':
        return <Treasures />;
      case 'characters':
        return <Characters />;
      case 'drafts':
        return <Drafts />;
      case 'statistics':
        return <Stats />;
      case 'review':
        return <Review reviewSelected={current === 'review' ? true : false} />;
      case 'idol':
        return <Contests />;
      case 'kit':
        return <Kits />;
      case 'balance':
        return <Balance />;
      case 'notifications':
        return <Notifications />;
      case 'candyEvent':
        return <CandyEvents />;
      case 'requests':
        return <Requests />;
      case 'users':
        return <Users />;
      default:
        break;
    }
  };

  const handleCreateNewTreasure = () => {
    if (props.isDetailsUIOpen) {
      props.handleCloseDetailsUI();
    }
    props.handleOpenTreasureCreationUI();
  };

  const { language } = props;

  return (
    <Row style={{ borderRadius: '0 0 20px 20px', height: 'inherit' }}>
      <Col>
        <Row className="sticky-buttons">
          <Row type="flex" justify="space-around">
            <Button
              onClick={handleCreateNewTreasure}
              className="buttons yellow-gradient"
              style={{ width: '85%' }}
            >
              {menu.PrizeDataButton1[language]}
            </Button>
          </Row>
          <Row type="flex" justify="space-around" className="menu-selector">
            {/* Treasure */}
            <Col className="buttons-col">
              <Button
                onClick={handleClick}
                value="treasures"
                className={
                  current === 'treasures'
                    ? 'buttons-menu button-treasures-selected'
                    : 'buttons-menu button-treasures'
                }
              ></Button>
              <Typography.Text
                className={current === 'treasures' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
              >
                {menu.PrizeDataButton3[language]}
              </Typography.Text>
            </Col>

            {/* Characters */}
            {(props.role >= roleAdmin || isDev) && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="characters"
                  className={
                    current === 'characters'
                      ? 'buttons-menu button-characters-selected'
                      : 'buttons-menu button-characters'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'characters' ? 'buttons-menu-text bold' : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton11[language]}
                </Typography.Text>
              </Col>
            )}

            {/* Stats */}
            {props.role >= rolePartner && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="statistics"
                  className={
                    current === 'stats'
                      ? 'buttons-menu button-stats-selected'
                      : 'buttons-menu button-stats'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'statistics' ? 'buttons-menu-text bold' : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton4[language]}
                </Typography.Text>
              </Col>
            )}

            {/* Balance */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="balance"
                  className={
                    current === 'balance'
                      ? 'buttons-menu button-balance-selected'
                      : 'buttons-menu button-balance'
                  }
                ></Button>
                <Typography.Text
                  className={current === 'balance' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
                >
                  {menu.PrizeDataButton9[language]}
                </Typography.Text>
              </Col>
            )}

            {/* Candy C. */}
            {props.role >= roleAdmin && (
              <Col className="buttons-col">
                <Button
                  value="candyEvent"
                  onClick={handleClick}
                  className={
                    current === 'candyEvent'
                      ? 'buttons-menu button-candyEvent-selected'
                      : 'buttons-menu button-candyEvent'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'candyEvent' ? 'buttons-menu-text bold' : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton5[language]}
                </Typography.Text>
              </Col>
            )}
          </Row>
          {props.role >= roleAdmin ? (
            <Row type="flex" justify="space-around" className="menu-selector bottom">
              {/* Idol */}
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="idol"
                  className={
                    current === 'idol'
                      ? 'buttons-menu button-contest-selected'
                      : 'buttons-menu button-contest'
                  }
                ></Button>
                <Typography.Text
                  className={current === 'idol' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
                >
                  {menu.PrizeDataButton6[language]}
                </Typography.Text>
              </Col>

              {/* Kit */}
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="kit"
                  className={
                    current === 'kit'
                      ? 'buttons-menu button-kit-selected'
                      : 'buttons-menu button-kit'
                  }
                ></Button>
                <Typography.Text
                  className={current === 'kit' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
                >
                  {menu.PrizeDataButton7[language]}
                </Typography.Text>
              </Col>

              {/* Requests */}
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="requests"
                  className={
                    current === 'requests'
                      ? 'buttons-menu button-requests-selected'
                      : 'buttons-menu button-requests'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'requests' ? 'buttons-menu-text bold' : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton8[language]}
                </Typography.Text>
              </Col>

              {/* Notifs */}
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="notifications"
                  className={
                    current === 'notifications'
                      ? 'buttons-menu button-notifications-selected'
                      : 'buttons-menu button-notifications'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'notifications' ? 'buttons-menu-text bold' : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton10[language]}
                </Typography.Text>
              </Col>
            </Row>
          ) : null}
          {props.role >= roleSuperadmin && (
            <Row type="flex" justify="space-around" className="menu-selector bottom">
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="users"
                  className={
                    current === 'users'
                      ? 'buttons-menu button-users-selected'
                      : 'buttons-menu button-users'
                  }
                ></Button>
                <Typography.Text
                  className={current === 'users' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
                >
                  {menu.users[language]}
                </Typography.Text>
              </Col>
              <Col className="buttons-col"></Col>
              <Col className="buttons-col"></Col>
              <Col className="buttons-col"></Col>
            </Row>
          )}
        </Row>
        <Row>
          <Col>{handleSelectedMenuItem()}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuButtons);
