import React from 'react';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import User from './User';
import { usersNotFound } from '../../../../lib/strings/users';

function UsersList({ usersList, loading, language }) {
  return (
    <div>
      {usersList.map((user) => {
        return <User key={user._id} user={user} />;
      })}

      {!loading && usersList.length === 0 && (
        <Row className="no-prizes">
          <Col>
            <Typography.Text className="roboto-regular center large-text">
              {usersNotFound[language]}
            </Typography.Text>
          </Col>
        </Row>
      )}
    </div>
  );
}

UsersList.propTypes = {
  usersList: PropTypes.array,
  language: PropTypes.string,
  loading: PropTypes.bool,
};

UsersList.defaultProps = {
  usersList: [],
  language: 'EN',
  loading: false,
};

export default UsersList;
